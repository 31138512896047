import React from 'react';
import {Fade, Toast, ToastBody, ToastHeader} from 'reactstrap';
import {useInterval} from '../student/utils';
import {useDispatch, useSelector} from 'react-redux';
import {dismissToast} from '../store/actions/actionCreators';

const CloseButton = ({toggle}) => (
    <button type='button' onClick={toggle} className='close py-0 pr-2' aria-label='Close'>
        <span aria-hidden='true'>
            <svg
                viewBox='0 0 14 14'
                style={{
                    height: '14px',
                    width: '14px',
                    stroke: 'red',
                    fill: 'none',
                    strokeLinejoin: 'round',
                    strokeLinecap: 'round',
                    strokeWidth: '1px',
                }}
            >
                <g>
                    <rect height='12' width='12' x='1' y='1' />
                    <line x1='4' x2='10' y1='4' y2='10' />
                    <line x1='10' x2='4' y1='4' y2='10' />
                </g>
            </svg>
        </span>
    </button>
);

const Alert = ({isOpen, icon, type, toggle, children}) => {
    // automatically close toast in 15 seconds
    useInterval(toggle, 15000);

    return (
        <Fade
            className={`alert alert-${icon ? icon : 'danger'} alert-dismissible`}
            in={isOpen}
            onClick={toggle}
            role='alert'
        >
            <Toast>
                <ToastHeader icon={icon ? icon : 'danger'}>
                    {type}
                    <CloseButton toggle={toggle} />
                </ToastHeader>
                <ToastBody>{children}</ToastBody>
            </Toast>
        </Fade>
    );
};

const Toasts = ({children}) => {
    const errors = useSelector(state => state?.toast);
    const dispatch = useDispatch();
    if (!errors) return null;
    const handleClose = id => dispatch(dismissToast(id));

    return (
        <>
            {children}
            <div
                style={{
                    zIndex: 9999,
                    position: 'fixed',
                    top: '60px',
                    right: '40px',
                    width: '380px',
                    maxWidth: '85%',
                }}
            >
                {errors?.map(({message, id, ...rest}) => (
                    <Alert
                        key={id}
                        id={id}
                        isOpen={true}
                        message={message}
                        {...rest}
                        toggle={() => handleClose(id)}
                    >
                        {message}
                    </Alert>
                ))}
            </div>
        </>
    );
};

export default Toasts;
