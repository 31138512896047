import axios from 'axios';
import axiosRetry from 'axios-retry';
import {rollbar} from '../../hooks/useApi';
import {axiosResponseError} from './actionCreators';

export const API_PREFIX = process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '';
export const API1 = API_PREFIX + '/api/v1';

axiosRetry(axios, {
    retries: 2,
    retryCondition: error =>
        axiosRetry.isNetworkError(error) || axiosRetry.isSafeRequestError(error),
    retryDelay: axiosRetry.exponentialDelay,
});

export function handleErrors(response) {
    const contentLength = response.headers.get('content-length');
    const contentType = response.headers.get('content-type');
    if (!response.ok) {
        if (!contentLength) {
            rollbar.error(response.statusText);
            throw new Error(response.statusText);
        } else if (contentType && contentType.indexOf('application/json') === -1) {
            return response.text().then(data => {
                rollbar.error(response.statusText, data);
                throw new Error(data);
            });
        }
        return response.json().then(data => {
            let message = data.error;
            if (!message) {
                message = JSON.stringify(data);
            }
            rollbar.error(message);
            throw new Error(message);
        });
    } else if (response.status === 204) {
        return new Response('{"status": "OK"}');
    }
    return response;
}

export function axiosErrors(error, dispatch, action = null, hatch = null) {
    if (error.response && error.response.data && error.response.data.detail === hatch) return;
    rollbar.error(error);
    const failDispatch = action ? action : axiosResponseError;
    if (!error.response) dispatch(failDispatch(error.message));
    else if (!error.response.data.detail) {
        if (error.response.data.startsWith('<!DOCTYPE html>')) document.write(error.response.data);
        else dispatch(failDispatch(error.response.data));
    } else if (Array.isArray(error.response.data.detail))
        dispatch(failDispatch(JSON.stringify(error.response.data.detail)));
    else dispatch(failDispatch(error.response.data.detail));
}

export function apiGet(request, success, path) {
    return dispatch => {
        if (request) dispatch(request());
        return axios
            .get(API1 + path)
            .then(resp => dispatch(success(resp.data)))
            .catch(err => axiosErrors(err, dispatch));
    };
}

export function apiDelete(request, success, path) {
    return dispatch => {
        if (request) dispatch(request());
        return axios
            .delete(API1 + path)
            .then(resp => dispatch(success(resp.data)))
            .catch(err => axiosErrors(err, dispatch));
    };
}

export function apiPost(request, success, path, data) {
    return dispatch => {
        if (request) dispatch(request());
        return axios
            .post(API1 + path, data)
            .then(resp => dispatch(success(resp.data)))
            .catch(err => axiosErrors(err, dispatch));
    };
}

export function apiPut(request, success, path, data) {
    return dispatch => {
        if (request) dispatch(request());
        return axios
            .put(API1 + path, data)
            .then(resp => dispatch(success(resp.data)))
            .catch(err => axiosErrors(err, dispatch));
    };
}
