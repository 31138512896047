import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {
    Button,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    TabPane,
    UncontrolledTooltip,
} from 'reactstrap';
import Select from 'react-select';
import * as api from '../../store/actions/fetchActions';
import {RowInput} from '../../components/input/RowInput';
import {sortColumn} from '../columnSort';
import {useDistrictsData, useStatesData} from '../../hooks/useApi';

const DistrictsTab = () => {
    const dispatch = useDispatch();
    const {districts} = useDistrictsData();
    const {statesOptions} = useStatesData();
    const initialDistrict = {
        district_id: null,
        district_name: '',
        state_id: 0,
        state_abbreviation: '',
        priority: 0,
    };
    const [modalDistrict, setModalDistrict] = useState(initialDistrict);
    const [sort, setSort] = useState({column: 'district_id', ascending: true});
    const [sortedDistricts, setSortedDistricts] = useState(districts);

    useEffect(() => {
        if (districts) {
            setSortedDistricts(sortColumn(districts, sort.column, sort.ascending));
        }
    }, [sort, districts]);
    const onSort = col => {
        setSort({column: col, ascending: sort.column === col ? !sort.ascending : true});
    };
    const openEditDistrict = district => {
        setModalDistrict(district);
    };
    const handleChange = (name, value) => setModalDistrict({...modalDistrict, [name]: value});
    const handleSubmit = () => {
        if (modalDistrict.district_id === 0) {
            dispatch(api.postDistrict(modalDistrict)).then(() => {
                setModalDistrict(initialDistrict);
            });
        } else {
            dispatch(api.putDistrict(modalDistrict)).then(() => {
                setModalDistrict(initialDistrict);
            });
        }
    };

    return (
        <TabPane tabId='districts'>
            <table className='table table-hover table-condensed'>
                <thead>
                    <tr>
                        <th className='sort' onClick={() => onSort('district_name')}>
                            District Name
                        </th>
                        <th className='sort' onClick={() => onSort('state_abbreviation')}>
                            State
                        </th>
                        <th className='sort' onClick={() => onSort('priority')}>
                            Priority
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {sortedDistricts &&
                        sortedDistricts.map((d, index) => (
                            <tr key={index}>
                                <td>{d.district_name}</td>
                                <td>{d.state_abbreviation}</td>
                                <td>{d.priority}</td>
                                <td>
                                    <i
                                        className='fa fa-edit icon-primary'
                                        onClick={() => openEditDistrict(d)}
                                        id={`tooltip-edit-district-${d.district_id}`}
                                        style={{paddingRight: '6px'}}
                                    />
                                    <UncontrolledTooltip
                                        placement='right'
                                        target={`tooltip-edit-district-${d.district_id}`}
                                    >
                                        Edit District
                                    </UncontrolledTooltip>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <div className='float-right'>
                <Button
                    color='primary'
                    onClick={() => {
                        setModalDistrict({...initialDistrict, district_id: 0});
                    }}
                >
                    Add District
                </Button>
            </div>
            <Modal
                isOpen={modalDistrict.district_id !== null}
                toggle={() => setModalDistrict(initialDistrict)}
            >
                <ModalHeader toggle={() => setModalDistrict(initialDistrict)}>
                    {modalDistrict.district_id === 0 ? 'Add' : 'Edit'} District
                </ModalHeader>
                <ModalBody>
                    <RowInput
                        name='district_name'
                        onChange={handleChange}
                        value={modalDistrict.district_name}
                    >
                        District Name
                    </RowInput>
                    <RowInput
                        name='priority'
                        onChange={handleChange}
                        value={modalDistrict.priority}
                    >
                        Priority
                    </RowInput>
                    <FormGroup>
                        <Label>State</Label>
                        <Select
                            value={{
                                label: modalDistrict.state_abbreviation,
                                value: modalDistrict.state_id,
                            }}
                            options={statesOptions}
                            onChange={opt => {
                                setModalDistrict({
                                    ...modalDistrict,
                                    state_abbreviation: opt.label,
                                    state_id: opt.value,
                                });
                            }}
                        />
                    </FormGroup>
                    <Button className='float-right' color='success' onClick={handleSubmit}>
                        Save
                    </Button>
                </ModalBody>
            </Modal>
        </TabPane>
    );
};

export default DistrictsTab;
