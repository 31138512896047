import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Col, Nav, NavItem, NavLink, Row, TabContent} from 'reactstrap';
import classnames from 'classnames';
import * as api from '../../store/actions/fetchActions';
import './teachers-admin.scss';
import {Link, Redirect} from 'react-router-dom';
import DistrictsTab from './DistrictsTab';
import SchoolsTab from './SchoolsTab';
import TeachersTab from './TeachersTab';
import useAuth from '../../hooks/useAuth';

const TeachersAdmin = () => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('teachers');
    const auth = useAuth(dispatch);

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not an admin */
    if (auth.isAdmin === false) return <Redirect to='/' />;

    const onTabClick = event => {
        const {name} = event.target;
        if (activeTab !== name) setActiveTab(name);
    };

    return (
        <Row>
            <Col>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === 'districts'})}
                            name='districts'
                            onClick={onTabClick}
                        >
                            Districts
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === 'schools'})}
                            name='schools'
                            onClick={onTabClick}
                        >
                            Schools
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === 'teachers'})}
                            name='teachers'
                            onClick={onTabClick}
                        >
                            Teachers
                        </NavLink>
                    </NavItem>
                    <NavItem style={{marginLeft: 'auto'}}>
                        <Link to='/admin' className='btn btn-primary'>
                            Home
                        </Link>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <DistrictsTab />
                    <SchoolsTab />
                    <TeachersTab />
                </TabContent>
            </Col>
        </Row>
    );
};

export default TeachersAdmin;
