import React, {useEffect, useState} from 'react';
import {Button, Alert} from 'reactstrap';
import {Link, useLocation, useHistory} from 'react-router-dom';
import {RowInput} from '../components/input/RowInput';
import queryString from 'query-string';
import {apiCall} from '../hooks/useApi';
import {useMutation} from 'react-query';

const SetPassword = () => {
    const location = useLocation();
    const [formValues, setFormValues] = useState({
        ...queryString.parse(location.search),
        password: '',
        password_copy: '',
    });
    const [done, setDone] = useState(false);
    const history = useHistory();
    const key = formValues.key;

    /* this call validates the reset key and returns 204 if valid
       we use useMutation because if it was useQuery it would refresh unnecessarily, including after
       the POST, resulting in an error and redirect to /login instead of showing the success message */
    const validate = useMutation(data => apiCall('get', `/user/set-password?key=${data}`), {
        onError: () => {
            /* `apiCall` takes care of creating a toast with the error message from the server */
            history.push('/login');
        },
        retry: false,
    });
    const password = useMutation(data => apiCall('post', '/user/set-password', data), {
        onSuccess: () => setDone(true),
    });
    useEffect(() => {
        /* no need for an error message if someone is tampering with the url, they know what they did */
        if (!key || key.length < 16) history.push('/login');
        else if (!done) validate.mutate(key);
    }, [key]);

    const handleChange = (name, value) => setFormValues({...formValues, [name]: value});
    const handleSubmit = () => password.mutate(formValues);

    return (
        <div className='loginWrapper'>
            {done ? (
                <Alert color='success'>
                    Your password was successfully updated!{' '}
                    <Link to='/login'> Go back to login</Link>
                </Alert>
            ) : (
                <>
                    <h5>Please set password</h5>
                    <RowInput
                        id='password'
                        name='password'
                        placeholder='New Password...'
                        type='password'
                        value={formValues.password}
                        onChange={handleChange}
                        required
                    >
                        Enter a new password:
                    </RowInput>
                    <RowInput
                        id='copyPassword'
                        name='password_copy'
                        placeholder='Repeat New Password...'
                        type='password'
                        value={formValues.password_copy}
                        onChange={handleChange}
                        required
                    >
                        Re-enter your new password:
                    </RowInput>
                    <Button color='primary' block onClick={handleSubmit}>
                        Set Password
                    </Button>
                </>
            )}
        </div>
    );
};

export default SetPassword;
