import React from 'react';
import {Card, CardBody, CardHeader, CardTitle} from 'reactstrap';

const CardExamResources = ({exam_name, resources}) => {
    return (
        <Card>
            <CardHeader>
                <CardTitle className='text-white'>{exam_name} Resources</CardTitle>
            </CardHeader>
            <CardBody>
                <ul>
                    {resources.map((resource, i) => (
                        <li key={i}>
                            <a href={resource.url} target='_blank'>
                                {resource.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </CardBody>
        </Card>
    );
};

export default CardExamResources;
