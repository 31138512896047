// please keep these in alphabetical order
export const ADD_TOAST = 'ADD_TOAST';
export const AXIOS_RESPONSE_ERROR = 'AXIOS_RESPONSE_ERROR';
export const CLEAR_READER_ERROR = 'CLEAR_READER_ERROR';
export const COPY_YEAR_REQUEST = 'COPY_YEAR_REQUEST';
export const COPY_YEAR_SUCCESS = 'COPY_YEAR_SUCCESS';
export const DELETE_CHECKOUT_FAILURE = 'DELETE_CHECKOUT_FAILURE';
export const DELETE_CHECKOUT_SUCCESS = 'DELETE_CHECKOUT_SUCCESS';
export const DELETE_EXAM_FAILURE = 'DELETE_EXAM_FAILURE';
export const DELETE_EXAM_REQUEST = 'DELETE_EXAM_REQUEST';
export const DELETE_EXAM_SUCCESS = 'DELETE_EXAM_SUCCESS';
export const DELETE_TEST_RESPONSE_REQUEST = 'DELETE_TEST_RESPONSE_REQUEST';
export const DELETE_TEST_RESPONSE_SUCCESS = 'DELETE_TEST_RESPONSE_SUCCESS';
export const DISMISS_TOAST = 'DISMISS_TOAST';
export const FETCH_ASSIGNMENTS_FAILURE = 'FETCH_ASSIGNMENTS_FAILURE';
export const FETCH_ASSIGNMENTS_REQUEST = 'FETCH_ASSIGNMENTS_REQUEST';
export const FETCH_ASSIGNMENTS_SUCCESS = 'FETCH_ASSIGNMENTS_SUCCESS';
export const FETCH_EXAM_FAILURE = 'FETCH_EXAM_FAILURE';
export const FETCH_EXAM_REQUEST = 'FETCH_EXAM_REQUEST';
export const FETCH_EXAM_SUCCESS = 'FETCH_EXAM_SUCCESS';
export const FETCH_READER_EXAM_FAILURE = 'FETCH_READER_EXAM_FAILURE';
export const FETCH_READER_EXAM_REQUEST = 'FETCH_READER_EXAM_REQUEST';
export const FETCH_READER_EXAM_SUCCESS = 'FETCH_READER_EXAM_SUCCESS';
export const FETCH_REGISTRANTS_SUCCESS = 'FETCH_REGISTRANTS_SUCCESS';
export const FETCH_STUDENTS_REQUEST = 'FETCH_STUDENTS_REQUEST';
export const FETCH_STUDENTS_SUCCESS = 'FETCH_STUDENTS_SUCCESS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const GET_DISTRICTS_REQUEST = 'GET_DISTRICTS_REQUEST';
export const GET_DISTRICTS_SUCCESS = 'GET_DISTRICTS_SUCCESS';
export const GET_EXAM_PROMPT_SUCCESS = 'GET_EXAM_PROMPT_SUCCESS';
export const GET_METRICS_SUCCESS = 'GET_METRICS_SUCCESS';
export const GET_REGISTRANT_OPTIONS_BY_KEY_REQUEST = 'GET_REGISTRANT_OPTIONS_BY_KEY_REQUEST';
export const GET_REGISTRANT_OPTIONS_BY_KEY_SUCCESS = 'GET_REGISTRANT_OPTIONS_BY_KEY_SUCCESS';
export const GET_SCHOOLS_SUCCESS = 'GET_SCHOOLS_SUCCESS';
export const GET_SCORES_FOR_TEST_RESPONSE_REQUEST = 'GET_SCORES_FOR_TEST_RESPONSE_REQUEST';
export const GET_SCORES_FOR_TEST_RESPONSE_SUCCESS = 'GET_SCORES_FOR_TEST_RESPONSE_SUCCESS';
export const GET_TEACHERS_SUCCESS = 'GET_TEACHERS_SUCCESS';
export const GET_TEACHERS_BY_SCHOOL_REQUEST = 'GET_TEACHERS_BY_SCHOOL_REQUEST';
export const GET_TEACHERS_BY_SCHOOL_SUCCESS = 'GET_TEACHERS_BY_SCHOOL_SUCCESS';
export const GET_TEACHERS_STUDENTS_SUCCESS = 'GET_TEACHERS_STUDENTS_SUCCESS';
export const GET_TEST_RESPONSES_FOR_EDITING_REQUEST = 'GET_TEST_RESPONSES_FOR_EDITING_REQUEST';
export const GET_TEST_RESPONSES_FOR_EDITING_SUCCESS = 'GET_TEST_RESPONSES_FOR_EDITING_SUCCESS';
export const GET_TEST_RESPONSES_FOR_ADMIN_REQUEST = 'GET_TEST_RESPONSES_FOR_ADMIN_REQUEST';
export const GET_TEST_RESPONSES_FOR_ADMIN_SUCCESS = 'GET_TEST_RESPONSES_FOR_ADMIN_SUCCESS';
export const POST_EXPORT_SUCCESS = 'POST_EXPORT_SUCCESS';
export const POST_REGISTRANTS_REQUEST = 'POST_REGISTRANTS_REQUEST';
export const POST_REGISTRANTS_SUCCESS = 'POST_REGISTRANTS_SUCCESS';
export const POST_REGISTRATION_REQUEST = 'POST_REGISTRATION_REQUEST';
export const POST_REGISTRATION_SUCCESS = 'POST_REGISTRATION_SUCCESS';
export const POST_REPORT_SUCCESS = 'POST_REPORT_SUCCESS';
export const POST_RESPONSE_FAILURE = 'POST_RESPONSE_FAILURE';
export const POST_RESPONSE_LOADING = 'POST_RESPONSE_LOADING';
export const POST_RESPONSE_REQUEST = 'POST_RESPONSE_REQUEST';
export const POST_RESPONSE_SUCCESS = 'POST_RESPONSE_SUCCESS';
export const POST_STUDENTS_FAILURE = 'POST_STUDENTS_FAILURE';
export const POST_STUDENTS_REQUEST = 'POST_STUDENTS_REQUEST';
export const POST_STUDENTS_SUCCESS = 'POST_STUDENTS_SUCCESS';
export const POST_SCANNED_RESPONSE_LOADING = 'POST_SCANNED_RESPONSE_LOADING';
export const POST_SCANNED_RESPONSE_SUCCESS = 'POST_SCANNED_RESPONSE_SUCCESS';
export const POST_SCANNED_RESPONSE_FAILURE = 'POST_SCANNED_RESPONSE_FAILURE';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const PUT_RESPONSE_FAILURE = 'PUT_RESPONSE_FAILURE';
export const PUT_RESPONSE_REQUEST = 'PUT_RESPONSE_REQUEST';
export const PUT_RESPONSE_SUCCESS = 'PUT_RESPONSE_SUCCESS';
export const PUT_STUDENTS_FAILURE = 'PUT_STUDENTS_FAILURE';
export const PUT_STUDENTS_REQUEST = 'PUT_STUDENTS_REQUEST';
export const PUT_STUDENTS_SUCCESS = 'PUT_STUDENTS_SUCCESS';
export const REFRESH_CHECKOUT_FAILURE = 'REFRESH_CHECKOUT_FAILURE';
export const REFRESH_CHECKOUT_REQUEST = 'REFRESH_CHECKOUT_REQUEST';
export const REFRESH_CHECKOUT_SUCCESS = 'REFRESH_CHECKOUT_SUCCESS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const SAVE_EXAM_FAILURE = 'SAVE_EXAM_FAILURE';
export const SAVE_EXAM_REQUEST = 'SAVE_EXAM_REQUEST';
export const SAVE_EXAM_SUCCESS = 'SAVE_EXAM_SUCCESS';
export const SAVE_TEST_RESPONSE_FAILURE = 'SAVE_TEST_RESPONSE_FAILURE';
export const SAVE_TEST_RESPONSE_REQUEST = 'SAVE_TEST_RESPONSE_REQUEST';
export const SAVE_TEST_RESPONSE_SUCCESS = 'SAVE_TEST_RESPONSE_SUCCESS';
export const SET_EXAM_MODAL = 'SET_EXAM_MODAL';
export const SET_EXAM_SELECT = 'SET_EXAM_SELECT';
export const SET_EXAM_TAB = 'SET_EXAM_TAB';
export const SET_EXAM_YEAR = 'SET_EXAM_YEAR';
export const SET_READER_TEST = 'SET_READER_TEST';
export const SET_STUDENT_PERSIST = 'SET_STUDENT_PERSIST';
export const SORT_EXAM_TAB = 'SORT_EXAM_TAB';
export const UPDATE_RESPONSE_SUCCESS = 'UPDATE_RESPONSE_SUCCESS';
