import React from 'react';

export const useInterval = (callback, delay, timeout = false) => {
    // largely thanks to https://overreacted.io/making-setinterval-declarative-with-react-hooks/
    const savedCallback = React.useRef();
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    React.useEffect(() => {
        const tick = () => {
            savedCallback.current();
        };
        if (delay !== null) {
            if (timeout) {
                let id = setTimeout(tick, delay);
                return () => clearTimeout(id);
            } else {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }
    }, [delay]);
};
