import * as act from '../actions/actionTypes';

const resetState = {
    updating: false,
    error: null,
};

const responseReducer = (state = {...resetState}, action) => {
    switch (action.type) {
        case act.FETCH_STUDENTS_REQUEST:
            state = {
                ...state,
                statusMessage: null,
            };
            break;
        case act.FETCH_STUDENTS_SUCCESS:
            state = {
                ...state,
                studentList: action.payload,
                error: null,
            };
            break;
        case act.UPDATE_RESPONSE_SUCCESS:
            state = {
                ...state,
                updating: false,
            };
            break;
        default:
    }
    return state;
};

export default responseReducer;
