import React from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';
import Dashboard from './Dashboard';
import useAuth from '../hooks/useAuth';
import {Redirect} from 'react-router-dom';
import {useDispatch} from 'react-redux';

const TeacherHome = () => {
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);

    const [tab, setTab] = React.useState('dash');

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not a reader */
    if (auth.isTeacher === false) return <Redirect to='/' />;

    const toggleTab = event => {
        const newTab = event.target.name;
        if (tab !== newTab) {
            setTab(newTab);
        }
    };

    return (
        <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({active: tab === 'dash'})}
                        name='dash'
                        onClick={toggleTab}
                    >
                        Dashboard
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={tab}>
                <TabPane tabId='dash'>
                    <Dashboard />
                </TabPane>
            </TabContent>
        </React.Fragment>
    );
};

export default TeacherHome;
