import React from 'react';
import {Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap';
import Loading from '../../components/waiting';
import {Link} from 'react-router-dom';

const AlertRecategorize = props => {
    if (props.count === 0) return null;
    if (!props.count) return <Loading>Loading recategorize count . . .</Loading>;
    return (
        <Card className='border-danger'>
            <CardHeader className='bg-danger'>
                <CardTitle>Recategorize Responses</CardTitle>
            </CardHeader>
            <CardBody>{props.count} responses need to be recategorized.</CardBody>
            <CardFooter>
                <Link className='btn btn-danger btn-sm' to='/admin/recategorize-response'>
                    Recategorize Now
                </Link>
            </CardFooter>
        </Card>
    );
};

export default AlertRecategorize;
