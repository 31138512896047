import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {
    Button,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    TabPane,
    UncontrolledTooltip,
} from 'reactstrap';
import Select from 'react-select';
import * as api from '../../store/actions/fetchActions';
import {RowInput} from '../../components/input/RowInput';
import {sortColumn} from '../columnSort';
import {useSchoolsData, useDistrictsData} from '../../hooks/useApi';

const SchoolsTab = () => {
    const dispatch = useDispatch();
    const {schoolsData: schools} = useSchoolsData();
    const {districts} = useDistrictsData();
    const initialSchool = {
        school_id: null,
        school_name: '',
        district_id: 0,
        district_name: '',
        state_abbreviation: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postal_code: '',
        priority: 0,
    };
    const [modalSchool, setModalSchool] = useState(initialSchool);
    const [sort, setSort] = useState({column: 'school_name', ascending: true});
    const [sortedSchools, setSortedSchools] = useState(schools);
    const [districtOptions, setDistrictOptions] = useState([]);

    useEffect(() => {
        if (schools) {
            setSortedSchools(sortColumn(schools, sort.column, sort.ascending));
        }
    }, [sort, schools]);
    useEffect(() => {
        if (districts) {
            setDistrictOptions(
                districts.map(d => ({value: d.district_id, label: d.district_name}))
            );
        }
    }, [districts]);
    const onSort = col => {
        setSort({column: col, ascending: sort.column === col ? !sort.ascending : true});
    };
    const openEditSchool = school => {
        setModalSchool(school);
    };
    const handleChange = (name, value) => setModalSchool({...modalSchool, [name]: value});
    const handleSubmit = () => {
        if (modalSchool.school_id === 0) {
            dispatch(api.postSchool(modalSchool)).then(() => {
                setModalSchool(initialSchool);
            });
        } else {
            const payload = {...modalSchool, priority: modalSchool.priority | 0};
            dispatch(api.putSchool(payload)).then(() => {
                setModalSchool(initialSchool);
            });
        }
    };

    return (
        <TabPane tabId='schools'>
            <table className='table table-hover table-condensed'>
                <thead>
                    <tr>
                        <th className='sort' onClick={() => onSort('school_name')}>
                            School Name
                        </th>
                        <th className='sort' onClick={() => onSort('district_name')}>
                            District Name
                        </th>
                        <th className='sort' onClick={() => onSort('state_abbreviation')}>
                            State
                        </th>
                        <th className='sort' onClick={() => onSort('priority')}>
                            Priority
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {sortedSchools &&
                        sortedSchools.map((s, index) => (
                            <tr key={index}>
                                <td>{s.school_name}</td>
                                <td>{s.district_name}</td>
                                <td>{s.state_abbreviation}</td>
                                <td>{s.priority}</td>
                                <td>
                                    <i
                                        className='fa fa-edit icon-primary'
                                        onClick={() => openEditSchool(s)}
                                        id={`tooltip-edit-school-${s.school_id}`}
                                        style={{paddingRight: '6px'}}
                                    />
                                    <UncontrolledTooltip
                                        placement='right'
                                        target={`tooltip-edit-school-${s.school_id}`}
                                    >
                                        Edit School
                                    </UncontrolledTooltip>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <div className='float-right'>
                <Button
                    color='primary'
                    onClick={() => {
                        setModalSchool({...initialSchool, school_id: 0});
                    }}
                >
                    Add School
                </Button>
            </div>
            <Modal
                isOpen={modalSchool.school_id !== null}
                toggle={() => setModalSchool(initialSchool)}
            >
                <ModalHeader toggle={() => setModalSchool(initialSchool)}>
                    {modalSchool.school_id === 0 ? 'Add' : 'Edit'} School
                </ModalHeader>
                <ModalBody>
                    <RowInput
                        name='school_name'
                        onChange={handleChange}
                        value={modalSchool.school_name}
                    >
                        School Name
                    </RowInput>
                    <RowInput name='address1' onChange={handleChange} value={modalSchool.address1}>
                        Address 1
                    </RowInput>
                    <RowInput
                        name='address2'
                        onChange={handleChange}
                        value={modalSchool.address2 || ''}
                    >
                        Address 2
                    </RowInput>
                    <RowInput name='city' onChange={handleChange} value={modalSchool.city}>
                        City
                    </RowInput>
                    <RowInput name='state' onChange={handleChange} value={modalSchool.state}>
                        State
                    </RowInput>
                    <RowInput
                        name='postal_code'
                        onChange={handleChange}
                        value={modalSchool.postal_code}
                    >
                        Zip Code
                    </RowInput>
                    <RowInput name='priority' onChange={handleChange} value={modalSchool.priority}>
                        Priority
                    </RowInput>
                    <FormGroup>
                        <Label>District</Label>
                        <Select
                            value={{
                                label: modalSchool.district_name,
                                value: modalSchool.district_id,
                            }}
                            options={districtOptions}
                            onChange={opt => {
                                setModalSchool({
                                    ...modalSchool,
                                    district_name: opt.label,
                                    district_id: opt.value,
                                });
                            }}
                        />
                    </FormGroup>
                    <Button className='float-right' color='success' onClick={handleSubmit}>
                        Save
                    </Button>
                </ModalBody>
            </Modal>
        </TabPane>
    );
};

export default SchoolsTab;
