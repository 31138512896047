import axios from 'axios';
import LogRocket from 'logrocket';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import {API_PREFIX, rollbar, apiCall} from './useApi';
import {
    fetchUserSuccess,
    postUserSuccess,
    postStudentsSuccess,
    postStudentsFailure,
} from '../store/actions/actionCreators';

const getUser = dispatch => {
    const {data, error} = useQuery(
        'user',
        () => {
            return axios(API_PREFIX + '/user').then(resp => resp.data);
        },
        {
            cacheTime: 24 * 60 * 60 * 1000,
            staleTime: 24 * 60 * 60 * 1000,
            // fetchUserSuccess triggers updates in redux teacher and student reducers
            onSuccess: data => {
                setMonitoringUser(data);
                dispatch(fetchUserSuccess(data));
            },
            retry: false,
        }
    );
    if (data === undefined && error?.response?.status === 403) {
        return {
            error: error.response.data?.detail || '403',
            redirect: error.response.headers?.location || '/',
        };
    }
    return data;
};

const setMonitoringUser = ({userId, firstName, lastName, email, adminLevel}) => {
    const userName = `${firstName} ${lastName}`;
    LogRocket.identify(userId, {
        name: userName,
        email: email,
        adminLevel,
    });
    rollbar.configure({
        payload: {
            person: {
                id: userId,
                username: userName,
                email: email,
            },
        },
    });
};

const useAuth = dispatch => {
    const queryClient = useQueryClient();
    const data = getUser(dispatch);
    const loginMutation = useMutation(payload => apiCall('post', '/user', payload), {
        onSuccess: authData => {
            queryClient.setQueryData('user', authData);
            setMonitoringUser(authData);
            // postUserSuccess triggers updates in redux toast and student reducers
            dispatch(postUserSuccess(authData));
        },
        onError: () => {
            queryClient.setQueryData('user', {});
        },
    });
    const logoutMutation = useMutation(() => apiCall('delete', '/user'), {
        onSettled: () => {
            queryClient.setQueryData('user', {redirect: '/'});
            setMonitoringUser({});
        },
    });
    const studentMutation = useMutation(name => apiCall('post', '/user/student', name), {
        onSuccess: authData => {
            queryClient.setQueryData('user', {
                ...authData,
                firstName: authData.firstName,
                middleInitial: authData.middleInitial,
                lastName: authData.lastName,
            });
            setMonitoringUser(authData);
            dispatch(postStudentsSuccess(authData));
        },
        onError: error => dispatch(postStudentsFailure(error)),
    });
    const passwordMutation = useMutation(payload =>
        apiCall('post', '/user/change-password', payload)
    );
    return {
        ...data,
        login: loginMutation.mutate,
        logout: logoutMutation.mutate,
        logoutStatus: logoutMutation.status,
        setStudentName: studentMutation.mutate,
        changePassword: passwordMutation.mutate,
    };
};

export default useAuth;
