import * as act from '../actions/actionTypes';

const resetExam = {
    loaded: false,
    column: null,
    ascending: true,
    id: null,
    name: null,
    sorted: true,
};

const examReducer = (
    state = {
        activeTab: 'exams',
        modalOpen: null,
        exam: {...resetExam},
        exams: [],
        years: [],
        formats: [],
    },
    action
) => {
    switch (action.type) {
        case act.SET_EXAM_TAB:
            if (
                state.activeTab !== action.payload &&
                (!!state.exam.exam_id || action.payload === 'exams')
            ) {
                state = {
                    ...state,
                    activeTab: action.payload,
                };
            }
            break;
        case act.SET_EXAM_MODAL:
            state = {...state};
            if (action.payload.main !== undefined) state.modalOpen = action.payload.main;
            if (action.payload.needRefresh !== undefined)
                state.question.needRefresh = action.payload.needRefresh;
            break;
        case act.SET_EXAM_SELECT:
            const selected = state.exams.find(e => e.exam_id === action.payload);
            state = {
                ...state,
                exam: {
                    ...state.exam,
                    exam_id: selected.exam_id,
                    name: selected.exam_name,
                    test_id: selected.test_id,
                },
            };
            break;
        case act.SET_EXAM_YEAR:
            state = {
                ...state,
                exam: {...resetExam},
                exams: [],
                activeTab: 'exams',
                year: action.payload,
            };
            break;
        case act.SORT_EXAM_TAB:
            if ('exams' !== action.payload.tab) break;
            const tableKey = action.payload.tab;
            const metaKey = action.payload.tab.slice(0, -1);
            const metaTab = {...state[metaKey]};
            let asc = metaTab.ascending ? 1 : -1;
            if (action.payload.column === metaTab.column && !!metaTab.sorted) asc = -asc;
            const sorted = [...state[tableKey]];
            sorted.sort((a, b) =>
                a[action.payload.column] > b[action.payload.column] ? asc : -asc
            );
            metaTab.ascending = asc === 1;
            metaTab.column = action.payload.column;
            metaTab.sorted = true;
            state = {
                ...state,
                [tableKey]: sorted,
                [metaKey]: metaTab,
            };
            break;
        case act.FETCH_EXAM_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                exam: {
                    ...state.exam,
                    loaded: true,
                    sorted: false,
                },
                copying: false,
                year:
                    action.payload.exams.length > 0
                        ? action.payload.exams[0].school_year
                        : action.payload.years[0],
            };
            break;
        case act.COPY_YEAR_REQUEST:
            state = {
                ...state,
                copying: true,
            };
            break;
        case act.COPY_YEAR_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                copying: false,
                modalOpen: null,
            };
            break;
        case act.SAVE_EXAM_REQUEST:
            state = {
                ...state,
                exam: {
                    ...state.exam,
                    saving: true,
                },
            };
            break;
        case act.SAVE_EXAM_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                exam: {
                    ...state.exam,
                    saving: false,
                    sorted: false,
                },
                modalOpen: null,
            };
            break;
        case act.SAVE_EXAM_FAILURE:
            state = {
                ...state,
                error: !state.error ? [action.payload] : [action.payload, ...state.error],
                exam: {
                    ...state.exam,
                    saving: false,
                },
            };
            break;
        case act.DELETE_EXAM_REQUEST:
            state = {
                ...state,
                exams: state.exams.filter(e => e.exam_id !== action.payload),
                deleting: true,
            };
            break;
        case act.DELETE_EXAM_SUCCESS:
            state = {
                ...state,
                deleting: false,
                modalOpen: null,
            };
            break;
        case act.DELETE_EXAM_FAILURE:
            state = {
                ...state,
                error: !state.error ? [action.payload] : [action.payload, ...state.error],
                deleting: false,
            };
            break;
        default:
    }
    return state;
};

export default examReducer;
