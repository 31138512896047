import React, {useEffect, useState} from 'react';
import {Col, FormGroup, Label} from 'reactstrap';
import Select from 'react-select';

const FilterItem = ({field, options, value, name, handler}) => {
    const [optionList, setOptionList] = useState();
    useEffect(() => {
        const values = options.map((item, idx) => ({
            value: idx,
            label: item === '' ? 'All' : item,
        }));
        setOptionList(values);
    }, [options]);

    const id = field + 'Filter';
    const selectValue =
        !value || !optionList
            ? {
                  value: -1,
                  label: 'All',
              }
            : optionList.filter(item => value === item.label)[0];
    return (
        <FormGroup row>
            <Label htmlFor={id} sm={3}>
                Filter {name}
            </Label>
            <Col sm={9}>
                <Select
                    id={id}
                    name={field}
                    options={optionList}
                    value={selectValue}
                    onChange={item => handler(field, item.label)}
                />
            </Col>
        </FormGroup>
    );
};

export default FilterItem;
