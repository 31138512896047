import React from 'react';
import {Col, InputGroup, InputGroupAddon, Row} from 'reactstrap';
import Select from 'react-select';

export const Exam = ({value, population, handle}) => {
    return (
        <Row>
            <Col xs={12}>
                <InputGroup>
                    <InputGroupAddon addonType='prepend'>Exam</InputGroupAddon>
                    <Select
                        styles={selectStyles}
                        options={population}
                        defaultValue={value}
                        onChange={handle}
                        placeholder='Select exam...'
                    />
                </InputGroup>
            </Col>
        </Row>
    );
};

export const Exams = props => {
    return (
        <Row>
            <Col xs={12}>
                <InputGroup>
                    <InputGroupAddon addonType='prepend'>Exams</InputGroupAddon>
                    <Select
                        styles={selectStyles}
                        isMulti
                        closeMenuOnSelect={false}
                        options={props.population}
                        value={props.data}
                        onChange={props.handle}
                        placeholder='Select exam to add...'
                    />
                </InputGroup>
            </Col>
        </Row>
    );
};

export const Questions = props => {
    return (
        <Row>
            <Col xs={12}>
                <InputGroup>
                    <InputGroupAddon addonType='prepend'>Questions</InputGroupAddon>
                    <Select
                        styles={selectStyles}
                        isMulti
                        isDisabled={props.disable}
                        closeMenuOnSelect={false}
                        options={props.population}
                        value={props.data}
                        onChange={props.handle}
                        placeholder='Select questions to assign...'
                    />
                </InputGroup>
            </Col>
        </Row>
    );
};

export const Schools = props => {
    return (
        <Row>
            <Col xs={12}>
                <InputGroup>
                    <InputGroupAddon addonType='prepend'>Schools</InputGroupAddon>
                    <Select
                        styles={selectStyles}
                        isMulti
                        closeMenuOnSelect={false}
                        options={props.population}
                        value={props.data}
                        onChange={props.handle}
                        placeholder='Select school to add...'
                    />
                </InputGroup>
            </Col>
        </Row>
    );
};

export const selectStyles = {
    container: provided => ({
        ...provided,
        width: '100%',
        margin: 0,
        flex: 1,
    }),
    control: provided => ({
        ...provided,
        borderRadius: 0,
        borderColor: 'hsl(0, 0%, 80%)',
    }),
};
