import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import Loading from '../waiting';
import {useOrderSelectData} from '../../hooks/useApiOrders';

const SelectOrder = ({reviewer, value, handleSelect}) => {
    const [order, setOrder] = useState(null);

    const selectOrders = data =>
        data.filter(
            o => reviewer?.value === 'all' || reviewer?.orders?.includes(parseInt(o.value))
        );
    const {orderDropDown} = useOrderSelectData(reviewer ? selectOrders : undefined);

    useEffect(() => {
        if (!orderDropDown) return;
        const selection = orderDropDown.filter(o => o.value === value)[0] || null;
        setOrder(selection);
    }, [value, orderDropDown]);

    if (!orderDropDown) return <Loading>Loading . . .</Loading>;

    return (
        <Select
            options={orderDropDown}
            value={order}
            onChange={v => handleSelect(v.value)}
            placeholder='Enter response code or teacher name...'
        />
    );
};

export default SelectOrder;
