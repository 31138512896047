import * as act from '../actions/actionTypes';

const resetState = {
    status: null,
    test: 0,
    order: 0,
    serial: 0,
    studentCode: null,
    teacher: 0,
    examName: '',
    teacherName: '',
    qrCode: '',
    firstName: '',
    middleInitial: '',
    lastName: '',
    responses: {},
    persist: {},
    persistChange: [],
    questions: [],
    uploads: [],
    promptUrl: null,
};

const studentReducer = (state = {...resetState}, action) => {
    switch (action.type) {
        case act.SET_STUDENT_PERSIST:
            // {key: value, key: value, ...}
            state = {
                ...state,
                persist: {
                    ...state.persist,
                    ...action.payload,
                },
                persistChange: [
                    ...new Set([
                        ...state.persistChange,
                        ...Object.keys(action.payload).filter(
                            key => state.persist && state.persist[key] !== action.payload[key]
                        ),
                    ]),
                ],
            };
            break;
        case act.PUT_STUDENTS_SUCCESS:
            state = {
                ...state,
                persistChange: [],
            };
            break;
        case act.FETCH_USER_SUCCESS:
        case act.POST_USER_SUCCESS:
            const {student, ...user} = action.payload;
            if (user.isStudent) {
                state = {
                    ...state,
                    ...student,
                    studentCode: user.userId,
                    firstName: user.firstName,
                    middleInitial: user.middleInitial,
                    lastName: user.lastName,
                    status:
                        !user.firstName && !user.middleInitial && !user.lastName ? 'start' : 'open',
                };
            }
            break;
        case act.POST_STUDENTS_REQUEST:
            state = {
                ...state,
                status: 'sendingName',
                promptUrl: null,
            };
            break;
        case act.POST_STUDENTS_SUCCESS:
            state = {
                ...state,
                ...action.payload.student,
                status: 'open',
                promptUrl: null,
            };
            break;
        case act.POST_STUDENTS_FAILURE:
            state = {
                ...state,
                status: 'start',
                promptUrl: null,
            };
            break;
        case act.POST_RESPONSE_REQUEST:
            state = {
                ...state,
                questions: state.questions.map((val, idx) =>
                    idx !== action.payload.index ? val : {...val, progress: 0, status: 'uploading'}
                ),
                promptUrl: null,
            };
            break;
        case act.POST_RESPONSE_LOADING:
            state = {
                ...state,
                questions: state.questions.map((val, idx) =>
                    idx !== action.payload.index ? val : {...val, progress: action.payload.progress}
                ),
            };
            break;
        case act.POST_RESPONSE_SUCCESS:
            state = {
                ...state,
                questions: state.questions.map((val, idx) =>
                    idx !== action.payload.index
                        ? val
                        : {...val, status: 'uploaded', progress: null}
                ),
                uploads: [...state.uploads, ...action.payload.data.files],
            };
            break;
        case act.POST_RESPONSE_FAILURE:
            state = {
                ...state,
                questions: state.questions.map((val, idx) =>
                    idx !== action.payload.index
                        ? val
                        : {...val, progress: 0, status: action.payload.message}
                ),
            };
            break;
        case act.PUT_RESPONSE_REQUEST:
            state = {
                ...state,
                status: 'submitting',
                promptUrl: null,
            };
            break;
        case act.PUT_RESPONSE_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                status: 'open',
            };
            break;
        case act.PUT_RESPONSE_FAILURE:
            state = {
                ...state,
                status: 'open',
            };
            break;
        case act.GET_EXAM_PROMPT_SUCCESS:
            state = {
                ...state,
                promptUrl: action.payload,
            };
            break;
        default:
    }
    return state;
};

export default studentReducer;
