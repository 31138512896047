import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useAnswerSheetsData} from '../../hooks/useApiOrders';
import {Link, Redirect} from 'react-router-dom';
import {
    Container,
    Button,
    Row,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Form,
    FormGroup,
} from 'reactstrap';
import Loading from '../../components/waiting';
import FilterItem from '../../components/input/FilterItem';
import {submitJob} from '../../hooks/useApiJobs';
import useAuth from '../../hooks/useAuth';
import AwaitJob from '../../components/AwaitJob';

const GenerateAnswerSheets = () => {
    const {ordersData} = useAnswerSheetsData();
    const [entities, setEntities] = useState(null);
    const [registrant, setRegistrant] = useState('');
    const [schools, setSchools] = useState(null);
    const [school, setSchool] = useState('');
    const [exams, setExams] = useState(null);
    const [exam, setExam] = useState('');
    const [filtered, setFiltered] = useState(null);
    const [selectAll, setSelectAll] = useState(true);

    const dispatch = useDispatch();
    const auth = useAuth(dispatch);
    const {jobId, reset, generateAnswerSheets} = submitJob();

    useEffect(() => {
        reset();
    }, []);
    useEffect(() => {
        if (!ordersData) return;
        setEntities(ordersData.entities);
        setSchools(ordersData.schools);
        setExams(ordersData.exams);
        setFiltered(ordersData.data.map(x => ({...x, selected: true, add: '0'})));
    }, [ordersData]);

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not an admin */
    if (auth.isAdmin === false) return <Redirect to='/' />;

    const handleFilter = (name, value) => {
        // capture new filter values
        let newRegistrant, newSchool, newExam;
        switch (name) {
            case 'registrant':
                newRegistrant = value === 'All' ? '' : value;
                newSchool = school;
                newExam = exam;
                break;
            case 'school':
                newRegistrant = registrant;
                newSchool = value === 'All' ? '' : value;
                newExam = exam;
                break;
            case 'exam':
                newRegistrant = registrant;
                newSchool = school;
                newExam = value === 'All' ? '' : value;
                break;
            default:
                return;
        }
        // generate new filtered list matching the selected filters
        const newFiltered = ordersData.data
            .filter(
                item =>
                    (!newRegistrant || item.registrant === newRegistrant) &&
                    (!newSchool || item.school_name === newSchool) &&
                    (!newExam || `${item.exam_name} (${item.test_id})` === newExam)
            )
            .map(item => {
                return {...item, selected: true, add: '0'};
            });
        // create new lists of filter options from the currently filtered population
        const newEntities = Array.from(new Set(newFiltered.map(item => item.registrant))).concat(
            ''
        );
        const newSchools = Array.from(new Set(newFiltered.map(item => item.school_name))).concat(
            ''
        );
        const newExams = Array.from(
            new Set(newFiltered.map(item => `${item.exam_name} (${item.test_id})`))
        ).concat('');
        // update state with all of our calculated values
        setEntities(newEntities);
        setSchools(newSchools);
        setExams(newExams);
        setRegistrant(newEntities.indexOf(newRegistrant) === -1 ? '' : newRegistrant);
        setSchool(newSchools.indexOf(newSchool) === -1 ? '' : newSchool);
        setExam(newExams.indexOf(newExam) === -1 ? '' : newExam);
        setFiltered(newFiltered);
        setSelectAll(true);
    };

    const handleCheck = (id, value) => {
        if (id === 'selectAll') {
            setSelectAll(value);
            setFiltered(filtered.map(item => ({...item, selected: value})));
        } else {
            setFiltered(
                filtered.map(item => ({
                    ...item,
                    selected: item.order_id === id ? value : item.selected,
                }))
            );
        }
    };

    const handleAdd = (event, id) => {
        setFiltered(
            filtered.map(item => ({
                ...item,
                add: item.order_id === id ? event.target.value : item.add,
            }))
        );
    };

    const handleSubmit = event => {
        event.preventDefault();
        const payload = {
            action: event.target.name,
            items: filtered
                .filter(item => item.selected)
                .map(item => ({id: item.order_id, add: parseInt(item.add, 10)})),
        };
        generateAnswerSheets(payload);
    };

    return (
        <>
            <Form>
                <Row>
                    <Col sm={10}>
                        <Filters
                            isLoading={!ordersData?.data}
                            jobId={jobId}
                            registrant={registrant}
                            entities={entities}
                            school={school}
                            schools={schools}
                            exam={exam}
                            exams={exams}
                            onChange={handleFilter}
                            onClose={reset}
                        />
                    </Col>
                    <Col sm='2'>
                        <Link to='/admin' className='btn btn-primary btn-block'>
                            Home
                        </Link>
                    </Col>
                </Row>
                <Container>
                    <Row>
                        <Col sm={4}>
                            <InputGroup>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <Input
                                            addon
                                            type='checkbox'
                                            name='selectAll'
                                            checked={selectAll}
                                            onChange={() => handleCheck('selectAll', !selectAll)}
                                        />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <span className='form-control'>Select All</span>
                            </InputGroup>
                        </Col>
                        <Col sm={4}>
                            <Button
                                name='codes'
                                className='btn btn-secondary btn-block'
                                onClick={handleSubmit}
                            >
                                Generate Student Codes
                            </Button>
                        </Col>
                        <Col sm={4}>
                            <Button
                                name='print'
                                className='btn btn-primary btn-block'
                                onClick={handleSubmit}
                            >
                                Print Packets
                            </Button>
                        </Col>
                    </Row>
                </Container>
                {filtered?.map(order => (
                    <FormGroup row key={order.order_id}>
                        <Col>
                            <InputGroup>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <Input
                                            addon
                                            type='checkbox'
                                            name='selected'
                                            checked={order.selected}
                                            onChange={() =>
                                                handleCheck(order.order_id, !order.selected)
                                            }
                                        />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <span className='form-control h-auto'>
                                    {order.printed ? ' (reprint) ' : ' '}
                                    {order.exam_name} ({order.test_id}) - {order.school_name} -{' '}
                                    {order.teacher_first} {order.teacher_last} (
                                    {order.number_of_students} students)
                                </span>
                                <InputGroupAddon addonType='append'>
                                    <InputGroupText htmlFor={'append_' + order.order_id}>
                                        Add:
                                    </InputGroupText>
                                    <Input
                                        className='h-auto'
                                        type='number'
                                        id={'append_' + order.order_id}
                                        name='append'
                                        onChange={e => {
                                            handleAdd(e, order.order_id);
                                        }}
                                        value={order.add}
                                        max='999'
                                        style={{
                                            width: '76px',
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                        }}
                                    />
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </FormGroup>
                ))}
            </Form>
        </>
    );
};

const Filters = ({
    isLoading,
    jobId,
    registrant,
    entities,
    school,
    schools,
    exam,
    exams,
    onChange,
    onClose,
}) => {
    if (isLoading) return <Loading>Loading orders . . .</Loading>;
    if (jobId)
        return (
            <AwaitJob jobId={jobId} onClose={onClose}>
                Generating answer sheets
            </AwaitJob>
        );
    if (!entities || !schools || !exams) return <Loading>Sorting filters . . .</Loading>;
    return (
        <>
            <FilterItem
                field='registrant'
                name='Ordering Entity'
                value={registrant}
                options={entities}
                handler={onChange}
            />
            <FilterItem
                field='school'
                name='School'
                value={school}
                options={schools}
                handler={onChange}
            />
            <FilterItem field='exam' name='Exam' value={exam} options={exams} handler={onChange} />
        </>
    );
};

export default GenerateAnswerSheets;
