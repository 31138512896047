import React from 'react';
import {Link} from 'react-router-dom';
import {
    Collapse,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardFooter,
    Button,
    Table,
} from 'reactstrap';
import {LoadingRow} from '../../components/waiting';

const TeacherReportStatus = props => {
    const [show, setShow] = React.useState(false);
    const [statusReport, setStatusReport] = React.useState(<LoadingRow>Loading . . .</LoadingRow>);

    const {teacher_report} = props;
    React.useEffect(() => {
        if (show && teacher_report) {
            setStatusReport(
                teacher_report.map((item, idx) => (
                    <tr key={idx}>
                        <td>{item.state}</td>
                        <td>{item.school}</td>
                        <td>{item.exam}</td>
                        <td>{item.teacher}</td>
                        <td className='text-right'>{item.scanned}</td>
                        {item.unevaluated_responses > 0 || item.scanned === 0 ? (
                            <td className='text-center'>
                                {item.unevaluated_responses} unevaluated responses
                            </td>
                        ) : (
                            <td className='text-center'>
                                <Link
                                    className='btn btn-primary btn-xs'
                                    to={'/admin/report/' + item.order_id}
                                >
                                    Ready
                                </Link>
                            </td>
                        )}
                    </tr>
                ))
            );
        }
    }, [show, teacher_report]);

    return (
        <Card>
            <CardHeader
                onClick={() => {
                    setShow(!show);
                }}
                className='pointer'
            >
                <CardTitle className='text-white'>Response and Report Status by Teacher</CardTitle>
            </CardHeader>
            <Collapse isOpen={show}>
                <CardBody>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>State</th>
                                <th>School</th>
                                <th>Exam</th>
                                <th>Teacher</th>
                                <th>Responses</th>
                                <th>Report Status</th>
                            </tr>
                        </thead>
                        <tbody>{statusReport}</tbody>
                    </Table>
                </CardBody>
                <CardFooter className='text-right'>
                    <Button
                        className='btn-primary'
                        size='sm'
                        disabled={!statusReport}
                        onClick={props.download}
                    >
                        Export Detail
                    </Button>
                </CardFooter>
            </Collapse>
        </Card>
    );
};

export default TeacherReportStatus;
