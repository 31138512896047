import React, {useEffect, useState} from 'react';
import {Row, Col, TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import ExamHeading from './ExamHeading';
import ExamsTab from './ExamTabExams';
import QuestionsTab from './ExamTabQuestions';
import AnswerKeyTab from './ExamTabAnswerKey';
import ExamModal from './ExamModal';
import CopyYearModal from './CopyYearModal';
import * as Act from '../../store/actions/actionCreators';
import * as API from '../../store/actions/fetchActions';
import classnames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';

const Exam = () => {
    const [examInput, setExamInput] = useState({});
    const dispatch = useDispatch();
    const activeTab = useSelector(state => state.exam.activeTab);
    const modalOpen = useSelector(state => state.exam.modalOpen);
    const year = useSelector(state => state.exam.year);
    const years = useSelector(state => state.exam.years);
    const exam = useSelector(state => state.exam.exam);
    const exams = useSelector(state => state.exam.exams);

    useEffect(() => {
        dispatch(API.fetchExams(year || years[0]));
    }, [year]);
    useEffect(() => {
        if (!exam.sorted) dispatch(Act.sortExamTab('exams', exam.column ? exam.column : 'test_id'));
    }, [exam.sorted, exam.column]);

    const modalToggle = (e, type, id) => {
        e.preventDefault();
        switch (type) {
            case 'exam':
                const selectExam = exams.find(e => e.exam_id === id);
                setExamInput({
                    ...selectExam,
                    online_exam_data: selectExam.online_exam_data || [], // Ensure passages are initialized
                });
                dispatch(Act.setExamModal({main: 'exam'}));
                break;
            case 'copy':
                dispatch(Act.setExamModal({main: 'copy'}));
                break;
            default:
                dispatch(Act.setExamModal({main: null}));
        }
    };

    const handleSelect = event => {
        const choice = parseInt(event.target.value);
        if (choice !== exam.exam_id) {
            dispatch(Act.setExamSelect(choice));
        }
    };

    const handleAddExam = () => {
        setExamInput({
            exam_id: -1,
            school_year: year,
            exam_name: '',
            test_id: 0,
            discipline: '',
            release_year: year,
            owned_by: 'PES',
            free_response_quantity: 0,
            free_response_time_limit: null,
            free_response_url: null,
            multiple_choice_quantity: 0,
            multiple_choice_time_limit: null,
            multiple_choice_url: null,
            preset_comments_required: false,
            resources: [],
            online_exam_data: [], // Initialize passages for a new exam
        });
        dispatch(Act.setExamModal({main: 'exam'}));
    };

    const handleExamValueChange = (name, value) => {
        setExamInput({...examInput, [name]: value});
    };

    const onYearChange = evt => {
        dispatch(Act.setExamYear(evt.target.value));
        dispatch(API.fetchExams(evt.target.value));
    };

    const isViewingHistory = years && Math.max(...years) !== year;

    const examTab = activeTab === 'exams';
    const questionTab = activeTab === 'questions';
    const answerTab = activeTab === 'answers';

    return (
        <>
            <ExamHeading
                year={year}
                onChange={onYearChange}
                modalToggle={modalToggle}
                exam={exam}
                handleExam={handleAddExam}
            />
            <Row>
                <Col>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({active: examTab})}
                                onClick={() => {
                                    dispatch(Act.setExamTab('exams'));
                                }}
                            >
                                Exams
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({active: questionTab})}
                                onClick={() => {
                                    dispatch(Act.setExamTab('questions'));
                                }}
                            >
                                FR Questions
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({active: answerTab})}
                                onClick={() => {
                                    dispatch(Act.setExamTab('answers'));
                                }}
                            >
                                MC Answer Keys
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId='exams'>
                            <ExamsTab
                                exam={exam}
                                exams={exams}
                                canCopy={isViewingHistory}
                                onSelect={handleSelect}
                                modalToggle={modalToggle}
                            />
                        </TabPane>
                        <TabPane tabId='questions'>
                            <QuestionsTab exam_id={exam.exam_id} />
                        </TabPane>
                        <TabPane tabId='answers'>
                            <AnswerKeyTab exam_id={exam.exam_id} />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
            <ExamModal
                isOpen={modalOpen === 'exam'}
                toggle={modalToggle}
                onChange={handleExamValueChange}
                onSave={data => dispatch(API.saveExam(data))}
                onDelete={data => dispatch(API.deleteExam(data))}
                saving={!!exam.saving}
                deleting={!!exam.deleting}
                {...examInput}
            />
            <CopyYearModal isOpen={modalOpen === 'copy'} toggle={modalToggle} />
        </>
    );
};

export default Exam;
