import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Card, CardHeader, CardTitle, CardBody} from 'reactstrap';
import teacherPackets from '../../export/teacherPackets';
import TeacherReportStatus from './CardTeacherReportStatus';
import UnscoredFreeResponse from './CardUnscoredFreeResponse';
import AlertRecategorize from './AlertRecategorize';
import ReportsCard from '../reports/CardReports';

const MidAdminDashboard = ({
    adminLevel,
    recategorize,
    teacher_packets,
    getMetrics,
    unscored_free_response,
    teacher_report,
    onReportDone,
}) => {
    const [teacherPacketData, setTeacherPacketData] = useState(null);

    useEffect(() => {
        getMetrics('recategorize');
    }, []);
    // we get teacher packet data here because it's used on two different cards
    useEffect(() => {
        if (!teacher_packets) {
            getMetrics('teacher_packets');
            return;
        }
        const orderedTeacherData = [...teacher_packets].sort((a, b) => {
            if (a.state > b.state) return 1;
            if (a.state < b.state) return -1;
            if (a.school > b.school) return 1;
            if (a.school < b.school) return -1;
            if (a.exam > b.exam) return 1;
            if (a.exam < b.exam) return -1;
            if (a.teacher > b.teacher) return 1;
            if (a.teacher < b.teacher) return -1;
            return 0;
        });
        setTeacherPacketData(orderedTeacherData);
    }, [teacher_packets]);

    const hasResponseMaintenance = !!(adminLevel & 2);
    const hasTestResponseReports = !!(adminLevel & 4);
    const hasAdditional = hasResponseMaintenance || hasTestResponseReports;

    return (
        <>
            <Row>
                <Col>
                    <AlertRecategorize count={recategorize} />
                    <UnscoredFreeResponse
                        unscored_free_response={unscored_free_response}
                        getMetrics={getMetrics}
                    />
                </Col>
                <Col>
                    <TeacherReportStatus
                        teacher_report={teacherPacketData}
                        download={() => teacherPackets(teacher_report)}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    {hasAdditional && (
                        <Card>
                            <CardHeader>
                                <CardTitle className='text-white'>Additional Actions</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <ul>
                                    {hasResponseMaintenance && (
                                        <li>
                                            <Link to='/admin/response-maintenance'>
                                                Response Maintenance
                                            </Link>
                                        </li>
                                    )}
                                    {hasTestResponseReports && (
                                        <li>
                                            <Link to='/admin/test-responses'>
                                                Test Response Scores
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </CardBody>
                        </Card>
                    )}
                    <ReportsCard onReportDone={onReportDone} />
                </Col>
            </Row>
        </>
    );
};

export default MidAdminDashboard;
