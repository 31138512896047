import {handleErrors, axiosErrors, API1, apiGet, apiDelete, apiPost, apiPut} from './fetchHelpers';
import * as act from './actionCreators';
import axios from 'axios';

// `api${httpAction}` functions are called with
//   `request`: [optional] initial request redux action
//   `success`: success redux action, which is dispatched with payload from the api response
//   `url`
//   `payload`: [optional] data to send in post/put requests
// what, no error response? correct, these all handle api errors uniformly by dispatching a toast notification
// please keep these in alphabetical order
export const deleteExam = exam_id =>
    apiDelete(null, act.deleteExamSuccess, '/exam/exam/' + exam_id);
export const fetchRegistrants = () =>
    apiGet(null, act.fetchRegistrantsSuccess, '/customer/registrant');
export const getDistricts = () =>
    apiGet(act.getDistrictsRequest, act.getDistrictsSuccess, '/teacher/districts');
export const getExamPrompt = section =>
    apiGet(null, act.getExamPromptSuccess, '/exam/prompt/' + section);
export const getMetrics = item =>
    apiGet(null, act.getMetricsSuccess, '/admin/metrics?include=' + item);
export const getRegistrantOptionsByKey = key =>
    apiGet(
        act.getRegistrantOptionsByKeyRequest,
        act.getRegistrantOptionsByKeySuccess,
        '/customer/register/' + key
    );
export const getScoresForTestResponse = response_id =>
    apiGet(
        act.getScoresForTestResponseRequest,
        act.getScoresForTestResponseSuccess,
        '/admin/test-response/scores/' + response_id
    );
export const getTeachersBySchool = school_id =>
    apiGet(
        act.getTeachersBySchoolRequest,
        act.getTeachersBySchoolSuccess,
        '/teacher/teacher/' + school_id
    );
export const getTeachersStudents = () =>
    apiGet(null, act.getTeachersStudentsSuccess, '/teacher/students');
export const getTestResponsesForAdmin = () =>
    apiGet(
        act.getTestResponsesForAdminRequest,
        act.getTestResponsesForAdminSuccess,
        '/admin/test-responses'
    );
export const getTestResponsesForEditing = question_id =>
    apiGet(
        act.getTestResponsesForEditingRequest,
        act.getTestResponsesForEditingSuccess,
        '/response/fr-response-test-responses/' + question_id
    );
export const postDistrict = data =>
    apiPost(null, act.getDistrictsSuccess, '/teacher/district', data);
export const postExamCopy = exam_id =>
    apiPost(act.copyYearRequest, act.fetchExamSuccess, '/exam/copy', {exam_id});
export const postExamCopyYear = data =>
    apiPost(act.copyYearRequest, act.copyYearSuccess, '/exam/copy_year', data);
export const postExport = e => apiPost(null, act.postExportSuccess, '/admin/export/' + e);
export const postPasswordReset = user_email =>
    apiPost(null, act.resetPasswordSuccess, '/user/password-reset', user_email);
export const postRegistrants = data =>
    apiPost(act.postRegistrantsRequest, act.postRegistrantsSuccess, '/customer/registrant', data);
export const postRegistration = data =>
    apiPost(act.postRegistrationRequest, act.postRegistrationSuccess, '/customer/register', data);
export const postReport = data => apiPost(null, act.postReportSuccess, '/teacher/report', data);
export const postSchool = data => apiPost(null, act.getSchoolsSuccess, '/teacher/school', data);
export const postTeacher = data => apiPost(null, act.getTeachersSuccess, '/teacher/teacher', data);
export const putDistrict = data => apiPut(null, act.getDistrictsSuccess, '/teacher/district', data);
export const putRegistrants = data =>
    apiPut(act.postRegistrantsRequest, act.postRegistrantsSuccess, '/customer/registrant', data);
export const putSchool = data => apiPut(null, act.getSchoolsSuccess, '/teacher/school', data);
export const putTeacher = data =>
    apiPut(null, act.getTeachersSuccess, '/teacher/teacher/' + data.teacher_id, data);

// there are older (non-toast) methods of displaying error messages throughout the code
// these could be converted to "new succinct method" when it's confirmed that error handling is updated,
// as long as there are no other complexities
export function saveExam(data) {
    return dispatch => {
        dispatch(act.saveExamRequest());
        return axios
            .post(API1 + '/exam/exam', data)
            .then(response => {
                dispatch(act.saveExamSuccess(response.data));
            })
            .catch(err => {
                axiosErrors(err, dispatch, act.saveExamFailure);
            });
    };
}

export function fetchExams(year) {
    return dispatch => {
        dispatch(act.fetchExamRequest());
        return axios
            .get(year ? `${API1}/exam/list?schoolYear=${year}` : API1 + '/exam/list')
            .then(resp => {
                dispatch(act.fetchExamSuccess(resp.data));
            })
            .catch(err => {
                axiosErrors(err, dispatch, act.fetchExamFailure);
            });
    };
}

export function fetchReaderExams(questionId) {
    return dispatch => {
        dispatch(act.fetchReaderExamRequest());
        return axios
            .get(questionId ? `${API1}/reader/question/${questionId}` : API1 + '/reader')
            .then(resp => {
                dispatch(act.fetchReaderExamSuccess(resp.data));
            })
            .catch(err => {
                axiosErrors(err, dispatch, act.fetchReaderExamFailure);
            });
    };
}
//David's custom api (DOES NOT WORK)
export function fetchReaderExamsTeacherStudent(questionId) {
    return dispatch => {
        dispatch(act.fetchReaderExamRequest());
        let myresponseid;

        function getReaderId(aQuestionId) {
            let myresult = axios
                .get(aQuestionId ? `${API1}/reader/question/${aQuestionId}` : API1 + '/reader')
                .then(resp => {
                    dispatch(act.fetchReaderExamSuccess(resp.data));
                    console.log('response data from exams api request:');
                    console.dir(resp.data);
                    myresponseid = resp.data.response.responseId;
                    return myresponseid;
                })
                .catch(err => {
                    axiosErrors(err, dispatch, act.fetchReaderExamFailure);
                });

            console.log('axios - ');
            console.dir(myresult);
        }

        function getFreeResponseInfo(responseID) {
            let myresult = axios
                .get(`${API1}/reader/response/${responseID}`)
                .then(testresp => {
                    console.log('response data from response?');
                    console.dir(testresp.data);
                    dispatch(act.fetchReaderExamSuccess(testresp.data));
                })
                .catch(err => {
                    axiosErrors(err, dispatch, act.fetchReaderExamFailure);
                });

            console.log('axios fr - ');
            console.dir(myresult);
        }

        let responsedata = getFreeResponseInfo(getReaderId(questionId));

        let mydata = {
            myresponseid: myresponseid,
            responsedata: responsedata,
        };
        return mydata;
    };
}

export function fetchReaderResponseById(responseId) {
    return dispatch => {
        dispatch(act.fetchReaderExamRequest());
        return axios
            .get(`${API1}/reader/response/${responseId}`)
            .then(resp => {
                dispatch(act.fetchReaderExamSuccess(resp.data));
            })
            .catch(err => {
                axiosErrors(err, dispatch, act.fetchReaderExamFailure);
            });
    };
}

export function postResponseScore(questionId, data) {
    return dispatch => {
        dispatch(act.fetchReaderExamRequest());
        return axios
            .post(`${API1}/reader/${questionId}`, data)
            .then(resp => {
                dispatch(act.fetchReaderExamSuccess(resp.data));
            })
            .catch(err => {
                axiosErrors(err, dispatch, act.fetchReaderExamFailure);
            });
    };
}

export function putRecategorize(questionId, responseId, data) {
    return dispatch => {
        dispatch(act.fetchReaderExamRequest());
        return axios
            .put(`${API1}/reader/response/${responseId}`, data)
            .then(resp => {
                dispatch(act.fetchReaderExamSuccess(resp.data));
            })
            .catch(err => {
                axiosErrors(err, dispatch, act.fetchReaderExamFailure);
            });
    };
}

export function refreshReaderLock(questionId, responseId) {
    return dispatch => {
        dispatch(act.refreshCheckoutRequest());
        return axios
            .patch(`${API1}/reader/response/${responseId}`)
            .then(() => {
                dispatch(act.refreshCheckoutSuccess());
            })
            .catch(err => {
                axiosErrors(err, dispatch, act.refreshCheckoutFailure);
            });
    };
}

export function cancelReaderLock(questionId, responseId) {
    return dispatch => {
        return axios
            .delete(`${API1}/reader/response/${responseId}`)
            .then(() => {
                dispatch(act.deleteCheckoutSuccess());
            })
            .catch(err => {
                axiosErrors(err, dispatch, act.deleteCheckoutFailure);
            });
    };
}

export function putStudents() {
    return (dispatch, getState) => {
        dispatch(act.putStudentsRequest());
        const state = getState();
        if (!state.student.persist || !state.student.persistChange) {
            return;
        }
        const data = Object.keys(state.student.persist)
            .filter(key => state.student.persistChange.includes(key))
            .reduce((obj, key) => {
                obj[key] = state.student.persist[key];
                return obj;
            }, {});
        axios
            .put(API1 + '/user/student', data, {headers: {'content-type': 'application/json'}})
            .then(() => {
                dispatch(act.putStudentsSuccess());
            })
            .catch(err => {
                axiosErrors(err, dispatch, act.putStudentsFailure);
            });
    };
}

export function postScannedResponse(file, history) {
    return dispatch => {
        const data = new FormData();
        data.append('file', file);
        data.append('fileHash', file.fileHash);

        axios
            .post(API1 + '/response/upload-scan', data, {
                onUploadProgress: ProgressEvent => {
                    dispatch(
                        act.postScannedResponseLoading(
                            (ProgressEvent.loaded / ProgressEvent.total) * 100
                        )
                    );
                },
            })
            .then(resp => {
                dispatch(act.postScannedResponseSuccess(resp));
                history.push('/answer/import_step_2/' + resp.data.job_id);
            })
            .catch(err => {
                axiosErrors(err, dispatch, msg => act.postScannedResponseFailure());
            });
    };
}

export function submitMCResponses(data) {
    return dispatch => {
        dispatch(act.putResponseRequest());
        return axios
            .post(API1 + '/response/mc-response', data)
            .then(() => dispatch(act.putResponseSuccess({examMCStatus: false})))
            .catch(err => axiosErrors(err, dispatch, act.putResponseFailure));
    };
}

export function submitFRResponses(payload) {
    return (dispatch, getState) => {
        dispatch(act.putResponseRequest());
        const state = getState();
        const responses = payload || state.student.responses;
        return axios
            .post(API1 + '/response/text', {questions: state.student.questions, responses})
            .then(() => dispatch(act.putResponseSuccess({examFRStatus: false})))
            .catch(err => axiosErrors(err, dispatch, act.putResponseFailure));
    };
}

export function saveTestResponse(question_id, text) {
    return dispatch => {
        dispatch(act.saveTestResponseRequest());
        return fetch(API1 + '/response/test-response/' + question_id, {
            method: 'POST',
            credentials: 'include',
            body: text,
        })
            .then(handleErrors)
            .then(response => {
                dispatch(act.saveTestResponseSuccess(text));
            })
            .catch(err => {
                dispatch(act.saveTestResponseFailure(err.message));
            });
    };
}

export function deleteTestResponse(response_id) {
    return dispatch => {
        dispatch(act.deleteTestResponseRequest(response_id));
        return fetch(API1 + '/response/fr-response-test-response/' + response_id, {
            method: 'DELETE',
            credentials: 'include',
            headers: {'content-type': 'application/json'},
        })
            .then(handleErrors)
            .then(() => {
                dispatch(act.deleteTestResponseSuccess(response_id));
            })
            .catch(err => {});
    };
}
