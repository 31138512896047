import React, {useState, useEffect} from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardFooter,
    Button,
    Progress,
    Collapse,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import Switch from 'react-switch';
import Expand from '../../components/Expand';
import useAuth from '../../hooks/useAuth';
import {useDispatch} from 'react-redux';
import {useJobData, useJobsData, useWorkersData} from '../../hooks/useApiJobs';

export const note = msg => {
    const timestamp = new Date();
    const ms = timestamp.getMilliseconds();
    const timestring = timestamp.toLocaleTimeString();
    console.log(`[${timestring.slice(0, -3)}.${ms}] ${msg}`);
};

const Jobs = ({isOpen}) => {
    note(`Jobs (isOpen=${isOpen})`);
    const [showJobId, setShowJobId] = useState();
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);
    // pass isOpen to react-query hooks so we don't keep calling jobs and workers when user is not on this tab
    const {jobsData, showAllJobs, deleteJob, restartJob} = useJobsData(isOpen);
    const {workers} = useWorkersData(isOpen);
    const showJobData = useJobData(showJobId);
    const adminId = auth ? auth.userId : 'unknown';
    const superAdmin = auth?.adminLevel > 127;

    const startedCount = jobsData?.started?.total_count || 0;
    const failedCount = jobsData?.failed?.total_count || 0;
    const enqueuedCount = jobsData?.enqueued?.total_count || 0;
    const deferredCount = jobsData?.deferred?.total_count || 0;
    const finishedCount = jobsData?.finished?.total_count || 0;
    const totalJobsCount =
        startedCount + failedCount + enqueuedCount + deferredCount + finishedCount;
    note(`Jobs (jobsData? ${!!jobsData})`);

    return (
        <Row>
            <Col md={7}>
                {startedCount > 0 && (
                    <QueueCard
                        {...jobsData.started}
                        queue='started'
                        adminId={adminId}
                        onDelete={deleteJob}
                        onMore={showAllJobs}
                        onShow={setShowJobId}
                    >
                        Running Jobs
                    </QueueCard>
                )}
                {failedCount > 0 && (
                    <QueueCard
                        {...jobsData.failed}
                        queue='failed'
                        adminId={adminId}
                        onDelete={deleteJob}
                        onMore={showAllJobs}
                        onRestart={restartJob}
                        onShow={setShowJobId}
                    >
                        Failed Jobs
                    </QueueCard>
                )}
                {enqueuedCount > 0 && (
                    <QueueCard
                        {...jobsData.enqueued}
                        queue='enqueued'
                        adminId={adminId}
                        onDelete={deleteJob}
                        onMore={showAllJobs}
                        onShow={setShowJobId}
                    >
                        Pending Jobs
                    </QueueCard>
                )}
                {deferredCount > 0 && (
                    <QueueCard
                        {...jobsData.deferred}
                        queue='deferred'
                        adminId={adminId}
                        onDelete={deleteJob}
                        onMore={showAllJobs}
                        onShow={setShowJobId}
                    >
                        Deferred Jobs
                    </QueueCard>
                )}
                {finishedCount > 0 && (
                    <QueueCard
                        {...jobsData.finished}
                        queue='finished'
                        adminId={adminId}
                        onMore={showAllJobs}
                        onShow={setShowJobId}
                    >
                        Finished Jobs
                    </QueueCard>
                )}
                {totalJobsCount === 0 && (
                    <Card>
                        <CardHeader>
                            <CardTitle className='text-white'>Jobs</CardTitle>
                        </CardHeader>
                        <CardBody>No jobs.</CardBody>
                    </Card>
                )}
            </Col>
            <Col md={5}>
                <Card>
                    <CardHeader>
                        <CardTitle className='text-white'>Workers</CardTitle>
                    </CardHeader>
                    <CardBody>
                        {workers && workers.map((item, idx) => <WorkerRow {...item} key={idx} />)}
                    </CardBody>
                </Card>
            </Col>
            <JobFilesModal id={showJobId} data={showJobData} setJob={setShowJobId} />
        </Row>
    );
};

const JobFilesModal = ({id, data, setJob}) => {
    note('JobFilesModal');
    const toggle = () => setJob(null);
    const isOpen = !!id;
    const links = data?.links;
    if (!links || links.length < 1 || !links[0].startsWith('http')) return null;
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{data?.description}</ModalHeader>
            <ModalBody>
                <ul>
                    {links.map((link, idx) => (
                        <li key={idx}>
                            <a href={link}>File {idx + 1}</a>
                        </li>
                    ))}
                </ul>
                <span style={{color: '#00acf3'}}>Result: </span>{' '}
                {data.result && <pre>{data.result}</pre>}
                {data.detail && <pre>{data.detail}</pre>}
            </ModalBody>
        </Modal>
    );
};

const QueueCard = ({
    jobs,
    total_count,
    queue,
    adminId,
    onDelete,
    onMore,
    onRestart,
    onShow,
    children,
}) => {
    note('QueueCard');
    const [showAll, setShowAll] = useState(false);
    const [jobList, setJobList] = useState([]);
    useEffect(() => {
        if (!jobs) setJobList([]);
        else {
            setJobList(jobs.filter(job => showAll || job.owner === adminId));
        }
    }, [jobs, showAll]);
    const haveMore = jobs.length < total_count;
    return (
        <Card>
            <CardHeader>
                <CardTitle className='text-white d-flex justify-content-between'>
                    {children}
                    <label className='m-0' style={{height: '24px'}}>
                        Show{' '}
                        <Switch
                            id='showAll'
                            onChange={() => setShowAll(!showAll)}
                            checked={showAll}
                            checkedIcon={<small style={{paddingLeft: '12px'}}>all</small>}
                            uncheckedIcon={<small>mine</small>}
                            height={22}
                        />
                    </label>
                </CardTitle>
            </CardHeader>
            <CardBody>
                {jobList.map(item => (
                    <JobRow
                        key={item.job}
                        {...item}
                        adminId={adminId}
                        onDelete={onDelete}
                        onRestart={onRestart}
                        onShow={onShow}
                    />
                ))}
            </CardBody>
            {haveMore && (
                <CardFooter className='text-center'>
                    <span className='link' onClick={() => onMore(queue)}>
                        Load All Jobs
                    </span>
                </CardFooter>
            )}
        </Card>
    );
};

const parseISOString = dateString => {
    const part = dateString.split(/\D+/);
    // Month, hour, minute, and second are zero-indexed; year and day are actual
    return new Date(Date.UTC(part[0], part[1] - 1, part[2], part[3] - 1, part[4] - 1, part[5] - 1));
};
const asDateTime = dateString => parseISOString(dateString).toLocaleString();
const asTime = dateString => parseISOString(dateString).toLocaleTimeString();

const WorkerRow = ({name, hostname, birth_date, last_heartbeat, state, successes, failures}) => {
    note('WorkerRow');
    return (
        <Row>
            <Col className='text-white bg-secondary rounded mr-1 ml-1'>
                <span style={{color: '#99defa'}}>
                    <b>{name}</b>
                </span>{' '}
                on {hostname || 'unspecified host'}
                <br />
                {birth_date ? (
                    <span style={{color: '#4dc5f7'}}>Born at {asDateTime(birth_date)}</span>
                ) : (
                    '(unknown birth date)'
                )}{' '}
                <small>(Last heartbeat {asTime(last_heartbeat)})</small>
                <br />
                <b>{state}</b> - lifetime job count: <b>{successes}</b> success / <b>{failures}</b>{' '}
                failed
                <br />
            </Col>
        </Row>
    );
};

const JobRow = ({
    job,
    status,
    owner,
    name,
    description,
    call,
    detail,
    links,
    progress,
    result,
    worker,
    enqueued_at,
    started_at,
    adminId,
    onDelete,
    onRestart,
    onShow,
}) => {
    note('JobRow');
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Row className='bg-light rounded'>
            <Col xs={1}>
                <Expand isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} />
            </Col>
            <Col xs={5}>
                <span> {description} </span>
                <small style={{verticalAlign: 'text-bottom'}}>({name})</small>
            </Col>
            <Col xs={2}>
                {onDelete && progress < 100 && owner === adminId ? (
                    <Button color='danger' size='sm' onClick={() => onDelete(job)}>
                        Cancel
                    </Button>
                ) : (
                    onRestart &&
                    status === 'failed' && (
                        <Button color='warning' size='sm' onClick={() => onRestart(job)}>
                            Restart
                        </Button>
                    )
                )}
            </Col>
            <Col xs={4}>
                <Progress max={100} color={progress < 100 ? 'warning' : 'success'} value={progress}>
                    {progress}%
                </Progress>
            </Col>
            <Collapse isOpen={isOpen} style={{width: '100%'}}>
                {links?.length > 0 && (
                    <Col xs={{offset: 1, size: 11}}>
                        <span style={{color: '#00acf3'}}>Files: </span>{' '}
                        {links?.length > 0 && links[0].startsWith('http')
                            ? links.map((link, idx) => (
                                  <span key={idx}>
                                      [<a href={link}>File {idx + 1}</a>]&nbsp;
                                  </span>
                              ))
                            : onShow && (
                                  <span style={{cursor: 'pointer'}} onClick={() => onShow(job)}>
                                      Click to load {links.length} file link
                                      {links.length > 1 ? 's' : ''}.
                                  </span>
                              )}
                    </Col>
                )}
                <Col xs={{offset: 1, size: 11}}>
                    <span style={{color: '#00acf3'}}>Status: </span>{' '}
                    <span className={status === 'failed' ? 'text-danger' : null}>
                        {status}
                        {worker && (
                            <span>
                                {' '}
                                on worker <strong>{worker}</strong>
                            </span>
                        )}
                    </span>
                </Col>
                {enqueued_at && (
                    <Col xs={{offset: 1, size: 11}}>
                        <span style={{color: '#00acf3'}}>Enqueued at: </span>{' '}
                        <span>{asDateTime(enqueued_at)}</span>
                    </Col>
                )}
                {started_at && (
                    <Col xs={{offset: 1, size: 11}}>
                        <span style={{color: '#00acf3'}}>Started at: </span>{' '}
                        <span>{asDateTime(started_at)}</span>
                    </Col>
                )}
                <Col xs={{offset: 1, size: 11}}>
                    <span>
                        <span style={{color: '#00acf3'}}>Call: </span>
                        <small style={{color: 'unset'}}>({job})</small>
                    </span>
                    <pre>{call}</pre>
                </Col>
                <Col xs={{offset: 1, size: 11}}>
                    <span style={{color: '#00acf3'}}>Result: </span> {result && <pre>{result}</pre>}
                    {detail && <pre>{detail}</pre>}
                </Col>
            </Collapse>
        </Row>
    );
};

export default Jobs;
