import React from 'react';
import './waiting.css';
import classnames from 'classnames';

const Loading = props => (
    <div className={classnames({lContainer: true, lImage: !!props.image, 'w-100': !props.image})}>
        <div className='lDefault'>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <span className={props.className}>{props.children}</span>
        </div>
    </div>
);

export const LoadingRow = props => (
    <tr>
        <td colSpan={0}>
            <Loading>{props.children}</Loading>
        </td>
    </tr>
);

export default Loading;
