import React, {useEffect, useState} from 'react';
import {convertFromRaw, Editor, EditorState, RichUtils} from 'draft-js';
import Loading from '../waiting';

const styles = ['BOLD', 'ITALIC', 'UNDERLINE'];
const Toolbar = ({onClick}) => (
    <div className='toolbar'>
        {styles.map(style => (
            <button key={style} onClick={onClick} name={style}>
                &lt;{style[0]}&gt;
            </button>
        ))}
    </div>
);

const TextEditor = ({value, raw, onChange}) => {
    const [answer, setAnswer] = useState(raw);
    useEffect(() => {
        if (!raw) setAnswer(EditorState.createEmpty());
        
    }, [raw]);
    useEffect(() => {
        if (!value) return;
        setAnswer(EditorState.createWithContent(convertFromRaw(value)));
    }, [value]);
    useEffect(() => {
        onChange(answer);
    }, [answer]);

    const styleClick = e => {
        setAnswer(RichUtils.toggleInlineStyle(answer, e.target.name));
    };
    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setAnswer(newState);
            return 'handled';
        }
        return 'not-handled';
    };
    if (!answer) return <Loading />;
    return (
        <div className='border border-primary mb-2'>
            <Toolbar onClick={styleClick} />
            <Editor
                editorState={answer}
                handleKeyCommand={handleKeyCommand}
                onChange={setAnswer}
                spellCheck={false}
                placeholder='Enter your answer here ...'
            />
        </div>
    );
};

export default TextEditor;
