import {useMutation, useQuery, useQueryClient} from 'react-query';
import {apiCall} from './useApi';

export const usePropertiesData = enabled => {
    const queryClient = useQueryClient();
    const {data, isLoading} = useQuery(['property'], () => apiCall('get', '/property'), {enabled});
    const saveMutation = useMutation(payload => apiCall('post', '/property', payload), {
        onSuccess: () => queryClient.refetchQueries(['property']),
    });
    return {
        properties: data,
        isLoading,
        updateProp: saveMutation.mutate,
    };
};
