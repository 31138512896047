import React from 'react';
import {Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Label} from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';

const ResponseOptions = ({
    questionNumber,
    reviewer,
    scores,
    comment,
    index,
    handleChange,
    handleSubmit,
}) => (
    <>
        <InputGroup>
            <InputGroupAddon addonType='prepend'>
                <InputGroupText className='primary' tag='label'>
                    Question
                </InputGroupText>
            </InputGroupAddon>
            <Input value={questionNumber} readOnly />
        </InputGroup>
        <div className='score-group'>
            <InputGroup>
                <InputGroupAddon addonType='prepend'>
                    <InputGroupText className='primary' tag='label'>
                        Scores
                    </InputGroupText>
                </InputGroupAddon>
                <Input value='(first score = -1 = blank)' readOnly />
                <InputGroupAddon addonType='append'>
                    <Button
                        color='primary'
                        className='btn-block'
                        onClick={() => handleSubmit('deleteScore', index)}
                    >
                        Clear Scores
                    </Button>
                </InputGroupAddon>
            </InputGroup>
            {scores.map((score, idx) => (
                <InputGroup key={idx} size='sm'>
                    <Label for={`score-${idx}`} sm={4}>
                        {score.part}
                    </Label>
                    <Col sm={2}>
                        <Input
                            name={`score-${idx}`}
                            value={score.score ? score.score : ''}
                            type='number'
                            min={-1}
                            max={9}
                            onChange={e =>
                                handleChange({
                                    section: 'scores',
                                    index: index,
                                    name: 'score',
                                    partIndex: idx,
                                    value: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col sm={6}>
                        <Input
                            name={`comment-${idx}`}
                            value={score.comment}
                            tag={TextareaAutosize}
                            placeholder='comment'
                            onChange={e =>
                                handleChange({
                                    section: 'scores',
                                    index: index,
                                    name: 'comment',
                                    partIndex: idx,
                                    value: e.target.value,
                                })
                            }
                        />
                    </Col>
                </InputGroup>
            ))}
        </div>
        <InputGroup>
            <InputGroupAddon addonType='prepend'>
                <InputGroupText className='primary' tag='label'>
                    Reviewer
                </InputGroupText>
            </InputGroupAddon>
            <Input value={reviewer} readOnly />
        </InputGroup>
        <InputGroup>
            <InputGroupAddon addonType='prepend'>
                <InputGroupText className='primary' tag='label'>
                    Other Comments
                </InputGroupText>
            </InputGroupAddon>
            <Input
                name='txtComment'
                value={comment ? comment : ''}
                tag={TextareaAutosize}
                onChange={e =>
                    handleChange({
                        section: 'responses',
                        index: index,
                        name: 'comment',
                        value: e.target.value,
                    })
                }
            />
        </InputGroup>
        <InputGroup>
            <Button
                color='primary'
                className='flex-fill'
                onClick={() => handleSubmit('responses', index)}
            >
                Update FR Score
            </Button>
        </InputGroup>
        <hr />
    </>
);

export default ResponseOptions;
