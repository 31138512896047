import React from 'react';
import {Card, CardBody, CardHeader, CardTitle} from 'reactstrap';
import {reportMap} from './reports';
import {AwaitJobModal} from '../components/AwaitJob';
import {submitJob} from '../hooks/useApiJobs';

const CardTeacherReports = ({exam_name, test_id, reports}) => {
    const {jobId, reset, generateTeacherReport} = submitJob();
    const handleLink = report => generateTeacherReport({testId: test_id, reportId: report});
    return (
        <Card>
            <CardHeader>
                <CardTitle className='text-white'>Reports for {exam_name}</CardTitle>
            </CardHeader>
            <CardBody>
                <ul>
                    {reports.map(rptId => (
                        <li key={rptId}>
                            <span className='link' onClick={() => handleLink(rptId)}>
                                {reportMap[rptId]}
                            </span>
                        </li>
                    ))}
                </ul>
                <AwaitJobModal jobId={jobId} onClose={reset}>
                    Generating report...
                </AwaitJobModal>
            </CardBody>
        </Card>
    );
};

export default CardTeacherReports;
