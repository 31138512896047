import React from 'react';
import {Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap';
import Loading from '../../components/waiting';
import {Link} from 'react-router-dom';

const AlertUnassigned = props => {
    if (props.count === 0) return null;
    if (!props.count) return <Loading>Loading unassigned response count . . .</Loading>;
    return (
        <Card className='border-danger'>
            <CardHeader className='bg-danger'>
                <CardTitle>Assign Scanned Response Images</CardTitle>
            </CardHeader>
            <CardBody>{props.count} imported images need to be assigned manually.</CardBody>
            <CardFooter>
                <Link className='btn btn-danger btn-sm' to='/admin/assign-unreadable-responses'>
                    Assign Responses Now
                </Link>
            </CardFooter>
        </Card>
    );
};

export default AlertUnassigned;
