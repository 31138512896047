import React from 'react';
import {useUsersData} from '../../hooks/useApiUser';
import {CardHeader} from 'reactstrap';
import Select from 'react-select';

const SelectUser = ({selectedUser, onSelect, active}) => {
    const {userList, isLoading} = useUsersData(active);
    return (
        <CardHeader>
            <Select
                styles={{
                    menu: provided => ({...provided, zIndex: 3}),
                }}
                options={userList}
                value={selectedUser}
                onChange={onSelect}
                isLoading={isLoading}
                placeholder='Select user ...'
            />
        </CardHeader>
    );
};

export default SelectUser;
