import React from 'react';
import {Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from 'reactstrap';
import ResponseImage from './ResponseImage';

const MCPage = props => {
    if (!props.mcAnswers) {
        return null;
    }
    return (
        <Row>
            <Col sm='5'>{props.image ? <ResponseImage {...props.image} /> : null}</Col>
            <Col sm='7'>
                <Row>
                    {props.mcAnswers.map((item, idx) => (
                        <Col xs='3' key={idx}>
                            <InputGroup size='sm'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText className='primary' tag='label'>
                                        {item.questionNumber}
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    name={item.id}
                                    value={item.answer}
                                    maxLength='5'
                                    onChange={e =>
                                        props.handleChange({
                                            section: 'mcPage',
                                            name: 'mcAnswers',
                                            index: idx,
                                            elementName: 'answer',
                                            value: e.target.value,
                                        })
                                    }
                                />
                            </InputGroup>
                        </Col>
                    ))}
                </Row>
                <InputGroup>
                    <Button
                        color='primary'
                        className='btn-block'
                        onClick={() => props.handleSubmit('mcPage')}
                    >
                        Update MC Answers
                    </Button>
                </InputGroup>
            </Col>
        </Row>
    );
};

export default MCPage;
