import React from 'react';
import {Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap';
import Loading from '../../components/waiting';
import {Link} from 'react-router-dom';

const AlertUnprinted = props => {
    if (props.count === 0) return null;
    if (!props.count) return <Loading>Loading unprinted orders count . . .</Loading>;
    return (
        <Card className='border-warning'>
            <CardHeader className='bg-warning'>
                <CardTitle>Print Answer Sheets</CardTitle>
            </CardHeader>
            <CardBody>{props.count} orders have not been printed.</CardBody>
            <CardFooter>
                <Link className='btn btn-warning btn-sm' to='/admin/generate-answer-sheets'>
                    Print Answer Sheets Now
                </Link>
            </CardFooter>
        </Card>
    );
};

export default AlertUnprinted;
