import * as act from './actionTypes';

// please keep these in alphabetical order
export const addToast = (message, type, icon) => ({
    type: act.ADD_TOAST,
    payload: {message, type, icon},
});
export const axiosResponseError = payload => ({type: act.AXIOS_RESPONSE_ERROR, payload});

export const copyYearRequest = () => ({type: act.COPY_YEAR_REQUEST});
export const copyYearSuccess = payload => ({type: act.COPY_YEAR_SUCCESS, payload});

export const deleteCheckoutFailure = message => ({
    type: act.DELETE_CHECKOUT_FAILURE,
    payload: message,
});
export const deleteCheckoutSuccess = () => ({type: act.DELETE_CHECKOUT_SUCCESS});
export const deleteExamFailure = message => ({type: act.DELETE_EXAM_FAILURE, payload: message});
export const deleteExamRequest = id => ({type: act.DELETE_EXAM_REQUEST, payload: id});
export const deleteExamSuccess = id => ({type: act.DELETE_EXAM_SUCCESS, payload: id});
export const deleteTestResponseRequest = id => ({
    type: act.DELETE_TEST_RESPONSE_REQUEST,
    payload: id,
});
export const deleteTestResponseSuccess = id => ({
    type: act.DELETE_TEST_RESPONSE_SUCCESS,
    payload: id,
});

export const dismissToast = index => ({type: act.DISMISS_TOAST, payload: index});

export const fetchAssignmentsFailure = payload => ({type: act.FETCH_ASSIGNMENTS_FAILURE, payload});
export const fetchAssignmentsRequest = () => ({type: act.FETCH_ASSIGNMENTS_REQUEST});
export const fetchAssignmentsSuccess = payload => ({type: act.FETCH_ASSIGNMENTS_SUCCESS, payload});
export const fetchExamFailure = message => ({type: act.FETCH_EXAM_FAILURE, payload: message});
export const fetchExamRequest = () => ({type: act.FETCH_EXAM_REQUEST});
export const fetchExamSuccess = payload => ({type: act.FETCH_EXAM_SUCCESS, payload});

export const fetchReaderExamFailure = message => ({
    type: act.FETCH_READER_EXAM_FAILURE,
    payload: message,
});
export const fetchReaderExamRequest = () => ({type: act.FETCH_READER_EXAM_REQUEST});
export const fetchReaderExamSuccess = payload => ({type: act.FETCH_READER_EXAM_SUCCESS, payload});
export const fetchRegistrantsSuccess = payload => ({type: act.FETCH_REGISTRANTS_SUCCESS, payload});
export const fetchStudentsRequest = () => ({type: act.FETCH_STUDENTS_REQUEST});
export const fetchStudentsSuccess = payload => ({type: act.FETCH_STUDENTS_SUCCESS, payload});
export const fetchUserSuccess = payload => ({type: act.FETCH_USER_SUCCESS, payload: payload});
export const getDistrictsRequest = payload => ({type: act.GET_DISTRICTS_REQUEST, payload});
export const getDistrictsSuccess = payload => ({type: act.GET_DISTRICTS_SUCCESS, payload});

export const getExamPromptSuccess = payload => ({type: act.GET_EXAM_PROMPT_SUCCESS, payload});
export const getMetricsSuccess = payload => ({type: act.GET_METRICS_SUCCESS, payload});
export const getSchoolsSuccess = payload => ({type: act.GET_SCHOOLS_SUCCESS, payload});
export const getRegistrantOptionsByKeyRequest = () => ({
    type: act.GET_REGISTRANT_OPTIONS_BY_KEY_REQUEST,
});
export const getRegistrantOptionsByKeySuccess = payload => ({
    type: act.GET_REGISTRANT_OPTIONS_BY_KEY_SUCCESS,
    payload,
});
export const getScoresForTestResponseRequest = () => ({
    type: act.GET_SCORES_FOR_TEST_RESPONSE_REQUEST,
});
export const getScoresForTestResponseSuccess = payload => ({
    type: act.GET_SCORES_FOR_TEST_RESPONSE_SUCCESS,
    payload,
});
export const getTeachersSuccess = payload => ({type: act.GET_TEACHERS_SUCCESS, payload});
export const getTeachersBySchoolRequest = () => ({type: act.GET_TEACHERS_BY_SCHOOL_REQUEST});
export const getTeachersBySchoolSuccess = payload => ({
    type: act.GET_TEACHERS_BY_SCHOOL_SUCCESS,
    payload,
});
export const getTeachersStudentsSuccess = payload => ({
    type: act.GET_TEACHERS_STUDENTS_SUCCESS,
    payload,
});
export const getTestResponsesForEditingRequest = () => ({
    type: act.GET_TEST_RESPONSES_FOR_EDITING_REQUEST,
});
export const getTestResponsesForEditingSuccess = payload => ({
    type: act.GET_TEST_RESPONSES_FOR_EDITING_SUCCESS,
    payload,
});
export const getTestResponsesForAdminRequest = () => ({
    type: act.GET_TEST_RESPONSES_FOR_ADMIN_REQUEST,
});
export const getTestResponsesForAdminSuccess = payload => ({
    type: act.GET_TEST_RESPONSES_FOR_ADMIN_SUCCESS,
    payload,
});
export const postExportSuccess = payload => ({type: act.POST_EXPORT_SUCCESS, payload});
export const postRegistrationRequest = () => ({type: act.POST_REGISTRATION_REQUEST});
export const postRegistrationSuccess = payload => ({type: act.POST_REGISTRATION_SUCCESS, payload});
export const postRegistrantsRequest = () => ({type: act.POST_REGISTRANTS_REQUEST});
export const postRegistrantsSuccess = payload => ({type: act.POST_REGISTRANTS_SUCCESS, payload});
export const postReportSuccess = payload => ({type: act.POST_REPORT_SUCCESS, payload});
export const postResponseFailure = (index, message) => ({
    type: act.POST_RESPONSE_FAILURE,
    payload: {index, message},
});
export const postResponseLoading = (index, progress) => ({
    type: act.POST_RESPONSE_LOADING,
    payload: {index, progress},
});
export const postResponseRequest = index => ({type: act.POST_RESPONSE_REQUEST, payload: {index}});
export const postResponseSuccess = (index, data) => ({
    type: act.POST_RESPONSE_SUCCESS,
    payload: {index, data},
});
export const postScannedResponseLoading = progress => ({
    type: act.POST_SCANNED_RESPONSE_LOADING,
    payload: {progress},
});

export const postScannedResponseSuccess = (index, data) => ({
    type: act.POST_SCANNED_RESPONSE_SUCCESS,
    payload: {index, data},
});
export const postScannedResponseFailure = () => ({type: act.POST_SCANNED_RESPONSE_FAILURE});

export const postStudentsFailure = payload => ({type: act.POST_STUDENTS_FAILURE, payload});
export const postStudentsRequest = () => ({type: act.POST_STUDENTS_REQUEST});
export const postStudentsSuccess = payload => ({type: act.POST_STUDENTS_SUCCESS, payload});
export const postUserSuccess = payload => ({type: act.POST_USER_SUCCESS, payload});

export const putResponseFailure = payload => ({type: act.PUT_RESPONSE_FAILURE, payload});
export const putResponseRequest = () => ({type: act.PUT_RESPONSE_REQUEST});
export const putResponseSuccess = payload => ({type: act.PUT_RESPONSE_SUCCESS, payload});
export const putStudentsFailure = message => ({type: act.PUT_STUDENTS_FAILURE, payload: message});
export const putStudentsRequest = () => ({type: act.PUT_STUDENTS_REQUEST});
export const putStudentsSuccess = () => ({type: act.PUT_STUDENTS_SUCCESS});

export const readerErrorClear = index => ({type: act.CLEAR_READER_ERROR, payload: index});

export const refreshCheckoutFailure = message => ({
    type: act.REFRESH_CHECKOUT_FAILURE,
    payload: message,
});
export const refreshCheckoutRequest = () => ({type: act.REFRESH_CHECKOUT_REQUEST});
export const refreshCheckoutSuccess = () => ({type: act.REFRESH_CHECKOUT_SUCCESS});

export const resetPasswordSuccess = payload => ({type: act.RESET_PASSWORD_SUCCESS, payload});

export const saveExamFailure = message => ({type: act.SAVE_EXAM_FAILURE, payload: message});
export const saveExamRequest = () => ({type: act.SAVE_EXAM_REQUEST});
export const saveExamSuccess = payload => ({type: act.SAVE_EXAM_SUCCESS, payload});
export const saveTestResponseFailure = message => ({type: act.SAVE_TEST_RESPONSE_FAILURE, message});
export const saveTestResponseRequest = () => ({type: act.SAVE_TEST_RESPONSE_REQUEST});
export const saveTestResponseSuccess = text => ({
    type: act.SAVE_TEST_RESPONSE_SUCCESS,
    payload: {text},
});

export const setExamModal = payload => ({type: act.SET_EXAM_MODAL, payload});
export const setExamSelect = payload => ({type: act.SET_EXAM_SELECT, payload});
export const setExamTab = payload => ({type: act.SET_EXAM_TAB, payload});
export const setExamYear = year => ({type: act.SET_EXAM_YEAR, payload: year});
export const setReaderTest = payload => ({type: act.SET_READER_TEST, payload});
export const setStudentPersist = payload => ({type: act.SET_STUDENT_PERSIST, payload});

export const sortExamTab = (tab, column) => ({type: act.SORT_EXAM_TAB, payload: {tab, column}});

export const updateResponseSuccess = () => ({type: act.UPDATE_RESPONSE_SUCCESS});
