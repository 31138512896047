import * as act from '../actions/actionTypes';

let toastId = 0;
const createToast = options => {
    const toastOptions = {...options, id: toastId++};
    if (!options.type) toastOptions.type = 'Error';
    return toastOptions;
};

const toastReducer = (state = [], action) => {
    switch (action.type) {
        case act.AXIOS_RESPONSE_ERROR:
        case act.SAVE_EXAM_FAILURE:
        case act.PUT_RESPONSE_FAILURE:
        case act.FETCH_ASSIGNMENTS_FAILURE:
            state = [createToast({message: action.payload}), ...state];
            break;
        case act.POST_RESPONSE_FAILURE:
            state = [createToast({message: action.payload.message}), ...state];
            break;
        case act.POST_USER_SUCCESS:
            if (action.payload.isStudent) {
                state = [
                    createToast({
                        message:
                            'Be sure to save copies of your responses for your records. If there are any technical issues with your submission, it is important that you have a copy of your work in case files need to be resubmitted.',
                        type: 'REMINDER',
                        icon: 'warning',
                    }),
                    ...state,
                ];
            }
            break;
        case act.POST_RESPONSE_SUCCESS:
            state = [
                createToast({
                    message: 'Saved ' + action.payload.data.files.length + ' files',
                    type: 'Upload successful!',
                    icon: 'success',
                }),
                ...state,
            ];
            break;
        case act.PUT_RESPONSE_SUCCESS:
            state = [
                createToast({
                    message: 'Your responses were submitted successfully!',
                    type: 'Upload successful!',
                    icon: 'success',
                }),
                ...state,
            ];
            break;
        case act.RESET_PASSWORD_SUCCESS:
            state = [
                createToast({
                    message: 'Password reset submitted!',
                    type: 'Submitted!',
                    icon: 'success',
                }),
                ...state,
            ];
            break;
        case act.ADD_TOAST:
            state = [createToast(action.payload), ...state];
            break;
        case act.DISMISS_TOAST:
            state = state.filter(toast => toast.id !== action.payload);
            break;
        default:
    }
    return state;
};

export default toastReducer;
