import React, {useState, useEffect} from 'react';
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledTooltip,
} from 'reactstrap';
import Spinner from '../../components/waiting';
import {DurationInput, RowInput, CheckInput} from '../../components/input/RowInput';
import {useDispatch} from 'react-redux';
import * as act from '../../store/actions/actionCreators';
import TestBuilder from './OnlineExamForm'; // Import your TestBuilder component

const ExamResource = props => {
    const {idx, resource, resources, onChange} = props;
    const {title, url} = resource;
    const handleDelete = event => {
        onChange(
            'resources',
            resources.filter((element, elementIndex) => elementIndex !== idx)
        );
    };
    const handleChange = event => {
        const {name, value} = event.target;
        onChange(
            'resources',
            resources.map((element, elementIndex) =>
                elementIndex === idx ? {...element, [name]: value} : element
            )
        );
    };
    return (
        <FormGroup row>
            <Label sm={1} for='linkTitle'>
                Title
            </Label>
            <Col sm={4}>
                <Input name='title' id='linkTitle' onChange={handleChange} value={title} />
            </Col>
            <Label sm={1} for='linkUrl'>
                URL
            </Label>
            <Col sm={5}>
                <Input name='url' id='linkUrl' onChange={handleChange} value={url} />
            </Col>
            <Col sm={1}>
                <i
                    className='fa fa-trash icon-danger'
                    tabIndex={idx}
                    onClick={handleDelete}
                    id={`tooltip-delete-${idx}`}
                />
                <UncontrolledTooltip placement='right' target={`tooltip-delete-${idx}`}>
                    Delete Resource
                </UncontrolledTooltip>
            </Col>
        </FormGroup>
    );
};

const ExamModal = props => {
    const {
        isOpen,
        toggle,
        onChange,
        onSave,
        onDelete,
        saving,
        deleting,
        exam_id,
        test_id,
        school_year,
        exam_name,
        discipline,
        release_year,
        owned_by,
        free_response_quantity,
        free_response_time_limit,
        free_response_url,
        multiple_choice_quantity,
        multiple_choice_time_limit,
        multiple_choice_url,
        preset_comments_required,
        resources,
        online_exam_data: initialOnlineExamData, // Receive initial online exam data as a prop
    } = props;
    const dispatch = useDispatch();

    const [isOnlineTestBuilder, setIsOnlineTestBuilder] = useState(false);
    const [online_exam_data, setOnlineExamData] = useState(initialOnlineExamData || []);

    const handleAddResource = () => {
        onChange('resources', [...resources, {title: '', url: ''}]);
    };

    const handleDelete = () => {
        dispatch(act.deleteExamRequest(exam_id));
        onDelete(exam_id);
    };

    const handleCheckboxChange = e => {
        setIsOnlineTestBuilder(e.target.checked);
    };

    const handleSave = () => {
        onSave({
            exam_id,
            school_year,
            exam_name,
            test_id,
            discipline,
            release_year,
            owned_by,
            free_response_quantity,
            free_response_time_limit,
            free_response_url,
            multiple_choice_quantity,
            multiple_choice_time_limit,
            multiple_choice_url,
            preset_comments_required,
            resources,
            online_exam_data, // Include passages in the saved object
        });
    };

    useEffect(() => {
        setOnlineExamData(initialOnlineExamData || []);
    }, [initialOnlineExamData]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} size='lg'>
            <ModalHeader toggle={toggle}>{exam_id < 1 ? 'Add' : 'Edit'} Exam</ModalHeader>
            <ModalBody>
                <RowInput
                    type='number'
                    name='school_year'
                    id='examYear'
                    value={school_year}
                    disabled
                >
                    Year
                </RowInput>
                {exam_id > 0 ? null : (
                    <RowInput name='test_id' id='examTestId' onChange={onChange} value={test_id}>
                        Test ID
                    </RowInput>
                )}
                <RowInput name='exam_name' id='examName' onChange={onChange} value={exam_name}>
                    Name
                </RowInput>
                <RowInput
                    name='discipline'
                    id='examDiscipline'
                    onChange={onChange}
                    value={discipline}
                >
                    Discipline
                </RowInput>
                <RowInput
                    type='number'
                    name='release_year'
                    id='examReleaseYear'
                    onChange={onChange}
                    value={release_year}
                >
                    Release Year
                </RowInput>
                <RowInput name='owned_by' id='examOwnedBy' onChange={onChange} value={owned_by}>
                    Owned By
                </RowInput>
                <RowInput
                    type='number'
                    name='free_response_quantity'
                    id='examNumFRQ'
                    onChange={onChange}
                    value={free_response_quantity}
                >
                    # Free Response
                </RowInput>
                <DurationInput
                    id='examFRQTime'
                    name='free_response_time_limit'
                    value={free_response_time_limit || ''}
                    onChange={onChange}
                >
                    FR Time Limit
                </DurationInput>
                <RowInput
                    name='free_response_url'
                    id='examFRURL'
                    onChange={onChange}
                    value={free_response_url || ''}
                >
                    FR Prompt URL
                </RowInput>
                <RowInput
                    type='number'
                    name='multiple_choice_quantity'
                    id='examNumMC'
                    onChange={onChange}
                    value={multiple_choice_quantity}
                >
                    # MC
                </RowInput>
                <DurationInput
                    id='examMCTime'
                    name='multiple_choice_time_limit'
                    value={multiple_choice_time_limit || ''}
                    onChange={onChange}
                >
                    MC Time Limit
                </DurationInput>
                <RowInput
                    name='multiple_choice_url'
                    id='examMCURL'
                    onChange={onChange}
                    value={multiple_choice_url || ''}
                >
                    MC Prompt URL
                </RowInput>
                <CheckInput
                    id='requirePreset'
                    name='preset_comments_required'
                    value={preset_comments_required}
                    onChange={onChange}
                >
                    Require readers to select Preset Comments
                </CheckInput>
                <FormGroup row>
                    <Label sm={3}>
                        <Input
                            type='checkbox'
                            checked={isOnlineTestBuilder}
                            onChange={handleCheckboxChange}
                        />
                        Online Test Builder
                    </Label>
                </FormGroup>
                {isOnlineTestBuilder && (
                    <TestBuilder passages={online_exam_data} setPassages={setOnlineExamData} />
                )}
                <h5>Exam Resources</h5>
                {!resources
                    ? null
                    : resources.map((resource, idx, resourceArray) => (
                          <ExamResource
                              key={idx}
                              idx={idx}
                              resource={resource}
                              resources={resourceArray}
                              onChange={onChange}
                          />
                      ))}
                <FormGroup row>
                    <Col sm={{size: 1, offset: 11}}>
                        <i
                            className='fa fa-plus icon-primary'
                            onClick={handleAddResource}
                            tabIndex={3}
                            id={`tooltip-add-${exam_id}`}
                        />
                        <UncontrolledTooltip placement='right' target={`tooltip-add-${exam_id}`}>
                            Add Resource
                        </UncontrolledTooltip>
                    </Col>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                {saving ? (
                    <Spinner />
                ) : (
                    <Button color='primary' onClick={handleSave}>
                        Save
                    </Button>
                )}{' '}
                {deleting ? (
                    <Spinner />
                ) : (
                    <Button color='danger' onClick={handleDelete}>
                        Delete
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
};

export default ExamModal;
