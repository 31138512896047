import React from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Card, CardHeader, CardTitle, CardBody} from 'reactstrap';
import ReportsCard from '../reports/CardReports';
import {submitJob} from '../../hooks/useApiJobs';
import AwaitJob from '../../components/AwaitJob';

const Maintenance = ({onReportDone}) => {
    const {jobId, reset, processBubblePages} = submitJob();

    return (
        <div>
            <Row>
                <Col md={6}>
                    <Card>
                        <CardHeader>
                            <CardTitle className='text-white'>Answer Sheets</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ul>
                                <li>
                                    <Link to='/admin/generate-answer-sheets'>
                                        Generate Answer Sheet Packet
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/answer/import_step_1'>Import Scanned Responses</Link>
                                </li>
                                <li>
                                    <Link to='/admin/assign-unreadable-responses'>
                                        Assign Unreadable Responses
                                    </Link>
                                </li>
                                <li>
                                    {jobId ? (
                                        <AwaitJob jobId={jobId} onClose={reset}>
                                            Processing bubble pages
                                        </AwaitJob>
                                    ) : (
                                        <span className='link' onClick={processBubblePages}>
                                            Process Bubble Pages
                                        </span>
                                    )}
                                </li>
                            </ul>
                        </CardBody>
                    </Card>
                    <ReportsCard onReportDone={onReportDone} />
                </Col>
                <Col md={6}>
                    <Card>
                        <CardHeader>
                            <CardTitle className='text-white'>Maintenance</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ul>
                                <li>
                                    <Link to='/admin/teachers'>
                                        District / School / Teacher Maintenance
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/admin/exams'>
                                        Exam / Question / Answer Key Maintenance
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/admin/response-maintenance'>
                                        Response Maintenance
                                    </Link>
                                </li>
                                <li>
                                    <Link to='/admin/test-responses'>Test Response Scores</Link>
                                </li>
                            </ul>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <CardTitle className='text-white'>Orders</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <ul>
                                <li>
                                    <Link to='/admin/registrants'>Edit Registrants</Link>
                                </li>
                                <li>
                                    <Link to='/register/enter?key=scoring-service'>Add Order</Link>
                                </li>
                                <li>
                                    <Link to='/admin/order_status'>Order Status</Link>
                                </li>
                            </ul>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Maintenance;
