import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import useAuth from '../hooks/useAuth';

const Logout = () => {
    const [finished, setFinished] = useState(false);
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);

    useEffect(() => {
        switch (auth.logoutStatus) {
            case 'idle':
                auth.logout();
                break;
            case 'success':
            case 'error':
                setFinished(true);
                break;
            default:
        }
    }, [auth]);

    if (finished) return <Redirect to='/' />;
    return <div className='loginWrapper'>Good bye!</div>;
};

export default Logout;
