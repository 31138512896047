import React, {useEffect, useState} from 'react';
import {Input, InputGroup, InputGroupAddon, InputGroupText, Row} from 'reactstrap';

const CheckboxOrders = props => {
    const [selections, setSelections] = useState(new Set());
    const {orders, onSelection} = props;
    /* if our list updates, make sure nothing is selected that's not on the list any more */
    useEffect(() => {
        if (!selections || !orders) return;
        const newSelections = new Set();
        const excluded = new Set();
        orders.forEach(item => {
            if (selections.has(item.order_id)) newSelections.add(item.order_id);
            else excluded.add(item.order_id);
        });
        if (excluded.size === 0) newSelections.add(-1);
        setSelections(newSelections);
    }, [orders]);
    /* if there are no order values, we have nothing to show */
    if (!orders || orders.length === 0)
        return (
            <Row>
                <p>Select a filter to show order list.</p>
            </Row>
        );

    const handleSelectAll = () => {
        updateSelections(
            selections.has(-1) ? new Set() : new Set(orders.map(item => item.order_id).concat(-1))
        );
    };
    const handleCheck = orderId => {
        const newSelections = new Set(selections);
        if (selections.has(orderId)) newSelections.delete(orderId);
        else newSelections.add(orderId);
        updateSelections(newSelections);
    };
    const updateSelections = values => {
        setSelections(values);
        onSelection(values);
    };

    return (
        <>
            <Row>
                <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                            <Input
                                addon
                                type='checkbox'
                                name='selectAll'
                                checked={selections.has(-1)}
                                onChange={handleSelectAll}
                            />
                        </InputGroupText>
                    </InputGroupAddon>
                    <span className='form-control'>Select All</span>
                </InputGroup>
            </Row>
            {orders.map(order => (
                <Row key={order.order_id}>
                    <InputGroup>
                        <InputGroupAddon addonType='prepend'>
                            <InputGroupText>
                                <Input
                                    addon
                                    type='checkbox'
                                    checked={selections.has(order.order_id)}
                                    onChange={() => handleCheck(order.order_id)}
                                />
                            </InputGroupText>
                        </InputGroupAddon>
                        <span className='form-control h-auto'>{`${order.exam_name} (${order.test_id}) - ${order.school_name} - ${order.teacher_first} ${order.teacher_last} (${order.student_count} students)`}</span>
                    </InputGroup>
                </Row>
            ))}
        </>
    );
};

export default CheckboxOrders;
