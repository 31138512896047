import React, {useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input, Button} from 'reactstrap';
import Loading from '../components/waiting';
import FRPage from './PageFRQ';
import SelectOrder from '../components/input/SelectOrder';
import {EnterInput} from './AssignFields';
import useAuth from '../hooks/useAuth';
import {useRecategorizeData} from '../hooks/useApi';

const AssignRecategorize = () => {
    const [response, setResponse] = React.useState();
    const [images, setImages] = React.useState();
    const [texts, setTexts] = React.useState();
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);
    const {recatData, updateRecat, deleteRecat, skipRecat, updateImage, updateText} =
        useRecategorizeData(dispatch);

    useEffect(() => {
        if (!recatData) return;
        setResponse({
            responseId: recatData.id,
            order: recatData.orderId,
            student: recatData.student,
            question: recatData.questionNumber,
        });
        setTexts(
            recatData.texts
                ? recatData.texts.map((text, idx) => ({
                      ...text,
                      checkDelete: false,
                      checkClear: false,
                  }))
                : []
        );
        setImages(
            recatData.images.map((image, idx) => ({
                ...image,
                checkDelete: false,
                checkClear: false,
            }))
        );
    }, [recatData]);

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not an admin */
    if (auth.isAdmin === false) return <Redirect to='/' />;

    /* no more responses in queue */
    if (recatData && recatData.id === -1) return <Redirect to='/admin' />;

    const textCount = texts ? texts.length : 0;
    const imageCount = images ? images.length : 0;

    const handleEvent = event => {
        const {name, value} = event.target;
        handleChange({section: 'response', name, value});
    };
    const handleChange = payload => {
        const {name, value} = payload;
        switch (payload.section) {
            case 'response':
                setResponse({...response, [name]: value});
                break;
            case 'texts':
                setTexts(
                    texts.map(txt => (txt.id === payload.textId ? {...txt, [name]: value} : txt))
                );
                break;
            case 'images':
                setImages(
                    images.map(img => (img.id === payload.imageId ? {...img, [name]: value} : img))
                );
                break;
            default:
        }
    };
    const handleAssignment = (section, index, id) => {
        switch (section) {
            case 'images': // "Update Page"
                const image = images.find(img => img.id === id);
                updateImage({
                    image_id: image.id,
                    delete: image.checkDelete,
                    clear: image.checkClear,
                    order: image.order,
                    student: image.student,
                    question: image.question,
                    page: image.page,
                });
                break;
            case 'texts': // "Update Text Block"
                const text = texts.find(txt => txt.id === id);
                updateText({
                    text_id: text.id,
                    delete: text.checkDelete,
                    clear: text.checkClear,
                    order: text.order,
                    student: text.student,
                    question: text.question,
                });
                break;
            default:
        }
    };

    return (
        <React.Fragment>
            <Row>
                {response ? (
                    <>
                        <Col sm={4}>
                            <h4>Recategorize Response</h4>
                        </Col>
                        <Col sm={4}>
                            <ul>
                                <li>
                                    {textCount}{' '}
                                    {textCount === 1 ? 'text section is' : 'text sections are'}{' '}
                                    attached.
                                </li>
                                <li>
                                    {imageCount} {imageCount === 1 ? 'image is' : 'images are'}{' '}
                                    attached.
                                </li>
                            </ul>
                        </Col>
                        <Col sm={2}>
                            <Button
                                block
                                color='primary'
                                onClick={() => {
                                    skipRecat({responseId: response.responseId});
                                }}
                            >
                                Skip
                            </Button>
                        </Col>
                    </>
                ) : (
                    <Col sm={10}>
                        <Loading>Loading . . .</Loading>
                    </Col>
                )}
                <Col sm='2'>
                    <Link to='/admin' className='btn btn-primary btn-block'>
                        Home
                    </Link>
                </Col>
            </Row>
            {response ? (
                <Row>
                    <Col>
                        <FRPage
                            index={0}
                            texts={texts}
                            images={images || []}
                            questionNumber={response.question}
                            onChange={handleChange}
                            onSubmit={handleAssignment}
                            deleteOption
                        >
                            <SelectOrder
                                value={response.order}
                                handleSelect={order =>
                                    handleChange({
                                        section: 'response',
                                        name: 'order',
                                        value: order,
                                    })
                                }
                            />
                            <EnterInput
                                label='Student:'
                                name='student'
                                value={response.student}
                                handle={handleEvent}
                            />
                            <EnterInput
                                label='Question:'
                                name='question'
                                value={response.question}
                                handle={handleEvent}
                            />
                            <InputGroup>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText className='primary' tag='label'>
                                        Comment:
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input readOnly value={recatData?.comment} />
                            </InputGroup>
                            <InputGroup>
                                <InputGroupAddon addonType='prepend'>
                                    <Button
                                        color='danger'
                                        className='btn-block'
                                        style={{zIndex: 'auto'}}
                                        onClick={() => {
                                            deleteRecat({responseId: response.responseId});
                                        }}
                                    >
                                        Delete All
                                    </Button>
                                </InputGroupAddon>
                                <Button
                                    color='primary'
                                    className='flex-fill'
                                    onClick={() => {
                                        updateRecat(response);
                                    }}
                                >
                                    Update All
                                </Button>
                            </InputGroup>
                            <hr />
                        </FRPage>
                    </Col>
                </Row>
            ) : null}
        </React.Fragment>
    );
};

export default AssignRecategorize;
