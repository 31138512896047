import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import useAuth from '../../hooks/useAuth';
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem} from 'reactstrap';
import AppliedPracticeLogo from './web-Applied-Practice-logo.png';
import nGaugeLogo from './web-nGauge-logo.png';

const NavbarTop = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);

    let userLink, readerLink, teacherLink, adminLink, showCount;
    if (!!auth) {
        if (auth.isAdmin && (auth.isReader || auth.isTeacher)) {
            adminLink = (
                <NavItem>
                    <Link className='nav-link' to='/admin'>
                        Admin
                    </Link>
                </NavItem>
            );
        }
        if (auth.isReader && (auth.isAdmin || auth.isTeacher)) {
            readerLink = (
                <NavItem>
                    <Link className='nav-link' to='/reader'>
                        Reader
                    </Link>
                </NavItem>
            );
        }
        if (auth.isTeacher && (auth.isAdmin || auth.isReader)) {
            teacherLink = (
                <NavItem>
                    <Link className='nav-link' to='/teacher'>
                        Teacher
                    </Link>
                </NavItem>
            );
        }
    }

    if (auth?.lastName) {
        userLink = (
            <NavItem>
                <Link className='nav-link' to='/logout'>
                    Logout {auth.firstName} {auth.lastName}
                </Link>
            </NavItem>
        );
    } else if (auth?.isStudent) {
        if (!auth.firstName && !auth.lastName) {
            userLink = (
                <NavItem>
                    <Link className='nav-link' to='/logout'>
                        Logout Student &quot;{auth.userId}&quot;
                    </Link>
                </NavItem>
            );
        } else {
            userLink = (
                <NavItem>
                    <Link className='nav-link' to='/logout'>
                        Logout {auth.firstName} {auth.middleInitial} {auth.lastName}
                    </Link>
                </NavItem>
            );
        }
    } else
        userLink = (
            <NavItem>
                <Link className='nav-link' to='/login'>
                    Login
                </Link>
            </NavItem>
        );

    if (auth?.isReader) {
        showCount = <div className='float-score'>Responses Scored: {auth.reader.scoreCount}</div>;
    }

    return (
        <Navbar color='light' light expand='md'>
            <NavbarBrand tag={Link} to='/'>
                <img src={AppliedPracticeLogo} alt='Applied Practice' style={{height: '46px'}} />
                &nbsp;
                <img
                    src={nGaugeLogo}
                    alt='nGauge Mock Exam Scoring Service'
                    style={{height: '40px'}}
                />
            </NavbarBrand>

            <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className='ml-auto' navbar>
                    {readerLink} {teacherLink} {adminLink} {userLink} {showCount}
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default NavbarTop;
