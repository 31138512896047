import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {
    Button,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    TabPane,
    UncontrolledTooltip,
} from 'reactstrap';
import Select from 'react-select';
import * as api from '../../store/actions/fetchActions';
import {RowInput} from '../../components/input/RowInput';
import {sortColumn} from '../columnSort';
import {useSchoolsData, useTeachersData} from '../../hooks/useApi';

const TeachersTab = () => {
    const dispatch = useDispatch();
    const {schoolsData: schools, schoolOptions} = useSchoolsData();
    const {teachersData: teachers} = useTeachersData();
    const initialTeacher = {
        teacher_id: null,
        school_id: 0,
        school_name: '',
        first_name: '',
        last_name: '',
        email: '',
        discipline: '',
        priority: 0,
    };
    const [modalTeacher, setModalTeacher] = useState(initialTeacher);
    const [sort, setSort] = useState({column: 'teacher_id', ascending: true});
    const [sortedTeachers, setSortedTeachers] = useState(teachers);

    useEffect(() => {
        if (teachers) {
            setSortedTeachers(sortColumn(teachers, sort.column, sort.ascending));
        }
    }, [sort, teachers]);

    const onSort = col => {
        setSort({column: col, ascending: sort.column === col ? !sort.ascending : true});
    };
    const openEditTeacher = teacher => {
        setModalTeacher(teacher);
    };
    const handleChange = (name, value) => setModalTeacher({...modalTeacher, [name]: value});
    const handleSubmit = () => {
        if (modalTeacher.teacher_id === 0) {
            dispatch(api.postTeacher(modalTeacher)).then(() => {
                setModalTeacher(initialTeacher);
            });
        } else {
            dispatch(api.putTeacher(modalTeacher)).then(() => {
                setModalTeacher(initialTeacher);
            });
        }
    };

    return (
        <TabPane tabId='teachers'>
            <table className='table table-hover table-condensed'>
                <thead>
                    <tr>
                        <th className='sort' onClick={() => onSort('teacher_id')}>
                            ID
                        </th>
                        <th className='sort' onClick={() => onSort('last_name')}>
                            Last Name
                        </th>
                        <th className='sort' onClick={() => onSort('first_name')}>
                            First Name
                        </th>
                        <th className='sort' onClick={() => onSort('email')}>
                            Email
                        </th>
                        <th className='sort' onClick={() => onSort('school_name')}>
                            School Name
                        </th>
                        <th className='sort' onClick={() => onSort('district_name')}>
                            District Name
                        </th>
                        <th className='sort' onClick={() => onSort('priority')}>
                            Priority
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {sortedTeachers &&
                        sortedTeachers.map((t, index) => (
                            <tr key={index}>
                                <td>{t.teacher_id}</td>
                                <td>{t.last_name}</td>
                                <td>{t.first_name}</td>
                                <td>{t.email}</td>
                                <td>{t.school_name}</td>
                                <td>{t.district_name}</td>
                                <td>{t.priority}</td>
                                <td>
                                    <i
                                        className='fa fa-edit icon-primary'
                                        onClick={() => openEditTeacher(t)}
                                        id={`tooltip-edit-teacher-${t.teacher_id}`}
                                        style={{paddingRight: '6px'}}
                                    />
                                    <UncontrolledTooltip
                                        placement='right'
                                        target={`tooltip-edit-teacher-${t.teacher_id}`}
                                    >
                                        Edit Teacher
                                    </UncontrolledTooltip>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <div className='float-right'>
                <Button
                    color='primary'
                    onClick={() => {
                        setModalTeacher({...initialTeacher, teacher_id: 0});
                    }}
                >
                    Add Teacher
                </Button>
            </div>
            <Modal
                isOpen={modalTeacher.teacher_id !== null}
                toggle={() => setModalTeacher(initialTeacher)}
            >
                <ModalHeader toggle={() => setModalTeacher(initialTeacher)}>
                    {modalTeacher.teacher_id === 0 ? 'Add' : 'Edit'} Teacher
                </ModalHeader>
                <ModalBody>
                    <RowInput
                        name='first_name'
                        onChange={handleChange}
                        value={modalTeacher.first_name}
                    >
                        First Name
                    </RowInput>
                    <RowInput
                        name='last_name'
                        onChange={handleChange}
                        value={modalTeacher.last_name}
                    >
                        Last Name
                    </RowInput>
                    <RowInput name='email' onChange={handleChange} value={modalTeacher.email}>
                        Email
                    </RowInput>
                    <RowInput
                        name='discipline'
                        onChange={handleChange}
                        value={modalTeacher.discipline || ''}
                    >
                        Discipline
                    </RowInput>
                    <RowInput name='priority' onChange={handleChange} value={modalTeacher.priority}>
                        Priority
                    </RowInput>
                    <FormGroup>
                        <Label>School</Label>
                        <Select
                            value={{label: modalTeacher.school_name, value: modalTeacher.school_id}}
                            options={schoolOptions}
                            onChange={opt => {
                                setModalTeacher({
                                    ...modalTeacher,
                                    school_name: opt.label,
                                    school_id: opt.value,
                                });
                            }}
                        />
                    </FormGroup>
                    <Button className='float-right' color='success' onClick={handleSubmit}>
                        Save
                    </Button>
                </ModalBody>
            </Modal>
        </TabPane>
    );
};

export default TeachersTab;
