import {useMutation, useQuery, useQueryClient} from 'react-query';
import {apiCall, note} from './useApi';

export const useJobData = job_id => {
    note('useJobData');
    const {data} = useQuery(['jobs', job_id], () => apiCall('get', '/jobs/' + job_id), {
        /* if job_id is not set to anything yet, we're not ready to query */
        enabled: !!job_id,
        /* refetch every 1.5 seconds, only while job is not complete */
        refetchInterval: job =>
            !job || ['finished', 'failed'].includes(job.status) ? false : 1500,
        refetchIntervalInBackground: true,
        /* keep the results around awhile once complete */
        cacheTime: 15 * 60 * 1000,
        staleTime: Infinity,
    });
    return data;
};

export const submitJob = () => {
    const {data, mutate, reset} = useMutation(({method, url, payload}) =>
        apiCall(method, url, payload)
    );
    return {
        jobId: data,
        reset,
        generateAnswerSheets: payload =>
            mutate({method: 'post', url: '/exam/answer_sheets', payload}),
        sampleAnswerSheets: payload => mutate({method: 'post', url: '/exam/sample/' + payload}),
        generateExport: payload => mutate({method: 'post', url: '/admin/export/' + payload}),
        processBubblePages: () => mutate({method: 'post', url: '/response/bubbles'}),
        generateTeacherReport: payload =>
            mutate({method: 'get', url: `/teacher/report/${payload.reportId}/${payload.testId}`}),
    };
};

export const useWorkersData = enabled => {
    note('useWorkersData');
    const {data} = useQuery(['jobs', 'workers'], () => apiCall('get', '/jobs/workers'), {
        enabled,
    });
    return {workers: data};
};

export const useJobsData = enabled => {
    note('useJobsData');
    const queryClient = useQueryClient();
    const {data} = useQuery(['jobs', 'all'], () => apiCall('get', '/jobs'), {
        enabled,
    });
    const showMutation = useMutation(
        payload => apiCall('get', '/jobs', null, {params: {show_all: payload}}),
        {
            onSuccess: newData => queryClient.setQueryData(['jobs', 'all'], newData),
        }
    );
    const deleteMutation = useMutation(payload => apiCall('delete', '/jobs/' + payload));
    const restartMutation = useMutation(payload => apiCall('patch', '/jobs/' + payload));
    return {
        jobsData: data,
        showAllJobs: payload => showMutation.mutate(payload),
        deleteJob: payload => deleteMutation.mutate(payload),
        restartJob: payload => restartMutation.mutate(payload),
    };
};
