import React from 'react';
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardFooter,
    Table,
    Button,
    UncontrolledTooltip,
} from 'reactstrap';
import {useTeacherFilesData} from '../../hooks/useApi';

const AlertTeacherFiles = () => {
    const {fileData, hasNextPage, fetchNextPage, deleteFile} = useTeacherFilesData();
    const haveFiles = fileData && fileData.length > 0 && fileData[0].count > 0;

    return (
        <Card className='border-success'>
            <CardHeader className='bg-success'>
                <CardTitle>Teacher File Uploads</CardTitle>
            </CardHeader>
            <CardBody>
                {haveFiles && (
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>Filename</th>
                                <th>Teacher</th>
                                <th>Exam</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fileData.map((page, idx) =>
                                page.content.map((item, index) => (
                                    <FileRow
                                        index={`${idx}-${index}`}
                                        {...item}
                                        onDelete={() => deleteFile(item.key)}
                                    />
                                ))
                            )}
                        </tbody>
                    </Table>
                )}
            </CardBody>
            <CardFooter className='text-center'>
                {hasNextPage ? (
                    <Button color='primary' size='sm' onClick={() => fetchNextPage()}>
                        Load More
                    </Button>
                ) : (
                    !haveFiles && <span>No files.</span>
                )}
            </CardFooter>
        </Card>
    );
};

const FileRow = ({index, teacher_id, teacher_name, test_id, filename, download, onDelete}) => (
    <tr>
        <td>
            <a href={download} target='_blank'>
                {filename}
            </a>
        </td>
        <td>{`${teacher_id} ${teacher_name}`}</td>
        <td>{test_id}</td>
        <td>
            <i
                className='fa fa-trash icon-danger'
                tabIndex={1}
                onClick={onDelete}
                id={`tooltip-delete-${index}`}
            />
            <UncontrolledTooltip placement='right' target={`tooltip-delete-${index}`}>
                Delete File
            </UncontrolledTooltip>
        </td>
    </tr>
);

export default AlertTeacherFiles;
