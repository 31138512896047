import * as act from '../actions/actionTypes';

const selectionsReducer = (
    state = {
        districts: [],
        schools: [],
        teachers: [],
    },
    action
) => {
    switch (action.type) {
        case act.GET_DISTRICTS_SUCCESS:
            state = {
                ...state,
                districts: action.payload,
            };
            break;
        case act.GET_SCHOOLS_SUCCESS:
            state = {
                ...state,
                schools: action.payload,
            };
            break;
        case act.GET_TEACHERS_SUCCESS:
            state = {
                ...state,
                teachers: action.payload,
            };
            break;
        default:
    }
    return state;
};

export default selectionsReducer;
