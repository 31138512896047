import React from 'react';
import {Button, Col, InputGroup, Row} from 'reactstrap';
import ResponseText from './ResponseText';
import TextReassignOption from './AssignText';
import ResponseImage from './ResponseImage';
import ReassignOptions from './AssignImage';
import Loading from '../components/waiting';

const FRPage = ({texts, images, index, questionNumber, onChange, onSubmit, children}) => {
    const rows = [];
    let firstRow = true;
    if (texts) {
        texts.forEach(text => {
            rows.push(
                <>
                    <Col sm={5}>
                        <ResponseText {...text} />
                    </Col>
                    <Col sm={7}>
                        {firstRow && children}
                        <TextReassignOption
                            {...text}
                            checkDelete={!!text.checkDelete}
                            checkClear={!!text.checkClear}
                            onChange={onChange}
                            index={index}
                            onSubmit={onSubmit}
                        />
                    </Col>
                </>
            );
            firstRow = false;
        });
    }
    if (images) {
        images.forEach(image => {
            rows.push(
                <>
                    <Col sm='5'>
                        <ResponseImage key={image.id} {...image} />
                    </Col>
                    <Col sm='7'>
                        {firstRow && children}
                        <ReassignOptions
                            id={image.id}
                            order={image.order}
                            student={image.student}
                            question={image.question}
                            page={image.page}
                            checkDelete={!!image.checkDelete}
                            checkClear={!!image.checkClear}
                            onChange={(name, value) =>
                                onChange({
                                    section: 'images',
                                    index: index,
                                    imageId: image.id,
                                    name,
                                    value,
                                })
                            }
                        />
                        {image.error ? <p className='error-msg'>{image.error}</p> : null}
                        {image.updating ? (
                            <Loading>Loading . . .</Loading>
                        ) : (
                            <InputGroup>
                                <Button
                                    color='primary'
                                    className='btn-block'
                                    onClick={() => onSubmit('images', index, image.id)}
                                >
                                    Update Page
                                </Button>
                            </InputGroup>
                        )}
                    </Col>
                </>
            );
            firstRow = false;
        });
    }
    if (firstRow) {
        rows.push(
            <>
                <Col sm={5}>No response text or images for question {questionNumber}.</Col>
                <Col sm={7}>{children}</Col>
            </>
        );
    }
    return (
        <Row>
            <Col sm='12'>
                <hr />
                {rows.map((row, idx) => (
                    <Row key={idx}>{row}</Row>
                ))}
            </Col>
        </Row>
    );
};

export default FRPage;
