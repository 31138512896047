import React from 'react';
import {Button, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';

const EnterName = props => {
    const [firstName, setFirstName] = React.useState(props.firstName || '');
    const [middleInitial, setMiddleInitial] = React.useState(props.middleInitial || '');
    const [lastName, setLastName] = React.useState(props.lastName || '');
    const {studentCode} = props;

    const handleInput = event => {
        const {name, value} = event.target;
        switch (name) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'middleInitial':
                setMiddleInitial(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            default:
                console.error('Invalid input', name, value);
        }
    };

    const handleSubmit = event => {
        event.preventDefault();
        props.onSubmit({studentCode, firstName, middleInitial, lastName});
    };

    return (
        <Form onSubmit={handleSubmit}>
            <h3>Please enter your name:</h3>
            <Row form>
                <Col md={5}>
                    <FormGroup>
                        <Label htmlFor='firstName'>First Name</Label>
                        <Input
                            name='firstName'
                            id='firstName'
                            value={firstName}
                            required
                            onChange={handleInput}
                        />
                    </FormGroup>
                </Col>
                <Col md={1}>
                    <FormGroup>
                        <Label htmlFor='middleInitial'>MI</Label>
                        <Input
                            name='middleInitial'
                            id='middleInitial'
                            maxLength={5}
                            value={middleInitial}
                            onChange={handleInput}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label htmlFor='lastName'>Last Name</Label>
                        <Input
                            name='lastName'
                            id='lastName'
                            value={lastName}
                            required
                            onChange={handleInput}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Button type='submit' color='primary'>
                Continue
            </Button>
        </Form>
    );
};

export default EnterName;
