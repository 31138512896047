import * as Excel from 'exceljs';
import {saveAs} from 'file-saver';

export default function ordersDownload(rows) {
    if (!rows) return;
    const preparedRows = rows.map(row => ({
        date: row.order_date,
        school: row.school_name,
        attn: row.ship_to_attn,
        address1: row.ship_to_address1,
        address2: row.ship_to_address2,
        city: row.ship_to_city,
        state: row.ship_to_state,
        zip: row.ship_to_postal_code,
        teacher: `${row.teacher_last}, ${row.teacher_first} (${row.teacher_id})`,
        email: row.teacher_email,
        exam: `${row.exam_name} - ${row.test_id}`,
        count: row.number_of_students,
        submission_type: row.submission_type,
    }));
    const fileDate = new Date();
    let workbook = new Excel.Workbook();
    workbook.creator = 'Accounting Data Solutions';
    workbook.lastModifiedBy = 'nGauge Mock Exam Scoring Service';
    workbook.created = new Date(2021, 11, 7);
    workbook.modified = fileDate;
    workbook.properties.date1904 = true;
    workbook.views = [{x: 0, y: 0, firstSheet: 0, activeTab: 1, visibility: 'visible'}];
    const sheetName = `Orders as of ${fileDate.toISOString().slice(0, 10)}`;

    let sheet = workbook.addWorksheet(sheetName, {
        views: [{xSplit: 0, ySplit: 1}],
    });
    sheet.autoFilter = 'A1:M1';
    sheet.columns = [
        {header: 'Date', key: 'date', width: 11},
        {header: 'School', key: 'school', width: 35},
        {header: 'Attn', key: 'attn', width: 11},
        {header: 'Address 1', key: 'address1', width: 25},
        {header: 'Address 2', key: 'address2', width: 18},
        {header: 'City', key: 'city', width: 11},
        {header: 'State', key: 'state', width: 6},
        {header: 'Zip', key: 'zip', width: 11},
        {header: 'Teacher', key: 'teacher', width: 18},
        {header: 'Email', key: 'email', width: 30},
        {header: 'Exam', key: 'exam', width: 51},
        {header: '# Students', key: 'count', width: 10},
        {header: 'Administration Method', key: 'submission_type', width: 20},
    ];
    sheet.addRows(preparedRows);

    workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
            new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
            `${sheetName}.xlsx`
        );
    });
}
