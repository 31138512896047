import React, {useState, useEffect, useRef} from 'react';
import Timer from '../components/Timer';
import {useInterval} from './utils';
import 'draft-js/dist/Draft.css';
import './AnswerSheet.css';
import {Button, Col, Row, Label} from 'reactstrap';

const Question = props => {
    return (
        <Row noGutters>
            <Col xs={3}>{props.question}</Col>
            <Col xs={9} style={{display: 'inline-flex', flexWrap: 'wrap'}}>
                {['A', 'B', 'C', 'D', 'E'].map(choice => (
                    <Label check key={choice} style={{width: '20%'}}>
                        <input
                            type='radio'
                            name={props.question}
                            value={choice}
                            checked={props.answers[props.question] === choice}
                            onChange={props.onClick}
                        />
                        <small style={{position: 'absolute', marginLeft: '1px'}}>{choice}</small>
                    </Label>
                ))}
            </Col>
            <br />
        </Row>
    );
};

const Questions = ({multipleChoice, setAnswers, answers}) => {
    let i;
    const groups = [];
    for (i = 0; i < multipleChoice.length; i += 10) {
        groups.push(multipleChoice.slice(i, i + 10));
    }

    const handleSelect = event => {
        const {name, value} = event.target;
        setAnswers({...answers, [name]: value});
    };

    return (
        <React.Fragment>
            {groups.map((g, i) => (
                <Col
                    key={i}
                    className='border border-primary mr-2 mb-4'
                    xs={12}
                    sm={6}
                    lg={4}
                    xl={3}
                    style={{borderRadius: '5px'}}
                >
                    {g.map(q => (
                        <Question key={q} question={q} answers={answers} onClick={handleSelect} />
                    ))}
                </Col>
            ))}
        </React.Fragment>
    );
};

const MultipleChoiceInput = ({
    persist,
    setStudentPersist,
    putStudents,
    submitMCResponses,
    setPage,
    err,
    examMCTime,
    multipleChoice,
}) => {
    const [answers, setAnswers] = useState(persist?.MCAnswers || {});
    const [startTime, setStartTime] = useState(new Date(persist?.MC || 0));
    useEffect(() => {
        if (startTime?.toJSON() === persist?.MC) return;
        if (startTime < 1000000000000) {
            const newStartTime = new Date();
            setStartTime(newStartTime);
            setStudentPersist({MC: newStartTime.toJSON()});
        }
    }, [persist?.MC, startTime]);

    const changed = useRef(false);
    const saveWork = () => {
        if (changed.current) {
            changed.current = false;
            putStudents();
        }
    };
    useEffect(() => {
        changed.current = true;
    }, [persist]);
    useEffect(() => {
        setStudentPersist({MCAnswers: answers});
    }, [answers]);

    useInterval(saveWork, 30000);

    const handleSubmit = () => {
        submitMCResponses(answers);
        setPage('open');
    };

    const onComplete = () => {
        setStudentPersist({MC: 'expired'});
        err('Timer Expired. Submitting.', 'Time is up!', 'warning');
        handleSubmit();
    };

    if (persist?.MC === 'expired') setPage('open');

    return (
        <div>
            <Timer startTime={startTime} timeLimit={examMCTime} onComplete={onComplete} />
            <h3>Multiple Choice</h3>
            <h4>Questions</h4>
            <Row>
                <Questions
                    multipleChoice={multipleChoice}
                    answers={answers}
                    setAnswers={setAnswers}
                />
            </Row>
            <Row>
                <Col align='center'>
                    <Button className='my-3 mx-1 p-2' color='primary' onClick={handleSubmit}>
                        Submit Responses
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default MultipleChoiceInput;
