/* Changes here must also be reflected in backend nGauge: ngauge.api.routers.teacher.report:get_report */
export const reportList = [
    {value: 0, label: 'Teacher Report with AP Score'},
    {value: 1, label: 'Teacher Report'},
    {value: 2, label: 'Student FRQ Report'},
    {value: 10, label: 'Student FRQ Report with Comments'},
    {value: 3, label: 'Student Report with AP Score'},
    {value: 4, label: 'Student Report Card'},
    {value: 5, label: 'Item Analysis Report'},
    {value: 6, label: 'Learning Objectives Report'},
    {value: 7, label: 'Learning Objectives Report (Skill)'},
    {value: 11, label: 'Item Analysis Report - All Teachers'},
];

export const reportMap = reportList.reduce((a, b) => {
    a[b.value] = b.label;
    return a;
}, {});
