import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Select from 'react-select';
import SelectReports from '../../components/input/SelectReports';
import {InlineGroup, InlineInput, InlineCheck} from '../../components/input/InlineInput';
import {Questions, Schools, Exams} from '../../components/input/SelectFields';
import {Col, Card, CardBody, CardFooter, Collapse, Button} from 'reactstrap';
import {
    useSchoolsData,
    useExamsData,
    useQuestionsData,
    useRegistrantsData,
    useAdminTeacherData,
} from '../../hooks/useApi';
import {addToast} from '../../store/actions/actionCreators';
import {useAdminUserData} from '../../hooks/useApiUser';
import SelectUser from './SelectUser';
import UserRole from './UserRole';
import UserTeacherInput from './UserTeacherInput';

/* Admin level options:
   0b00000000   (0) not an admin
   0b00000001   (1) mid admin
   0b00000010   (2) access to Response Maintenance
   0b00000100   (4) access to Test Response reports
   0b00001000   (8) not defined
   0b00010000  (16) not defined
   0b00100000  (32) not defined
   0b01000000  (64) not defined
   0b10000000 (128) super admin */
const midAdmin = 1;
const allowResponseMaintenance = 2;
const allowTestResponseReport = 4;
const superAdmin = 128;

const newUser = {
    user_id: null,
    first_name: '',
    last_name: '',
    email: '',
    is_reader: false,
    is_admin: false,
    admin_level: 0,
    is_teacher: false,
    teacher_id: null,
    questions: [],
    teacher: {reports: []},
    password: '',
};

const Users = ({active}) => {
    const dispatch = useDispatch();
    const {schoolOptions} = useSchoolsData(active);
    const {examOptions} = useExamsData(active);
    const {questionOptions} = useQuestionsData(active);
    const {registrantOptions, registrantKeys} = useRegistrantsData(active);
    const [selectedUser, setSelectedUser] = useState();
    const [newTeacher, setNewTeacher] = useState();
    const {userData, deleteUser, addUser, updateUser, resetPassword} = useAdminUserData(
        dispatch,
        selectedUser?.value
    );
    const {teacherData} = useAdminTeacherData(newTeacher);
    const [registrantDefault, setRegistrantDefault] = useState();
    const [userRecord, setUserRecord] = useState(null);

    const isNew = !userRecord?.user_id;
    const teacherId = userRecord?.teacher_id || '';
    const userQuestions = userRecord?.questions || null;
    const userReports = userRecord?.teacher?.reports || [];

    useEffect(() => {
        // state copy is separate to be edited locally
        setUserRecord(userData);
    }, [userData]);

    useEffect(() => {
        // create new user based on teacher
        if (!teacherData) return;
        const {reports, school_id, ...data} = teacherData;
        setUserRecord({...newUser, ...data, teacher: {reports}});
    }, [teacherData]);

    useEffect(() => {
        if (userRecord && !selectedUser) {
            setUserRecord(null);
        }
    }, [selectedUser, userRecord]);

    const handleSelect = user => {
        // user has selected a user from the dropdown
        // either prep clean record or load record from api
        if (user.value === 'CREATE-NEW') {
            setSelectedUser({value: null, label: 'Create New User'});
            setUserRecord({...newUser});
        } else {
            setSelectedUser(user);
            // make sure we have selection lists when they are needed
            // TODO: add getting examsList and schoolsList for mid-admin
        }
    };
    const handleAdminChange = (name, checked) => {
        let newAdminLevel, selection;
        if (name === 'isSuperAdmin') {
            setUserRecord({...userRecord, admin_level: checked ? superAdmin : midAdmin});
            return;
        }
        switch (name) {
            case 'allowResponseMaintenance':
                selection = allowResponseMaintenance;
                break;
            case 'allowTestResponseReport':
                selection = allowTestResponseReport;
                break;
            default:
        }
        if (checked) {
            // bitwise or to turn the bit on
            newAdminLevel = userRecord.admin_level | selection;
        } else {
            // invert the selection so it's the only bit off
            selection = ~selection;
            // bitwise and to leave all but selected bit alone
            newAdminLevel = userRecord.admin_level & selection;
        }
        setUserRecord({...userRecord, admin_level: newAdminLevel});
    };
    const handleSwitch = (chk, evt, id) => {
        // update value of user record if admin/teacher/reader is toggled
        const adminSwitchedOn = chk === true && id === 'is_admin';
        setUserRecord({
            ...userRecord,
            admin_level: adminSwitchedOn ? midAdmin : userRecord.admin_level,
            [id]: chk,
        });
    };

    const handleChange = (name, value) => {
        setUserRecord({...userRecord, [name]: value});
    };

    const loadRegistrantDefaults = () => {
        if (!registrantDefault) return;
        setUserRecord({
            ...userRecord,
            exams: registrantKeys[registrantDefault.value].exams,
            schools: registrantKeys[registrantDefault.value].schools,
        });
    };

    const handleSubmit = evt => {
        evt.preventDefault();
        if (userRecord?.is_teacher && !userRecord.teacher) {
            dispatch(addToast('Must assign user to existing teacher record.'));
            return;
        }
        const data = {
            ...userRecord,
            questions:
                userRecord?.is_reader && userRecord.questions
                    ? userRecord.questions.map(item => item.value)
                    : null,
            exams:
                userRecord?.is_admin && userRecord.exams
                    ? userRecord.exams.map(item => item.value)
                    : null,
            schools:
                userRecord?.is_admin && userRecord.schools
                    ? userRecord.schools.map(item => item.value)
                    : null,
            teacher: userRecord?.is_teacher
                ? {
                      teacher_id: userRecord.teacher_id,
                      reports: userRecord.teacher.reports,
                  }
                : null,
        };
        if (!userRecord.is_admin) data.admin_level = 0;
        if (userRecord.password) data.password = userRecord.password;
        if (isNew) {
            delete data.user_id;
            addUser(data);
        } else if (data.user_id) updateUser(data);
    };

    const handlePasswordReset = evt => {
        evt.preventDefault();
        resetPassword(userRecord.user_id);
    };

    const handleDelete = evt => {
        evt.preventDefault();
        deleteUser(userRecord.user_id);
        setUserRecord(null);
        setSelectedUser(null);
    };

    return (
        <Card>
            <SelectUser selectedUser={selectedUser} onSelect={handleSelect} active={active} />
            <Collapse isOpen={!!userRecord}>
                <CardBody>
                    <UserTeacherInput enabled={isNew} value={newTeacher} onSubmit={setNewTeacher} />
                    <InlineGroup>
                        <InlineInput
                            title='First Name'
                            name='first_name'
                            value={userRecord?.first_name}
                            onChange={handleChange}
                        />
                        <InlineInput
                            title='Last Name'
                            name='last_name'
                            value={userRecord?.last_name}
                            onChange={handleChange}
                        />
                        <InlineInput
                            title='Email'
                            name='email'
                            type='email'
                            value={userRecord?.email}
                            onChange={handleChange}
                        />
                        <InlineInput
                            id='override-user'
                            title='Password'
                            name='password'
                            type='password'
                            value={userRecord?.password}
                            onChange={handleChange}
                            tip='Leave blank for normal operation, or enter to manually set password for user.'
                        />
                    </InlineGroup>
                    <UserRole
                        name='Reader'
                        id='is_reader'
                        enabled={userRecord?.is_reader}
                        onSwitch={handleSwitch}
                    >
                        <Questions
                            disable={!userRecord?.is_reader}
                            data={userQuestions}
                            population={questionOptions}
                            handle={value => {
                                setUserRecord({
                                    ...userRecord,
                                    questions: value,
                                });
                            }}
                        />
                    </UserRole>
                    <UserRole
                        name='Teacher'
                        id='is_teacher'
                        enabled={userRecord?.is_teacher}
                        onSwitch={handleSwitch}
                    >
                        <InlineGroup>
                            <InlineInput
                                title='Teacher ID'
                                name='teacher_id'
                                value={teacherId}
                                onChange={handleChange}
                            />
                        </InlineGroup>
                        <SelectReports
                            disable={!userRecord?.is_teacher}
                            data={userReports}
                            handle={value => {
                                setUserRecord({
                                    ...userRecord,
                                    teacher: {
                                        ...userRecord.teacher,
                                        reports: value,
                                    },
                                });
                            }}
                        />
                    </UserRole>
                    <UserRole
                        name='Admin'
                        id='is_admin'
                        enabled={userRecord?.is_admin}
                        onSwitch={handleSwitch}
                    >
                        <InlineCheck
                            name='isSuperAdmin'
                            value={!!(userRecord?.admin_level & superAdmin)}
                            onChange={handleAdminChange}
                        >
                            Super Admin
                        </InlineCheck>
                        <Collapse isOpen={!(userRecord?.admin_level & superAdmin)}>
                            <InlineCheck
                                name='allowResponseMaintenance'
                                value={!!(userRecord?.admin_level & allowResponseMaintenance)}
                                onChange={handleAdminChange}
                            >
                                Access Response Maintenance
                            </InlineCheck>
                            <InlineCheck
                                name='allowTestResponseReport'
                                value={!!(userRecord?.admin_level & allowTestResponseReport)}
                                onChange={handleAdminChange}
                            >
                                Access Test Response reports
                            </InlineCheck>
                            <Col xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                        gap: '10px',
                                        justifyContent: 'right',
                                    }}
                                >
                                    Populate Schools and Exams from Registrant
                                    <div style={{width: '300px', display: 'flex'}}>
                                        <Select
                                            styles={{
                                                width: 200,
                                                container: base => ({
                                                    ...base,
                                                    flex: 1,
                                                    width: 200,
                                                }),
                                                menu: provided => ({
                                                    ...provided,
                                                    zIndex: 3,
                                                }),
                                            }}
                                            options={registrantOptions}
                                            value={registrantDefault}
                                            onChange={setRegistrantDefault}
                                            placeholder='Select a registrant ...'
                                        />
                                    </div>
                                    <Button onClick={loadRegistrantDefaults}>Apply</Button>
                                </div>
                            </Col>
                            <Schools
                                data={userRecord?.schools}
                                population={schoolOptions}
                                handle={value => {
                                    setUserRecord({
                                        ...userRecord,
                                        schools: value,
                                    });
                                }}
                            />
                            <Exams
                                data={userRecord?.exams}
                                population={examOptions}
                                handle={value => {
                                    setUserRecord({
                                        ...userRecord,
                                        exams: value,
                                    });
                                }}
                            />
                        </Collapse>
                    </UserRole>
                </CardBody>
                <CardFooter className='d-flex justify-content-between'>
                    <Button color='danger' disabled={isNew} onClick={handleDelete}>
                        Delete User
                    </Button>
                    <Button color='warning' disabled={isNew} onClick={handlePasswordReset}>
                        Reset Password
                    </Button>
                    <Button color='success' onClick={handleSubmit}>
                        {isNew ? 'Create' : 'Update'}
                    </Button>
                </CardFooter>
            </Collapse>
        </Card>
    );
};

export default Users;
