import React, {useState} from 'react';
import * as rs from 'reactstrap';
import Spinner from './waiting';
import {Button, Col, FormGroup, Input, Label} from 'reactstrap';

export const Modal = props => {
    const {isOpen, onClose, small, title, onSave, onDelete, saving, deleting, children} = props;
    return (
        <rs.Modal isOpen={isOpen} toggle={onClose} size={small ? 'sm' : 'lg'}>
            <rs.ModalHeader toggle={onClose}>{title}</rs.ModalHeader>
            <rs.ModalBody>{children}</rs.ModalBody>
            <rs.ModalFooter>
                {onSave ? (
                    saving ? (
                        <Spinner />
                    ) : (
                        <Button color='primary' onClick={onSave}>
                            Save
                        </Button>
                    )
                ) : null}{' '}
                {onDelete ? (
                    deleting ? (
                        <Spinner />
                    ) : (
                        <Button color='danger' onClick={onDelete}>
                            Delete
                        </Button>
                    )
                ) : null}
            </rs.ModalFooter>
        </rs.Modal>
    );
};

export const Field = props => {
    const {name, value, onChange, children, ...rest} = props;
    const fieldId = `field_${name}`;
    return (
        <FormGroup row>
            <Label sm={3} for={fieldId}>
                {children}
            </Label>
            <Col sm={9}>
                <Input id={fieldId} name={name} value={value} onChange={onChange} {...rest} />
            </Col>
        </FormGroup>
    );
};
