const cValue = value => {
    // receive a value, return a value suitable for sorting
    if (value === null || value === undefined) return '';
    if (value.hasOwnProperty('toLowerCase')) return value.toLowerCase();
    return value;
};

export const sortColumn = (population, column, ascending) => {
    const asc = ascending ? 1 : -1;
    const sorting = [...population];
    sorting.sort((a, b) => (cValue(a[column]) > cValue(b[column]) ? asc : -asc));
    return sorting;
};
