import axios from 'axios';
import {useMemo} from 'react';
import Rollbar from 'rollbar';
import {addToast} from '../store/actions/actionCreators';
import {useMutation, useQuery, useInfiniteQuery, useQueryClient} from 'react-query';
import LogRocket from 'logrocket';

export const API_PREFIX =
    (process.env.REACT_APP_API_PREFIX ? process.env.REACT_APP_API_PREFIX : '') + '/api/v1';

export const rollbar = new Rollbar({
    accessToken: '7adc62c93f4e4de085e564998fda2834',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: process.env.NODE_ENV !== 'development',
    payload: {
        environment:
            process.env.NODE_ENV === 'production'
                ? process.env.REACT_APP_GIT_BRANCH === 'prod'
                    ? 'production'
                    : process.env.REACT_APP_GIT_BRANCH
                : process.env.NODE_ENV,
        client: {
            javascript: {
                source_map_enabled: true,
                code_version: process.env.REACT_APP_GIT_SHA,
                guess_uncaught_frames: true,
            },
        },
        server: {branch: process.env.REACT_APP_GIT_BRANCH},
    },
    /* https://docs.rollbar.com/docs/javascript#section-ignoring-specific-exception-messages */
    ignoredMessages: [
        /* this will have been reported better to rollbar from the backend */
        /^Request failed with status code 500$/,
        /* there's a browser extension that caused a lot of superfluous errors */
        /^Extension context invalidated\.$/,
        /* general connectivity issues - not much we can do from our side */
        /^Request aborted$/,
        /^Network Error$/,
        /^Request timed out$/,
        /^timeout of \d*ms exceeded$/,
        /* there's never any useful information with this error */
        /^Script error\.$/,
    ],
    transform: function (obj) {
        obj.sessionURL = LogRocket.sessionURL;
        return obj;
    },
    checkIgnore: function (isUncaught, args, payload) {
        /* payload is the log object, so payload.client is info about the user
           and payload.server.branch = {git branch}, etc */
        const {message, response} = args[0];
        const url = payload?.request?.url;
        if (response?.status >= 400 && response.status < 422) {
            console.warn(`Ignored bad request to ${url}; returned ${response.status} (${message})`);
            return true;
        }
        return false;
    },
});

export const apiCall = (method, path, data, config) => {
    note(`apiCall ${method} ${path}`);
    const axiosCallConfig = {method, url: API_PREFIX + path, data};
    if (config) Object.entries(config).forEach(([key, value]) => (axiosCallConfig[key] = value));
    return axios(axiosCallConfig).then(resp => resp.data);
};

export const note = msg => {
    const timestamp = new Date();
    const ms = timestamp.getMilliseconds();
    const timestring = timestamp.toLocaleTimeString();
    console.log(`[${timestring.slice(0, -3)}.${ms}] ${msg}`);
};

export const submitUpload = () => {
    const {data, mutate, reset} = useMutation(({url, fileData, postData, setProgress}) =>
        apiCall(
            'post',
            url,
            buildFormData(fileData, postData),
            !!setProgress && {
                onUploadProgress: ProgressEvent => {
                    if (setProgress)
                        setProgress((ProgressEvent.loaded / ProgressEvent.total) * 100);
                },
            }
        )
    );
    const buildFormData = (fileData, postData) => {
        const formData = new FormData();
        fileData.forEach(file => formData.append('file', file));
        Object.entries(postData).forEach(([key, value]) => formData.append(key, value));
        return formData;
    };
    return {
        uploaded: data?.files,
        reset,
        uploadStudent: (fileData, postData, setProgress) =>
            mutate({url: '/response/upload', fileData, postData, setProgress}),
        uploadTeacher: (fileData, postData, setProgress) =>
            mutate({url: '/response/upload-teacher', fileData, postData, setProgress}),
    };
};

export const useQuestionsData = enabled => {
    const {data, isLoading} = useQuery(['questions'], () => apiCall('get', '/admin/questions'), {
        enabled,
    });

    return {
        questionsData: data,
        isLoading,
        questionOptions: data
            ? data.map(question => ({
                  label: question.question_name,
                  value: question.question_id,
              }))
            : [],
    };
};

export const useStudentData = (student_id, reviewer_id) => {
    const queryClient = useQueryClient();
    const {data} = useQuery(
        ['studentData', student_id, reviewer_id],
        () => apiCall('get', '/admin/responses/' + student_id + '/' + reviewer_id),
        {enabled: !!student_id}
    );
    const {mutate} = useMutation(({method, url, payload}) => apiCall(method, url, payload), {
        onSuccess: () => queryClient.refetchQueries(['studentData', student_id, reviewer_id]),
    });
    return {
        studentData: data,
        updateImage: payload => mutate({method: 'put', url: '/response/image', payload}),
        updateText: payload => mutate({method: 'put', url: '/response/text', payload}),
        updateName: payload => mutate({method: 'put', url: '/admin/student/name', payload}),
        updateMC: payload => mutate({method: 'post', url: '/admin/student/mc', payload}),
        updateScore: payload => mutate({method: 'put', url: '/response/score', payload}),
        deleteScore: response_id =>
            mutate({method: 'delete', url: `/response/score/${response_id}`}),
    };
};

export const useTeacherStudentData = (student_id, enabled) => {
    const {data, isLoading} = useQuery(
        ['teacherStudentData', student_id],
        () => apiCall('get', '/teacher/student/' + student_id),
        {enabled}
    );
    return {
        studentData: data,
        isLoading,
        responseImageCount: useMemo(() => {
            let count = 0;
            data?.responses?.map(response => {
                count += response.images?.length || 0;
            });
            return count;
        }, [data]),
    };
};

export const useUnassigned = dispatch => {
    const queryClient = useQueryClient();
    const {data} = useQuery(['metrics', 'unassigned'], () =>
        apiCall('get', '/admin/metrics?include=unassigned')
    );
    const {mutate} = useMutation(payload => apiCall('put', '/response/image', payload), {
        onSuccess: (responseData, payload) =>
            queryClient.setQueryData(['metrics', 'unassigned'], data => {
                dispatch(addToast(responseData.status, 'Assigned!', 'success'));
                return data?.unassigned
                    ? {
                          unassigned: {
                              count: data.unassigned.count - 1,
                              pages: data.unassigned.pages.filter(
                                  p => p.image_id !== payload.image_id
                              ),
                          },
                      }
                    : null;
            }),
    });
    return {
        count: data?.unassigned?.count,
        unassigned: data?.unassigned?.pages,
        updateImage: payload => mutate(payload),
    };
};

export const useDistrictsData = () => {
    const {data, isLoading, error} = useQuery(
        ['districts'],
        () => apiCall('get', '/teacher/districts'),
        {cacheTime: 24 * 60 * 60 * 1000, staleTime: Infinity}
    );

    return {
        districts: data,
        isLoading,
    };
};

export const useAdminTeacherData = teacher_id => {
    const {data} = useQuery(
        ['teacher', teacher_id],
        async () => await apiCall('get', '/admin/teacher/' + teacher_id),
        {
            enabled: !!teacher_id,
        }
    );
    return {teacherData: data};
};

export const useSchoolsData = (enabled = true) => {
    const {data, isLoading, error, refetch} = useQuery(
        'schools',
        () => apiCall('get', '/teacher/schools'),
        {enabled}
    );

    return {
        schoolsData: data,
        refetch,
        isLoading,
        schoolOptions: useMemo(
            () =>
                data?.map(school => ({
                    label: school.school_name,
                    value: school.school_id,
                })),
            [data]
        ),
    };
};

export const useTeachersData = () => {
    const {data, isLoading, refetch} = useQuery('teachers', () =>
        apiCall('get', '/teacher/teacher')
    );

    return {
        teachersData: data,
        refetch,
        isLoading,
    };
};

export const useYearsData = () => {
    const {data, isLoading, refetch} = useQuery(
        'years',
        async () => await apiCall('get', '/exam/years'),
        {cacheTime: 24 * 60 * 60 * 1000, staleTime: Infinity}
    );

    return {
        years: data || [],
        isLoading,
        refetch,
    };
};

export const useExamsData = enabled => {
    const {data, isLoading, refetch} = useQuery('exams', () => apiCall('get', '/exam/list'), {
        enabled,
    });

    return {
        examData: data,
        refetch,
        isLoading,
        examOptions: useMemo(
            () =>
                data?.exams?.map(exam => ({
                    label: `${exam.exam_name} - ${exam.test_id}`,
                    value: exam.exam_id,
                })),
            [data]
        ),
    };
};

export const useStatesData = () => {
    const {data, isLoading, refetch} = useQuery(
        'states',
        async () => await apiCall('get', '/teacher/states'),
        {cacheTime: Infinity, staleTime: Infinity}
    );

    return {
        statesData: data,
        refetch,
        isLoading,
        statesOptions: useMemo(() =>
            data?.map(state => ({
                label: state.state_abbreviation,
                value: state.state_id,
            }))
        ),
    };
};

export const useRegistrantsData = enabled => {
    const {status, data, isLoading, refetch} = useQuery(
        'registrants',
        () => apiCall('get', '/customer/registrant'),
        {enabled}
    );

    return {
        status,
        data,
        refetch,
        isLoading,
        registrantKeys: useMemo(
            () =>
                data?.reduce((obj, record) => {
                    obj[record.order_key] = {
                        order_key: record.order_key,
                        ordering_entity: record.ordering_entity,
                        deadline_date: record.deadline_date,
                        schools: record.schools.map(s => ({
                            value: s.school_id,
                            label: s.school_name,
                        })),
                        exams: record.exams.map(e => ({
                            value: e.exam_id,
                            label: `${e.exam_name} - ${e.test_id}`,
                        })),
                    };
                    return obj;
                }, {}),
            [data]
        ),
        registrantOptions: useMemo(
            () =>
                data?.map(order => ({
                    label: order.ordering_entity,
                    value: order.order_key,
                })),
            [data]
        ),
    };
};

export const useRecategorizeData = dispatch => {
    const queryClient = useQueryClient();
    const {data} = useQuery('recategorize', () => apiCall('get', '/response/recategorize'), {
        retry: false,
    });
    const mutation = useMutation(
        ({method, payload}) => apiCall(method, '/response/recategorize', payload),
        {
            onSuccess: () => queryClient.invalidateQueries('recategorize'),
        }
    );
    const reassign = useMutation(({url, payload}) => apiCall('put', url, payload), {
        onSuccess: (responseData, {payload}) => {
            queryClient.setQueryData('recategorize', data => {
                dispatch(addToast(responseData.status, 'Assigned!', 'success'));
                const remove =
                    payload.delete ||
                    payload.order !== data.orderId ||
                    payload.student !== data.student;
                if (payload.image_id) {
                    return {
                        ...data,
                        images: remove
                            ? data.images.filter(img => img.id !== payload.image_id)
                            : data.images.map(img =>
                                  img.id !== payload.image_id
                                      ? img
                                      : {...img, page: payload.page, question: payload.question}
                              ),
                    };
                } else if (payload.text_id) {
                    return {
                        ...data,
                        texts: remove
                            ? data.texts.filter(txt => txt.id !== payload.text_id)
                            : data.texts.map(txt =>
                                  txt.id !== payload.text_id
                                      ? txt
                                      : {...txt, question: payload.question}
                              ),
                    };
                }
                return data;
            });
        },
    });
    return {
        recatData: data,
        updateRecat: payload => mutation.mutate({method: 'put', payload}),
        deleteRecat: payload => mutation.mutate({method: 'delete', payload}),
        skipRecat: payload => mutation.mutate({method: 'patch', payload}),
        updateImage: payload => reassign.mutate({url: '/response/image', payload}),
        updateText: payload => reassign.mutate({url: '/response/text', payload}),
    };
};

export const useTeacherFilesData = () => {
    const queryClient = useQueryClient();
    const {data, fetchNextPage, hasNextPage} = useInfiniteQuery(
        'teacher-files',
        ({pageParam}) =>
            apiCall('get', '/response/teacher-files', null, {
                params: {continuation_token: pageParam},
            }),
        {
            getNextPageParam: lastPage => lastPage.continuation_token || undefined,
            cacheTime: 30 * 60 * 1000,
            staleTime: Infinity,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }
    );
    const {mutate} = useMutation(
        params => apiCall('delete', '/response/teacher-files', null, {params}),
        {
            onSuccess: () => queryClient.invalidateQueries('teacher-files'),
        }
    );
    return {
        fileData: data ? data.pages : [],
        hasNextPage,
        fetchNextPage,
        deleteFile: spaces_key => {
            mutate({spaces_key});
        },
    };
};
