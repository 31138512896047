import {reportList} from '../../teacher/reports';
import {Col, InputGroup, InputGroupAddon, Row} from 'reactstrap';
import Select from 'react-select';
import React from 'react';
import {selectStyles} from './SelectFields';

const Reports = props => {
    const [options, setOptions] = React.useState([]);
    const {adminLevel} = props;
    React.useEffect(() => {
        if (props.adminLevel > 127) {
            setOptions([
                ...reportList,
                {value: 8, label: 'MIE Learning Objectives Report'},
                {value: 9, label: 'MIE Learning Objectives Report (Skill)'},
            ]);
        } else setOptions(reportList);
    }, [adminLevel]);

    const currentValue = options.filter(item => props.data.indexOf(item.value) !== -1);
    const distill = newValue => props.handle(newValue ? newValue.map(item => item.value) : []);
    return (
        <Row>
            <Col xs={12}>
                <InputGroup>
                    <InputGroupAddon addonType='prepend'>Reports</InputGroupAddon>
                    <Select
                        styles={selectStyles}
                        isMulti
                        isDisabled={props.disable}
                        closeMenuOnSelect={false}
                        options={options}
                        value={currentValue}
                        onChange={distill}
                        placeholder='Select reports...'
                    />
                </InputGroup>
            </Col>
        </Row>
    );
};

export default Reports;
