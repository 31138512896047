import React, {useEffect} from 'react';
import {TabContent, TabPane, Nav, NavItem} from 'reactstrap';
import {useLocation, Link, Redirect} from 'react-router-dom';
import classnames from 'classnames';
import Dashboard from './dashboard/Dashboard';
import MidAdminDashboard from './dashboard/MidAdminDashboard';
import Maintenance from './maintenance/Maintenance';
import Jobs from './monitoring/Jobs';
import Users from './users/Users';
import Setup from './setup/Setup';
import {useDispatch, useSelector} from 'react-redux';
import {getMetrics} from '../store/actions/fetchActions';
import {postReportSuccess} from '../store/actions/actionCreators';
import useAuth from '../hooks/useAuth';

const Divert = () => {
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);
    const adminData = useSelector(state => state?.admin);

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not an admin */
    if (auth.isAdmin === false) return <Redirect to='/' />;
    const handleReportDone = () => dispatch(postReportSuccess());
    const handleGetMetrics = payload => dispatch(getMetrics(payload));
    if (auth.adminLevel > 127)
        return (
            <SuperAdminHome
                adminData={adminData}
                getMetrics={handleGetMetrics}
                onReportDone={handleReportDone}
            />
        );
    else
        return (
            <MidAdminDashboard
                {...adminData}
                adminLevel={auth.adminLevel}
                getMetrics={handleGetMetrics}
                onReportDone={handleReportDone}
            />
        );
};

const SuperAdminHome = ({adminData, getMetrics, onReportDone}) => {
    const [tab, setTab] = React.useState('dash');
    const {hash} = useLocation();

    useEffect(() => {
        const subHash = hash.substring(1);
        // make sure it's not an empty string
        if (subHash) {
            setTab(hash.substring(1));
        }
    }, [hash]);

    return (
        <>
            <Nav tabs>
                <NavItem>
                    {/* using `component={NavLink}` results in NavItem passing a `navigate` prop
                     to the <a> tag, which in turn results in an error message in the console.
                     rather than wrap NavLink in another custom component to strip the `navigate`
                     prop, I'm just adding `nav-link` to classnames because that's all we need. */}
                    <Link
                        to='/admin#dash'
                        className={classnames({active: tab === 'dash', 'nav-link': true})}
                    >
                        Dashboard
                    </Link>
                </NavItem>
                <NavItem>
                    <Link
                        to='/admin#maint'
                        className={classnames({active: tab === 'maint', 'nav-link': true})}
                    >
                        Maintenance
                    </Link>
                </NavItem>
                <NavItem>
                    <Link
                        to='/admin#jobs'
                        className={classnames({active: tab === 'jobs', 'nav-link': true})}
                    >
                        Jobs
                    </Link>
                </NavItem>
                <NavItem>
                    <Link
                        to='/admin#users'
                        className={classnames({active: tab === 'users', 'nav-link': true})}
                    >
                        Users
                    </Link>
                </NavItem>
                <NavItem>
                    <Link
                        to='/admin#setup'
                        className={classnames({active: tab === 'setup', 'nav-link': true})}
                    >
                        Setup
                    </Link>
                </NavItem>
            </Nav>

            <TabContent activeTab={tab}>
                <TabPane tabId='dash'>
                    <Dashboard {...adminData} getMetrics={getMetrics} />
                </TabPane>
                <TabPane tabId='maint'>
                    <Maintenance onReportDone={onReportDone} />
                </TabPane>
                <TabPane tabId='jobs'>
                    <Jobs isOpen={tab === 'jobs'} />
                </TabPane>
                <TabPane tabId='users'>
                    <Users active={tab === 'users'} />
                </TabPane>
                <TabPane tabId='setup'>
                    <Setup isOpen={tab === 'setup'} />
                </TabPane>
            </TabContent>
        </>
    );
};

export default Divert;
