import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Form, Input, Button} from 'reactstrap';
import * as api from '../store/actions/fetchActions';
import {Link} from 'react-router-dom';

const PasswordReset = props => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');

    const onChange = event => {
        const {type, value} = event.target;
        if (type === 'email') setEmail(value);
    };

    const onSubmit = e => {
        e.preventDefault();
        dispatch(api.postPasswordReset({email: email}));
    };

    return (
        <div className='loginWrapper'>
            <Form onSubmit={e => onSubmit(e)}>
                Enter the email you use to login with:
                <br />
                <Input
                    className='mb-2'
                    placeholder='Email'
                    type='email'
                    value={email}
                    onChange={onChange}
                    required
                />
                <Button color='primary' block type='submit'>
                    Send Reset Code
                </Button>
                <p className='text-center'>
                    <Link to='/login'>
                        <small>Back to login</small>
                    </Link>
                </p>
            </Form>
        </div>
    );
};

export default PasswordReset;
