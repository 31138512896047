import React, {useState} from 'react';
import {Button, Form, FormGroup, Input, InputGroup, InputGroupAddon} from 'reactstrap';

const UserTeacherInput = ({enabled, onSubmit}) => {
    const [value, setValue] = useState('');
    if (!enabled) return null;
    const handleSubmit = evt => {
        evt.preventDefault();
        onSubmit(value);
    };
    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup style={{maxWidth: '360px'}}>
                <InputGroup>
                    <InputGroupAddon addonType='prepend'>Create from teacher? ID:</InputGroupAddon>
                    <Input value={value} onChange={e => setValue(e.target.value)} />
                    <InputGroupAddon addonType='append'>
                        <Button color='primary'>Fill</Button>
                    </InputGroupAddon>
                </InputGroup>
            </FormGroup>
        </Form>
    );
};

export default UserTeacherInput;
