import React from 'react';
import Countdown from 'react-countdown';
import classnames from 'classnames';
import './Timer.css';

const Timer = props => {
    const [lowTime, setLowTime] = React.useState(false);

    if (!props.timeLimit) return null;
    const endDate = props.startTime.valueOf() + props.timeLimit;

    return (
        <div className={classnames('countdown-timer', {warning: lowTime})}>
            <p>Time Left to Submit Work:</p>
            <Countdown
                date={endDate}
                daysInHours
                onComplete={props.onComplete}
                onTick={delta => {
                    if (delta.total < 300000) setLowTime(true);
                    // 5 minutes in milliseconds
                    else if (lowTime) setLowTime(false);
                }}
            />
        </div>
    );
};

export default Timer;
