import React from 'react';
import {Link, Redirect, useHistory} from 'react-router-dom';
import {Button, Row, Col} from 'reactstrap';
import Loading from '../../components/waiting';
import ResponseImage from '../../response/ResponseImage';
import ResponseText from '../../response/ResponseText';
import {useDispatch} from 'react-redux';
import useAuth from '../../hooks/useAuth';
import {useBlankResponseData} from '../../hooks/useApiMetrics';

const ReviewBlanks = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);
    const {blankResponseData, isLoading, refetch, isUpdating, updateBlank} = useBlankResponseData();

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not an admin */
    if (auth.isAdmin === false) return <Redirect to='/' />;

    const handle = event => {
        updateBlank(
            {type: event.target.name, data: {responseId: blankResponseData.id}},
            {
                onSuccess: () => {
                    if (blankResponseData?.next > 0) refetch();
                    else history.push('/admin');
                },
            }
        );
    };
    const imageCount = blankResponseData?.images?.length || 0;

    return (
        <Row>
            <Col sm={2} />
            <Col sm={8}>
                {isLoading ? (
                    <Loading>Loading next blank response...</Loading>
                ) : (
                    <>
                        {isUpdating ? (
                            <Loading>Updating...</Loading>
                        ) : (
                            <Row>
                                <Col sm={4}>
                                    <Button
                                        block
                                        color='danger'
                                        name='acceptBlank'
                                        onClick={handle}
                                    >
                                        Accept
                                    </Button>
                                </Col>
                                <Col sm={4}>
                                    <Button
                                        block
                                        color='danger'
                                        name='deleteBlank'
                                        onClick={handle}
                                    >
                                        Delete
                                    </Button>
                                </Col>
                                <Col sm={4}>
                                    <Button
                                        block
                                        color='primary'
                                        name='releaseBlank'
                                        onClick={handle}
                                    >
                                        Release
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col>
                                Response{' '}
                                {!blankResponseData?.html ? 'does not include' : 'includes'} text
                                section. {imageCount} {imageCount === 1 ? 'image is' : 'images are'}{' '}
                                attached.
                                <ul>
                                    <li>Student: {blankResponseData.student}</li>
                                    <li>Teacher: {blankResponseData.teacher}</li>
                                    <li>Comment: {blankResponseData.comment}</li>
                                    <li>Response ID: {blankResponseData.id}</li>
                                    <li>Assigned question: {blankResponseData.questionNumber}</li>
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            {blankResponseData?.html ? (
                                <Col sm={6}>
                                    <ResponseText html={blankResponseData.html} />
                                </Col>
                            ) : null}
                            {blankResponseData?.images
                                ? blankResponseData.images.map((img, idx) => (
                                      <Col key={idx} sm={6}>
                                          <ResponseImage {...img} />
                                      </Col>
                                  ))
                                : null}
                        </Row>
                    </>
                )}
            </Col>
            <Col sm={2}>
                <Link to='/admin' className='btn btn-block btn-primary'>
                    Home
                </Link>
            </Col>
        </Row>
    );
};

export default ReviewBlanks;
