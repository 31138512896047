import React from 'react';
import {Button, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row} from 'reactstrap';
import ResponseImage from './ResponseImage';

const NamePage = props => {
    return (
        <Row>
            <Col sm='5'>{props.image ? <ResponseImage {...props.image} /> : null}</Col>
            <Col sm='7'>
                <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                        <InputGroupText className='primary' tag='label'>
                            Last Name:
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        value={props.lastName}
                        onChange={e =>
                            props.handleChange({
                                section: 'namePage',
                                name: 'lastName',
                                value: e.target.value,
                            })
                        }
                    />
                </InputGroup>
                <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                        <InputGroupText className='primary' tag='label'>
                            First Name:
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        value={props.firstName}
                        onChange={e =>
                            props.handleChange({
                                section: 'namePage',
                                name: 'firstName',
                                value: e.target.value,
                            })
                        }
                    />
                </InputGroup>
                <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                        <InputGroupText className='primary' tag='label'>
                            Middle Initial:
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        value={props.middleInitial}
                        onChange={e =>
                            props.handleChange({
                                section: 'namePage',
                                name: 'middleInitial',
                                value: e.target.value,
                            })
                        }
                    />
                </InputGroup>
                <InputGroup>
                    <Button
                        color='primary'
                        className='btn-block'
                        onClick={() => props.handleSubmit('namePage')}
                    >
                        Update Name
                    </Button>
                </InputGroup>
            </Col>
        </Row>
    );
};

export default NamePage;
