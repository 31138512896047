import React from 'react';
import {DeleteInput, EnterInput} from './AssignFields';
import Loading from '../components/waiting';
import {Button, InputGroup} from 'reactstrap';
import SelectOrder from '../components/input/SelectOrder';

const TextReassignOption = ({
    index,
    id,
    order,
    student,
    question,
    onChange,
    onSubmit,
    checkDelete,
    checkClear,
    updating,
}) => {
    const handleChange = event => {
        onChange({
            section: 'texts',
            index: index,
            textId: id,
            name: event.target.name,
            value: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        });
    };
    const handleSelect = opt => {
        onChange({
            section: 'texts',
            index: index,
            textId: id,
            name: 'order',
            value: opt,
        });
    };
    const handleSubmit = () => {
        onSubmit('texts', index, id);
    };
    return (
        <React.Fragment>
            <SelectOrder value={order} handleSelect={handleSelect} />
            <EnterInput label='Student:' name='student' value={student} handle={handleChange} />
            <EnterInput label='Question:' name='question' value={question} handle={handleChange} />
            <DeleteInput
                deleteCheck={!!checkDelete}
                clearCheck={!!checkClear}
                handle={handleChange}
            />
            {updating ? (
                <Loading>Loading . . .</Loading>
            ) : (
                <InputGroup>
                    <Button color='primary' className='btn-block' onClick={handleSubmit}>
                        Update Text Block
                    </Button>
                </InputGroup>
            )}
        </React.Fragment>
    );
};

export default TextReassignOption;
