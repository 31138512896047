import React, {useState, useEffect} from 'react';
import {UncontrolledTooltip} from 'reactstrap';
import Loading from '../../components/waiting';
import {useExamAnswerKeyData} from '../../hooks/useApiExams';
import AnswerModal from './AnswerModal';

const AnswerKeyTab = ({exam_id}) => {
    const [answers, setAnswers] = useState([]);
    const [modalAnswer, setModalAnswer] = useState();
    const [sort, setSort] = useState({column: null, ascending: true});
    const {answerKeyData} = useExamAnswerKeyData(exam_id);

    useEffect(() => {
        setSort({column: 'question_number', ascending: true});
    }, [exam_id]);
    useEffect(() => {
        if (answerKeyData) setAnswers(answerKeyData);
    }, [answerKeyData]);
    useEffect(() => {
        const asc = sort.ascending ? 1 : -1;
        const sorting = [...answers];
        sorting.sort((a, b) => (a[sort.column] > b[sort.column] ? asc : -asc));
        setAnswers(sorting);
    }, [sort]);
    const onSort = col => {
        setSort({column: col, ascending: sort.column === col ? !sort.ascending : true});
    };

    if (!answers) return <Loading>Loading answers . . .</Loading>;
    return (
        <table className='table table-hover table-condensed'>
            <tbody>
                <tr>
                    <th className='sort' onClick={() => onSort('question_number')}>
                        Question Number
                    </th>
                    <th className='sort' onClick={() => onSort('correct_answer')}>
                        Correct Answer
                    </th>
                    <th className='sort' onClick={() => onSort('objective1')}>
                        Objective 1
                    </th>
                    <th className='sort' onClick={() => onSort('objective2')}>
                        Objective 2
                    </th>
                    <th className='sort' onClick={() => onSort('skill')}>
                        Skill
                    </th>
                    <th>Actions</th>
                </tr>
                {answers.map(a => (
                    <tr key={a.multiple_choice_answer_key_id}>
                        <td>{a.question_number}</td>
                        <td>{a.correct_answer}</td>
                        <td>{a.objective1}</td>
                        <td>{a.objective2}</td>
                        <td>{a.skill}</td>
                        <td>
                            <i
                                className='fa fa-edit icon-primary'
                                onClick={() => setModalAnswer(a)}
                                id={`tooltip-edit-answer-${a.multiple_choice_answer_key_id}`}
                            />
                            <UncontrolledTooltip
                                placement='right'
                                target={`tooltip-edit-answer-${a.multiple_choice_answer_key_id}`}
                            >
                                Edit Answer
                            </UncontrolledTooltip>
                        </td>
                    </tr>
                ))}
            </tbody>
            <AnswerModal
                isOpen={!!modalAnswer}
                toggle={() => setModalAnswer(null)}
                exam_id={exam_id}
                {...modalAnswer}
            />
        </table>
    );
};

export default AnswerKeyTab;
