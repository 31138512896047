import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import {postExamCopyYear} from '../../store/actions/fetchActions';
import Spinner from '../../components/waiting';
import {useYearsData} from '../../hooks/useApi';

const CopyYearModal = props => {
    const dispatch = useDispatch();
    const copying = useSelector(state => state.exam.copying);
    const {years} = useYearsData();

    const [copyFromYear, setCopyFromYear] = useState(0);
    const [copyToYear, setCopyToYear] = useState(0);

    useEffect(() => {
        if (years) {
            let thisYear = Math.max(...years);
            if (thisYear > 0 && thisYear < 9999) {
                setCopyFromYear(thisYear);
                setCopyToYear(thisYear);
            }
        }
    }, [years]);
    const {isOpen, toggle} = props;

    const handleSubmit = () => {
        console.log({copyFromYear, copyToYear});
        dispatch(postExamCopyYear({copyFromYear, copyToYear}));
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Copy Year</ModalHeader>
            <ModalBody>
                <FormGroup row>
                    <Label sm={3} for='copyFromYear'>
                        Copy from year
                    </Label>
                    <Col sm={9}>
                        <Input
                            type='select'
                            name='copyFromYear'
                            id='copyFromYear'
                            onChange={evt => setCopyFromYear(evt.target.value)}
                            value={copyFromYear}
                        >
                            {years.map(y => (
                                <option key={y}>{y}</option>
                            ))}
                        </Input>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={3} for='copyToYear'>
                        Copy to year
                    </Label>
                    <Col sm={9}>
                        <Input
                            type='number'
                            name='copyToYear'
                            id='copyToYear'
                            onChange={evt => setCopyToYear(evt.target.value)}
                            value={copyToYear}
                        />
                    </Col>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                {copying ? (
                    <Spinner />
                ) : (
                    <Button color='primary' onClick={handleSubmit}>
                        Execute
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
};

export default CopyYearModal;
