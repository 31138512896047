import React from 'react';
import {Link, Redirect, useParams} from 'react-router-dom';
import {Col, Row} from 'reactstrap';
import ReportsCard from './CardReports';
import {useDispatch} from 'react-redux';
import useAuth from '../../hooks/useAuth';
import {useHistory} from 'react-router';

const ReportPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useAuth(dispatch);

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not an admin */
    if (auth.isAdmin === false) return <Redirect to='/' />;
    const orderId = parseInt(useParams().orderId);

    return (
        <>
            <Row>
                <Col sm={{size: 2, offset: 10}}>
                    <Link to='/admin' className='btn btn-primary btn-block'>
                        Home
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <ReportsCard orderId={orderId} onReportDone={() => history.push('/admin')} />
                </Col>
            </Row>
        </>
    );
};

export default ReportPage;
