import React from 'react';
import {Button, CardBody, CardText, Modal, ModalBody, Progress} from 'reactstrap';
import {useJobData} from '../hooks/useApiJobs';

const AwaitJob = ({jobId, onClose, children}) => {
    const jobData = useJobData(jobId);

    const progress = jobData ? jobData.progress : 0;
    const color =
        progress < 100 ? 'warning' : jobData && jobData.status === 'failed' ? 'danger' : 'success';
    return (
        <CardBody>
            <CardText>
                {children}
                <br />
                <small className='text-muted'>{jobId}</small>
            </CardText>
            <Progress max={100} color={color} value={progress}>
                {progress}%
            </Progress>
            <CardText>Status: {jobData ? jobData.status : 'sending'}</CardText>
            {jobData && jobData.detail ? <CardText>{jobData.detail}</CardText> : null}
            <div style={{display: 'flex'}}>
                <div style={{flex: '1 1 60%'}}>
                    {jobData && jobData.links && jobData.links.length > 0 ? (
                        jobData.links.length === 1 ? (
                            <a className='btn btn-success btn-sm' href={jobData.links[0]}>
                                Download File
                            </a>
                        ) : (
                            <span>
                                Download{' '}
                                {jobData.links.map((link, idx) => (
                                    <span key={idx}>
                                        [<a href={link}>File {idx + 1}</a>]&nbsp;
                                    </span>
                                ))}
                            </span>
                        )
                    ) : null}
                </div>
                <div style={{flex: '1 1 40%', textAlign: 'right'}}>
                    <Button color='primary' size='sm' onClick={onClose}>
                        Done
                    </Button>
                </div>
            </div>
        </CardBody>
    );
};

export const AwaitJobModal = ({jobId, onClose, children}) => (
    <Modal isOpen={!!jobId} toggle={onClose}>
        <ModalBody>
            <AwaitJob jobId={jobId} onClose={onClose}>
                {children}
            </AwaitJob>
        </ModalBody>
    </Modal>
);

export default AwaitJob;
