import React from 'react';
import {DeleteInput, EnterInput} from './AssignFields';
import SelectOrder from '../components/input/SelectOrder';

const ReassignOptions = ({order, student, question, page, checkDelete, checkClear, onChange}) => {
    const handleChange = event => {
        onChange(
            event.target.name,
            event.target.type === 'checkbox' ? event.target.checked : event.target.value
        );
    };
    return (
        <React.Fragment>
            <SelectOrder value={order} handleSelect={opt => onChange('order', opt)} />
            <EnterInput label='Student:' name='student' value={student} handle={handleChange} />
            <EnterInput label='Question:' name='question' value={question} handle={handleChange} />
            <EnterInput label='Page:' name='page' value={page} handle={handleChange} />
            <DeleteInput deleteCheck={checkDelete} clearCheck={checkClear} handle={handleChange} />
        </React.Fragment>
    );
};

export default ReassignOptions;
