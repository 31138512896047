import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Form, FormSpy} from 'react-final-form';
import {Button, Table, Row, Col} from 'reactstrap';
import * as API from '../../store/actions/fetchActions';
import arrayMutators from 'final-form-arrays';

class Wizard extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };
    static Page = ({children}) => children;

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            values: props.initialValues || {},
            school: null,
        };
    }

    onChange = change => {
        const newValue = change.values.school ? change.values.school.value : undefined;
        if (newValue) {
            if (newValue !== this.state.school) {
                this.setState(state => ({
                    school: change.values.school.value,
                }));
                this.props.getTeachersBySchool(newValue);
            }
        }
    };

    next = values => {
        this.setState(state => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values,
        }));
    };

    previous = () =>
        this.setState(state => ({
            page: Math.max(state.page - 1, 0),
        }));

    /**
     * NOTE: Both validate and handleSubmit switching are implemented
     * here because 🏁 Redux Final Form does not accept changes to those
     * functions once the form has been defined.
     */

    validate = values => {
        const activePage = React.Children.toArray(this.props.children)[this.state.page];
        return activePage.props.validate ? activePage.props.validate(values) : {};
    };

    handleSubmit = values => {
        const {children, onSubmit} = this.props;
        const {page} = this.state;
        const isLastPage = page === React.Children.count(children) - 1;
        if (isLastPage) {
            return onSubmit(values);
        } else {
            this.next(values);
        }
    };

    render() {
        const {children} = this.props;
        const {page, values} = this.state;
        const activePage = React.Children.toArray(children)[page];
        const isLastPage = page === React.Children.count(children) - 1;

        return (
            <Form
                initialValues={values}
                mutators={{
                    ...arrayMutators,
                }}
                validate={values => {
                    return this.validate(values);
                }}
                onSubmit={this.handleSubmit}
            >
                {({
                    handleSubmit,
                    submitting,
                    values,
                    form: {
                        mutators: {push, pop},
                    },
                }) => (
                    <form onSubmit={handleSubmit}>
                        {activePage}
                        <div className='buttons' style={{paddingTop: '30px'}}>
                            {isLastPage && (
                                <>
                                    <Row>
                                        <Col xs={2}>
                                            <h6>School</h6>
                                        </Col>
                                        <Col>
                                            <p>{values.school.school_name}</p>
                                            {/*<p>{values.school.attn}</p>*/}
                                            <p>{values.school.address1}</p>
                                            <p>{values.school.address2}</p>
                                            <p>
                                                {values.school.city}, {values.school.state}{' '}
                                                {values.school.postal_code}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Teacher</th>
                                                <th>Email</th>
                                                <th>Exam</th>
                                                <th># Students</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {values.teachers.map((teacher, index) => (
                                                <tr>
                                                    <td>
                                                        {teacher.first_name} {teacher.last_name}
                                                    </td>
                                                    <td>{teacher.email}</td>
                                                    <td>{teacher.exam.label}</td>
                                                    <td>{teacher.exam_count}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </>
                            )}
                            {page > 0 && (
                                <Button
                                    className='btn btn-secondary float-left'
                                    type='button'
                                    onClick={this.previous}
                                >
                                    Previous
                                </Button>
                            )}

                            {!isLastPage && (
                                <Button className='btn btn-primary float-right' type='submit'>
                                    Next
                                </Button>
                            )}
                            {isLastPage && (
                                <Button className='btn btn-primary float-right' type='submit'>
                                    Submit
                                </Button>
                            )}
                        </div>
                        <FormSpy
                            subscription={{values: true}}
                            onChange={change => this.onChange(change)}
                        />
                    </form>
                )}
            </Form>
        );
    }
}

const mapStateToProps = state => {
    return {};
};
const mapActionsToProps = {
    getTeachersBySchool: API.getTeachersBySchool,
};
export default connect(mapStateToProps, mapActionsToProps)(Wizard);
