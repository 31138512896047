import React, {useState, useRef, useEffect} from 'react';
import {Col, Row, Button} from 'reactstrap';
import './PromptPopup.css';

const Popup = props => {
    const windowCheckInterval = useRef(null);
    const windowRef = useRef(null);

    const {section} = props;
    useEffect(() => {
        if (!section) return;
        windowRef.current = window.open(
            '/pdf-popup?prompt=' + section,
            'PromptDocument',
            'width=800px,height=1000px,menubar=no,location=no,resizable=yes,scrollbars=yes,status=no'
        );
        if (windowCheckInterval.current) clearInterval(windowCheckInterval.current);
        windowCheckInterval.current = setInterval(() => {
            if (!windowRef.current || windowRef.current.closed) {
                clearInterval(windowCheckInterval.current);
                windowRef.current = null;
            }
        }, 50);
        return () => {
            clearInterval(windowCheckInterval.current);
            windowRef.current = null;
        };
    }, [section]);
    return <>{props.children}</>;
};

const Splash = props => {
    const [testPop, setTestPop] = useState(null);
    const [promptPop, setPromptPop] = useState(false);

    if (promptPop)
        return <Popup section={props.prompt ? props.section : null} children={props.children} />;

    const TestPopup = () => (
        <Popup section='test'>
            <p>If the popup opened, you are ready to begin!</p>
            <p>
                If the popup window was blocked, please adjust your browser or popup blocker
                settings and try again.
            </p>
            <Button color='primary' onClick={() => setTestPop(null)}>
                Reset
            </Button>
        </Popup>
    );

    const TestSection = () => (
        <Row>
            <Col xs={12} md={8}>
                <p>
                    Please click the Test Popup button to see if your browser will allow this site
                    to open your question prompts.
                </p>
                {testPop}
            </Col>
            <Col md={4} align='center'>
                <Button
                    className='mx-5 p-4'
                    color='primary'
                    onClick={() => setTestPop(<TestPopup />)}
                >
                    Test Popup
                </Button>
            </Col>
        </Row>
    );

    return (
        <div>
            {props.prompt ? <TestSection /> : null}
            <Row>
                <Col align='center'>
                    <p>Click the button below to begin.</p>
                    <Button className='mx-5 p-4' color='primary' onClick={() => setPromptPop(true)}>
                        Start
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default Splash;
