import React, {useState} from 'react';
import {Collapse, Card, CardBody, CardHeader, CardTitle, List} from 'reactstrap';
import FileUpload from '../components/FileUpload';
import {submitUpload} from '../hooks/useApi';
import {useHistory} from 'react-router-dom';

const CardTeacherUpload = ({exam_name, test_id}) => {
    const [show, setShow] = useState(false);
    const {uploaded, reset, uploadTeacher} = submitUpload();

    const handleUpload = (files, index, setProgress) => {
        uploadTeacher(files, {test_id: index}, setProgress);
    };
    const handleDone = () => {
        reset();
        setShow(false);
    };
    return (
        <Card>
            <CardHeader
                onClick={() => {
                    setShow(!show);
                }}
                className='pointer'
            >
                <CardTitle className='text-white'>Upload for {exam_name}</CardTitle>
            </CardHeader>
            <Collapse isOpen={show}>
                <CardBody>
                    <FileUpload
                        index={test_id}
                        onUpload={handleUpload}
                        multiple
                        onDone={handleDone}
                    >
                        Choose file to import
                    </FileUpload>
                    {uploaded && uploaded.length > 0 ? (
                        <>
                            <p>Files received:</p>
                            <List type='unstyled'>
                                {uploaded.map((filename, key) => (
                                    <li key={key}>
                                        <pre>{filename}</pre>
                                    </li>
                                ))}
                            </List>
                        </>
                    ) : null}
                </CardBody>
            </Collapse>
        </Card>
    );
};

const DummyCardTeacherUpload = ({exam_name}) => (
    <Card>
        <CardHeader
            onClick={() => (window.location = 'https://appliedpractice.com/upload/')}
            className='pointer'
        >
            <CardTitle className='text-white'>Upload for {exam_name}</CardTitle>
        </CardHeader>
    </Card>
);

// export default CardTeacherUpload;
export default DummyCardTeacherUpload;
