import {useMutation, useQuery, useQueryClient} from 'react-query';
import {apiCall} from './useApi';

export const useExamQuestionsData = examId => {
    const queryClient = useQueryClient();
    const {data, isLoading} = useQuery(
        ['questions', examId],
        () => apiCall('get', '/exam/question?examId=' + examId),
        {enabled: !!examId}
    );
    const saveMutation = useMutation(payload => apiCall('put', '/exam/question', payload), {
        onSuccess: () => queryClient.refetchQueries(['questions', examId]),
    });
    const deleteMutation = useMutation(
        question_id => apiCall('delete', '/exam/question/' + question_id),
        {onSuccess: () => queryClient.refetchQueries(['questions', examId])}
    );

    return {
        questionsData: data?.questions,
        formatsData: data?.formats,
        isLoading,
        saveQuestion: saveMutation.mutate,
        deleteQuestion: deleteMutation.mutate,
    };
};

export const useExamAnswerKeyData = examId => {
    const queryClient = useQueryClient();
    const {data} = useQuery(
        ['answer-key', examId],
        () => apiCall('get', `/exam/answer-key/${examId}`),
        {enabled: !!examId}
    );
    const saveMutation = useMutation(payload => apiCall('put', '/exam/answer-key', payload), {
        onSuccess: () => queryClient.refetchQueries(['answer-key', examId]),
    });
    return {
        answerKeyData: data,
        isSaving: saveMutation.isLoading,
        saveAnswer: saveMutation.mutate,
    };
};
