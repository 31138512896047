import React, {useState, useEffect} from 'react';
import {NavLink, Input, UncontrolledTooltip} from 'reactstrap';

const CommentTable = ({disabled, comments, isEssay, onChange}) => {
    const [edit, setEdit] = useState(null);
    if (disabled) return null;
    const setComments = newComments => {
        onChange({target: {name: 'comments', value: newComments}});
    };
    const addComment = () => {
        const newComment = {id: comments ? -comments.length - 1 : -1, text: '', essayPart: -1};
        setComments([...comments, newComment]);
        setEdit(newComment.id);
    };
    const editComment = (event, commentId) => {
        setComments(
            comments.map(cmt =>
                cmt.id === commentId ? {...cmt, [event.target.name]: event.target.value} : cmt
            )
        );
    };
    const deleteComment = commentId => {
        setComments(comments.filter(cmt => cmt.id !== commentId));
        setEdit(null);
    };

    const sections = ['Thesis', 'Evidence and Commentary', 'Sophistication'];

    return (
        <table className='table table-hover table-condensed'>
            <thead>
                <tr>
                    {isEssay && <th>Section</th>}
                    <th>Scoring Comment</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {comments.map(comment =>
                    edit === comment.id ? (
                        <tr key={comment.id}>
                            {isEssay && (
                                <td>
                                    <Input
                                        type='select'
                                        name='essayPart'
                                        value={comment.essayPart || -1}
                                        onChange={e => editComment(e, comment.id)}
                                    >
                                        <option value={-1} disabled>
                                            Select One
                                        </option>
                                        <option value={0}>{sections[0]}</option>
                                        <option value={1}>{sections[1]}</option>
                                        <option value={2}>{sections[2]}</option>
                                    </Input>
                                </td>
                            )}
                            <td>
                                <Input
                                    name='text'
                                    value={comment.text}
                                    onChange={e => editComment(e, comment.id)}
                                />
                            </td>
                            <td>
                                <i
                                    className='fa fa-check icon-primary'
                                    onClick={() => setEdit(null)}
                                    id={`edit-comment-${comment.id}`}
                                    style={{paddingRight: '6px'}}
                                />
                                <UncontrolledTooltip
                                    placement='right'
                                    target={`edit-comment-${comment.id}`}
                                >
                                    Edit Comment
                                </UncontrolledTooltip>
                                <i
                                    className='fa fa-trash icon-danger'
                                    onClick={() => deleteComment(comment.id)}
                                    id={`delete-comment-${comment.id}`}
                                />
                                <UncontrolledTooltip
                                    placement='right'
                                    target={`delete-comment-${comment.id}`}
                                >
                                    Delete Comment
                                </UncontrolledTooltip>
                            </td>
                        </tr>
                    ) : (
                        <tr key={comment.id}>
                            {isEssay && <td>{sections[comment.essayPart]}</td>}
                            <td>{comment.text}</td>
                            <td>
                                <NavLink
                                    name='comment'
                                    tabIndex={0}
                                    onClick={() => setEdit(comment.id)}
                                >
                                    <i
                                        className='fa fa-edit icon-primary'
                                        id={`add-comment-${comment.id}`}
                                    />
                                    <UncontrolledTooltip
                                        placement='right'
                                        target={`add-comment-${comment.id}`}
                                    >
                                        Edit Comment
                                    </UncontrolledTooltip>
                                </NavLink>
                            </td>
                        </tr>
                    )
                )}
                <tr>
                    <td colSpan={isEssay ? 2 : 1} />
                    <td>
                        <i
                            className='fa fa-plus icon-primary'
                            id={`add-comment`}
                            onClick={addComment}
                        />
                        <UncontrolledTooltip placement='right' target={`add-comment`}>
                            Add Comment
                        </UncontrolledTooltip>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default CommentTable;
