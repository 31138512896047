import * as act from '../actions/actionTypes';

const registrantReducer = (
    state = {
        hasRegistrants: false,
    },
    action
) => {
    switch (action.type) {
        case act.FETCH_REGISTRANTS_SUCCESS:
            state = {
                ...state,
                registrants: action.payload.reduce((obj, record) => {
                    obj[record.order_key] = {
                        order_key: record.order_key,
                        ordering_entity: record.ordering_entity,
                        deadline_date: record.deadline_date,
                        schools: record.schools.map(s => ({
                            value: s.school_id,
                            label: s.school_name,
                        })),
                        exams: record.exams.map(e => ({
                            value: e.exam_id,
                            label: `${e.exam_name} - ${e.test_id}`,
                        })),
                    };
                    return obj;
                }, {}),
            };
            break;
        case act.POST_REGISTRANTS_REQUEST:
            state = {
                ...state,
                written: false,
            };
            break;
        case act.POST_REGISTRANTS_SUCCESS:
            state = {
                ...state,
                written: true,
                registrants: null,
            };
            break;
        default:
    }
    return state;
};

export default registrantReducer;
