import React from 'react';
import {Row, Col} from 'reactstrap';
import teacherPackets from '../../export/teacherPackets';
import TeacherReportStatus from './CardTeacherReportStatus';
import UnscoredFreeResponse from './CardUnscoredFreeResponse';
import ReaderScoreCount from './CardReaderScoreCount';
import AlertRecategorize from './AlertRecategorize';
import AlertUnassigned from './AlertUnassigned';
import AlertBlank from './AlertBlank';
import AlertUnprinted from './AlertUnprinted';
import AlertNoResponse from './AlertNoResponse';
import AlertTeacherFiles from './AlertTeacherFiles';

const Dashboard = props => {
    const [teacherPacketData, setTeacherPacketData] = React.useState(null);
    const {recategorize, blank, unprinted, no_responses, unassigned, teacher_packets, getMetrics} =
        props;
    React.useEffect(() => {
        getMetrics(
            'recategorize&include=blank&include=unprinted&include=no_responses&include=unassigned'
        );
    }, []);
    // we get teacher packet data here because it's used on two different cards
    React.useEffect(() => {
        if (!teacher_packets) {
            getMetrics('teacher_packets');
            return;
        }
        const orderedTeacherData = [...teacher_packets].sort((a, b) => {
            if (a.state > b.state) return 1;
            if (a.state < b.state) return -1;
            if (a.school > b.school) return 1;
            if (a.school < b.school) return -1;
            if (a.exam > b.exam) return 1;
            if (a.exam < b.exam) return -1;
            if (a.teacher > b.teacher) return 1;
            if (a.teacher < b.teacher) return -1;
            return 0;
        });
        setTeacherPacketData(orderedTeacherData);
    }, [teacher_packets, getMetrics]);

    return (
        <Row>
            <Col>
                <UnscoredFreeResponse
                    unscored_free_response={props.unscored_free_response}
                    getMetrics={props.getMetrics}
                />
                <ReaderScoreCount
                    reader_scores={props.reader_scores}
                    getMetrics={props.getMetrics}
                />
                <TeacherReportStatus
                    teacher_report={teacherPacketData}
                    download={() => teacherPackets(teacherPacketData)}
                />
            </Col>
            <Col>
                <AlertUnassigned count={unassigned ? unassigned.count : null} />
                <AlertRecategorize count={recategorize} />
                <AlertBlank count={blank} />
                <AlertUnprinted count={unprinted} />
                <AlertNoResponse count={no_responses} />
                <AlertTeacherFiles />
            </Col>
        </Row>
    );
};

export default Dashboard;
