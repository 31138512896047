import React, {Component} from 'react';
import {Provider, useDispatch} from 'react-redux';
import {QueryClient, QueryClientProvider, QueryCache, MutationCache} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import App from './App';
import PDFPopup from './components/PDFPopup';
import {Container, Jumbotron} from 'reactstrap';
import {rollbar} from './hooks/useApi';

/* receive react-redux store from index.js and wrap the app in the Provider component */
const Root = ({store}) => (
    <Provider store={store}>
        <QueryRoot>
            <BrowserRouter>
                <Switch>
                    {/* pdf-popup is a special route meant to open with no navbar, footer, etc
                        everything else will open within the App component which includes
                        navbar and footer on every page */}
                    <Route path='/pdf-popup' component={PDFPopup} />
                    <ErrorBoundary>
                        <Route component={App} />
                    </ErrorBoundary>
                </Switch>
            </BrowserRouter>
        </QueryRoot>
    </Provider>
);

/* setup react-query and wrap app in QueryClientProvider component with dev tools
   also defines react-query global error handling, spawning toasts and reporting to rollbar */
const QueryRoot = ({children}) => {
    const dispatch = useDispatch();
    const handleApiError = (error, query) => {
        /* Decide what to do with the error and generate a user message */
        let message = error.message;
        /* if we've aborted a request, don't bother the user with it */
        if (message === 'Request aborted') return;
        /* send to error to Rollbar to potentially notify the devs */
        rollbar.error(error, {...query});
        /* if there's response data, it's probably error detail */
        let payload = error.response?.data;
        if (!!payload) {
            message = !payload.detail
                ? payload
                : Array.isArray(payload.detail)
                  ? (message = JSON.stringify(payload.detail))
                  : payload.detail;
        }
        /* if it's an html doc, make that the page */
        if (message.startsWith('<!DOCTYPE html>')) document.write(message);
        /* no error message toast for 403s */ else if (message === 'Not authenticated') return;
        /* default action: notify user of the error (via toast through redux action) */
        dispatch({type: 'ADD_TOAST', payload: {message}});
    };
    const queryClient = new QueryClient({
        queryCache: new QueryCache({onError: handleApiError}),
        mutationCache: new MutationCache({onError: handleApiError}),
        defaultOptions: {
            queries: {
                cacheTime: 15 * 60 * 1000,
                staleTime: 10 * 60 * 1000,
                refetchOnMount: true,
                refetchOnReconnect: true,
                refetchOnWindowFocus: false,
                notifyOnChangeProps: 'tracked',
                // meta: {dispatch},
            },
            // mutations: {
            //     meta: {dispatch},
            // },
        },
    });
    return (
        <QueryClientProvider client={queryClient}>
            {children}
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

/* prevent blank white pages... show an error message and report to rollbar! */
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true, error};
    }

    componentDidCatch(error, errorInfo) {
        console.log('ERROR', error.message);
        rollbar.error(error, {...errorInfo});
    }

    render() {
        if (this.state.hasError) {
            return (
                <Jumbotron>
                    <Container>
                        <h2>Sorry, something went wrong.</h2>
                        <p className='lead'>
                            We were not able to load this page. This error has been reported to our
                            development team. Please try again in a few minutes.
                        </p>
                        <hr />
                        <details style={{whiteSpace: 'pre-wrap', fontSize: 'small', color: 'gray'}}>
                            <code style={{fontSize: 'unset'}}>
                                {this.state.error?.toString()}
                                {'\n'}
                            </code>
                        </details>
                    </Container>
                </Jumbotron>
            );
        }
        return this.props.children;
    }
}

export default Root;
