import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Col, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip} from 'reactstrap';
import {
    saveTestResponse,
    getTestResponsesForEditing,
    deleteTestResponse,
} from '../../../store/actions/fetchActions';
import TextEditor from '../../../components/input/TextInput';
import {stateToHTML} from 'draft-js-export-html';
import ResponseText from '../../../response/ResponseText';

const EditTestResponsesModal = ({questionNumber, exam, isOpen, toggle}) => {
    const dispatch = useDispatch();
    const [raw, setRaw] = useState();
    const testResponses = useSelector(state => state.admin.editing_test_responses);

    useEffect(() => {
        if (questionNumber !== null && questionNumber !== '') {
            dispatch(getTestResponsesForEditing(questionNumber));
        }
    }, [questionNumber]);

    const save = () => {
        if (!raw) return;
        dispatch(saveTestResponse(questionNumber, stateToHTML(raw.getCurrentContent())));
        dispatch(getTestResponsesForEditing(questionNumber));
        setRaw(null);
    };

    return (
        <Modal size='lg' isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Edit Test Responses for question {questionNumber} on exam {exam}
            </ModalHeader>
            <ModalBody>
                {testResponses.map((response, index) => (
                    <Row
                        key={index}
                        style={{
                            paddingTop: '10px',
                            paddingBottom: '15px',
                            borderBottom: '1px solid #dddddd',
                            margin: '0px',
                        }}
                    >
                        <Col xs={11}>
                            <ResponseText
                                html={response.texts}
                                style={{maxHeight: '100px', overflow: 'auto'}}
                            />
                        </Col>
                        <Col
                            xs={1}
                            style={{maxHeight: '100px', alignSelf: 'center', paddingBottom: '16px'}}
                        >
                            <i
                                id={`tooltip-delete-test-response-${index}`}
                                className='fa fa-trash icon-danger float-right'
                                onClick={() => dispatch(deleteTestResponse(response.responseId))}
                            />
                            <UncontrolledTooltip
                                placement='right'
                                target={`tooltip-delete-test-response-${index}`}
                            >
                                Delete Test Response
                            </UncontrolledTooltip>
                        </Col>
                    </Row>
                ))}
                <div style={{paddingTop: '10px'}}>
                    <TextEditor raw={raw} onChange={setRaw} />
                    <Button color='primary' className='float-right' onClick={save}>
                        Save Test Response
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default EditTestResponsesModal;
