const cachedStateName = 'nGagueMockExamScoringService';
let cacheFailed = false;
let lastCacheTime, cacheTimerId;

const stateVersion = '0.5';

export function loadCachedState() {
    try {
        const serializedState = localStorage.getItem(cachedStateName);
        if (serializedState === null) {
            return undefined;
        }
        const stateValue = JSON.parse(serializedState);
        if (stateValue.version !== stateVersion) {
            localStorage.removeItem(cachedStateName);
            return undefined;
        }
        delete stateValue.version;
        return stateValue;
    } catch (err) {
        return undefined;
    }
}

export function cacheState(store) {
    const time = Date.now();
    const timeSinceLastCache = time - lastCacheTime;

    // we don't want to serialize the store more than once per second
    if (!!lastCacheTime && timeSinceLastCache < 1000) {
        if (cacheTimerId === undefined) {
            // timer to save store after a second so we don't miss anything
            cacheTimerId = setTimeout(() => cacheState(store), 1000);
        }
        return;
    }

    // since we've decided to continue, save the run time and clear any timers that might be running
    lastCacheTime = time;
    if (cacheTimerId !== undefined) {
        clearTimeout(cacheTimerId);
        cacheTimerId = undefined;
    }

    try {
        // serialize the store tree and save it in the user's browser
        const wholeState = store.getState();
        const persistState = {
            version: stateVersion,
            student: wholeState.student,
        };
        const serializedState = JSON.stringify(persistState);
        localStorage.setItem(cachedStateName, serializedState);
    } catch {
        // unless local storage is disabled...
        if (!cacheFailed) {
            console.error('Your privacy settings do not allow saving your data between sessions.');
            cacheFailed = true;
        }
    }
}
