import * as act from '../actions/actionTypes';

const teacherReducer = (state = {teacherId: null, reports: [], dashboard: null}, action) => {
    switch (action.type) {
        case act.FETCH_USER_SUCCESS:
            if (action.payload.isTeacher) {
                state = {
                    ...state,
                    ...action.payload.teacher,
                };
            }
            break;
        case act.GET_TEACHERS_STUDENTS_SUCCESS:
            state = {
                ...state,
                dashboard: action.payload,
            };
            break;
        default:
    }
    return state;
};

export default teacherReducer;
