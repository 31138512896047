import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Container,
    Row,
    Col,
    Collapse,
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from 'reactstrap';
import Loading from '../../components/waiting';
import Expand from '../../components/Expand';
import {Exams, Schools} from '../../components/input/SelectFields';
import {postRegistrants, putRegistrants} from '../../store/actions/fetchActions';
import {useSchoolsData, useExamsData, useRegistrantsData} from '../../hooks/useApi';
import {Link, Redirect} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const Entry = props => {
    const [isOpen, setIsOpen] = useState(false);
    const {examOptions} = useExamsData(isOpen);
    const {schoolOptions} = useSchoolsData(isOpen);
    const {objectKey, data} = props;
    const toggle = () => {
        setIsOpen(!isOpen);
    };
    const inputChange = event => {
        const {name, value} = event.target;

        setIsOpen(true);
        props.handle(objectKey, {
            ...data,
            [name]: value,
        });
    };

    return (
        <Row>
            <Col xs={12}>
                <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                            <Expand isOpen={isOpen} toggle={toggle} />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        name='ordering_entity'
                        value={data.ordering_entity}
                        placeholder='Ordering Entity'
                        onChange={inputChange}
                    />
                    <Input
                        name='order_key'
                        value={data.order_key}
                        placeholder='URL Key'
                        onChange={inputChange}
                    />
                    <Input
                        name='deadline_date'
                        type='date'
                        value={data.deadline_date}
                        onChange={inputChange}
                    />
                    <InputGroupAddon addonType='append'>
                        <Button
                            color={data.hasChanges ? 'primary' : 'light'}
                            disabled={!data.hasChanges}
                            onClick={() => props.handleSave(objectKey)}
                        >
                            {!objectKey ? 'Add' : 'Save'}
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </Col>
            <Collapse isOpen={isOpen} style={{width: '100%'}}>
                <Exams
                    data={data.exams}
                    population={examOptions}
                    handle={exams => props.handle(objectKey, {...data, exams})}
                />
                <Schools
                    data={data.schools}
                    population={schoolOptions}
                    handle={schools => props.handle(objectKey, {...data, schools})}
                />
            </Collapse>
        </Row>
    );
};

const Registrant = () => {
    const dispatch = useDispatch();
    const [addData, setAddData] = useState({ordering_entity: '', order_key: '', deadline_date: ''});
    const [currentRegistrants, setCurrentRegistrants] = useState({});
    const [hasChanges, setHasChanges] = useState(false);
    const written = useSelector(state => state.registrants.written);
    const {registrantKeys: registrants} = useRegistrantsData();
    const auth = useAuth(dispatch);

    useEffect(() => {
        setCurrentRegistrants(registrants);
    }, [registrants]);

    useEffect(() => {
        if (hasChanges && written) {
            setAddData({ordering_entity: '', order_key: '', deadline_date: ''});
            setHasChanges(false);
        }
    }, [written]);

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not an admin */
    if (auth.isAdmin === false) return <Redirect to='/' />;

    const handleNew = (_, data) => {
        setHasChanges(true);
        setAddData({
            ...addData,
            ...data,
            hasChanges: true,
        });
        // console.log('current Data - ');
        // console.dir(data);
        // console.log('current addData - ');
        // console.dir(addData);
    };
    const handleChange = (key, data) => {
        setHasChanges(true);
        setCurrentRegistrants({
            ...currentRegistrants,
            [key]: {
                ...currentRegistrants[key],
                ...data,
                hasChanges: true,
            },
        });
    };
    const submitNew = () => {
        // console.log('addData - ', addData);
        const newdata = addData;
        newdata['entity'] = addData.ordering_entity;
        newdata['key'] = addData.order_key;
        newdata['deadline'] = addData.deadline_date;
        // console.log('newdata - ', newdata);
        dispatch(postRegistrants(newdata));
    };
    const submitSave = key => {
        const item = currentRegistrants[key];
        const payload = {
            order_key: key,
            new_key: item.order_key,
            ordering_entity: item.ordering_entity,
            deadline: item.deadline_date,
            deadline_date: item.deadline_date,
            schools: item.schools.map(s => s.value),
            exams: item.exams.map(e => e.value),
        };
        dispatch(putRegistrants(payload));
    };

    return (
        <Container>
            <Row>
                <Col sm={10}>
                    <h2>Registrants</h2>
                </Col>
                <Col sm={2}>
                    <Link to='/admin' className={`btn btn-${hasChanges ? 'danger' : 'primary'}`}>
                        Close
                    </Link>
                </Col>
            </Row>
            {!currentRegistrants ? (
                <Row>
                    <Col xs={12}>
                        <Loading>Loading registrants . . .</Loading>
                    </Col>
                </Row>
            ) : (
                <React.Fragment>
                    <Entry objectKey='' data={addData} handle={handleNew} handleSave={submitNew} />
                    {Object.keys(currentRegistrants).map(key => (
                        <Entry
                            key={key}
                            objectKey={key}
                            data={currentRegistrants[key]}
                            handle={handleChange}
                            handleSave={submitSave}
                        />
                    ))}
                </React.Fragment>
            )}
        </Container>
    );
};

export default Registrant;
