import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Button, Col, Row, UncontrolledTooltip} from 'reactstrap';
import ordersDownload from '../../export/ordersDownload';
import EditOrderModal from './EditOrderModal';
import {Link, Redirect} from 'react-router-dom';
import {sortColumn} from '../columnSort';
import useAuth from '../../hooks/useAuth';
import {useOrdersData} from '../../hooks/useApiOrders';

const OrderStatus = () => {
    const dispatch = useDispatch();
    const {ordersData, updateOrder, deleteOrder} = useOrdersData(true, 'OrderStatus');
    const initialStateOrder = {
        order_id: null,
        order_date: '',
        school_name: '',
        ship_to_attn: '',
        ship_to_address1: '',
        ship_to_address2: '',
        ship_to_city: '',
        ship_to_state: '',
        ship_to_postal_code: '',
        teacher_first: '',
        teacher_last: '',
        teacher_email: '',
        number_of_students: '',
        submission_type: '',
    };
    const [modalOrder, setModalOrder] = useState(initialStateOrder);
    const [sort, setSort] = useState({column: 'order_id', ascending: true});
    const [sortedOrders, setSortedOrders] = useState(ordersData);

    useEffect(() => {
        if (!ordersData) return;
        setSortedOrders(sortColumn(ordersData, sort.column, sort.ascending));
    }, [sort, ordersData]);

    const auth = useAuth(dispatch);

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not an admin */
    if (auth.isAdmin === false) return <Redirect to='/' />;

    const onSort = col => {
        setSort({column: col, ascending: sort.column === col ? !sort.ascending : true});
    };
    const openEditOrder = order => {
        setModalOrder(order);
    };

    return (
        <>
            <Row>
                <Col>
                    <div className='float-right'>
                        <Button color='primary' onClick={() => ordersDownload(sortedOrders)}>
                            Download
                        </Button>{' '}
                        <Link to='/admin' className='btn btn-primary'>
                            Home
                        </Link>
                    </div>
                    <table className='table table-hover table-condensed'>
                        <thead>
                            <tr>
                                <th className='sort' onClick={() => onSort('order_date')}>
                                    Order Date
                                </th>
                                <th className='sort' onClick={() => onSort('school_name')}>
                                    School
                                </th>
                                <th className='sort' onClick={() => onSort('ship_to_attn')}>
                                    Attn
                                </th>
                                <th className='sort' onClick={() => onSort('ship_to_address1')}>
                                    Address 1
                                </th>
                                <th className='sort' onClick={() => onSort('ship_to_address2')}>
                                    Address 2
                                </th>
                                <th className='sort' onClick={() => onSort('ship_to_city')}>
                                    City
                                </th>
                                <th className='sort' onClick={() => onSort('ship_to_state')}>
                                    State
                                </th>
                                <th className='sort' onClick={() => onSort('ship_to_postal_code')}>
                                    Zip
                                </th>
                                <th className='sort' onClick={() => onSort('teacher_last')}>
                                    Teacher
                                </th>
                                <th className='sort' onClick={() => onSort('teacher_email')}>
                                    Email
                                </th>
                                <th className='sort' onClick={() => onSort('exam_name')}>
                                    Exam
                                </th>
                                <th className='sort' onClick={() => onSort('number_of_students')}>
                                    # Students
                                </th>
                                <th className='sort' onClick={() => onSort('submission_type')}>
                                    Administration Method
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {sortedOrders?.map(order => (
                                <tr key={order.order_id}>
                                    <td>{order.order_date}</td>
                                    <td>{order.school_name}</td>
                                    <td>{order.ship_to_attn}</td>
                                    <td>{order.ship_to_address1}</td>
                                    <td>{order.ship_to_address2}</td>
                                    <td>{order.ship_to_city}</td>
                                    <td>{order.ship_to_state}</td>
                                    <td>{order.ship_to_postal_code}</td>
                                    <td>{order.teacher_first + ' ' + order.teacher_last}</td>
                                    <td>{order.teacher_email}</td>
                                    <td>{order.exam_name}</td>
                                    <td>{order.number_of_students}</td>
                                    <td>{order.submission_type}</td>
                                    <td>
                                        <i
                                            className='fa fa-edit icon-primary'
                                            onClick={() => openEditOrder(order)}
                                            id={`tooltip-edit-order-${order.order_id}`}
                                            style={{paddingRight: '6px'}}
                                        />
                                        <UncontrolledTooltip
                                            placement='right'
                                            target={`tooltip-edit-order-${order.order_id}`}
                                        >
                                            Edit Order
                                        </UncontrolledTooltip>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Col>
            </Row>
            <EditOrderModal
                isOpen={modalOrder.order_id !== null}
                onClose={() => setModalOrder(initialStateOrder)}
                onUpdate={updateOrder}
                onDelete={deleteOrder}
                {...modalOrder}
            />
        </>
    );
};

export default OrderStatus;
