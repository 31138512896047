import React, {useState} from 'react';
import {useEffect} from 'react';
import {Collapse, Card, CardHeader, CardTitle, CardBody, Table} from 'reactstrap';
import StudentRow from './StudentRow';
var $ = require('jquery');
import 'datatables.net';
// import tableSort from "table-sort-js/table-sort.js";

const CardExamStudents = ({exam_name, students}) => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        $('.studentsTable').DataTable();
    });

    return (
        <Card>
            <CardHeader
                onClick={() => {
                    setShow(!show);
                }}
                className='pointer'
            >
                <CardTitle className='text-white'>{exam_name}</CardTitle>
            </CardHeader>
            <Collapse isOpen={show}>
                <CardBody>
                    <Table hover responsive className='studentsTable'>
                        <thead>
                            <tr>
                                <th>Student</th>
                                <th>Login Code</th>
                                <th>Name</th>
                                <th>MC</th>
                                <th>FR</th>
                                <th>Scored</th>
                            </tr>
                        </thead>
                        <tbody>
                            {students.map((student, i) => (
                                <StudentRow key={i} student={student} />
                            ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Collapse>
        </Card>
    );
};

export default CardExamStudents;
