import {Card, CardBody, CardHeader, CardTitle, Collapse} from 'reactstrap';
import classnames from 'classnames';
import Switch from 'react-switch';
import React from 'react';

const UserRole = ({name, id, enabled, onSwitch, children}) => {
    return (
        <Card
            className={classnames({
                'border-secondary': !enabled,
                'bg-secondary': !enabled,
            })}
        >
            <CardHeader className={classnames({'bg-secondary': !enabled})}>
                <CardTitle className='text-white d-flex justify-content-between'>
                    User is {name}
                    <label className='m-0' style={{height: '24px'}}>
                        <Switch
                            id={id}
                            onChange={onSwitch}
                            checked={enabled || false}
                            height={22}
                            offColor='#c00'
                        />
                    </label>
                </CardTitle>
            </CardHeader>
            <Collapse isOpen={enabled}>
                <CardBody>{children}</CardBody>
            </Collapse>
        </Card>
    );
};

export default UserRole;
