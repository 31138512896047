import React from 'react';
import './ResponseImage.scss';
import './ResponseText.css';

const ResponseText = ({html, ...props}) => {
    if (!html) {
        return null;
    }

    return <div {...props} className='responseText' dangerouslySetInnerHTML={{__html: html}} />;
};

export default ResponseText;
