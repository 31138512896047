import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Collapse, Card, CardHeader, CardTitle, CardBody, CardFooter, Button} from 'reactstrap';
import Filters from './ReportFilters';
import CheckboxOrders from '../../components/input/CheckboxOrders';
import SelectReports from '../../components/input/SelectReports';
import AwaitJob from '../../components/AwaitJob';
import {postReport} from '../../store/actions/fetchActions';
import useAuth from '../../hooks/useAuth';
import {useQuery} from 'react-query';
import {apiCall} from '../../hooks/useApi';

export const note = msg => {
    const timestamp = new Date();
    const ms = timestamp.getMilliseconds();
    const timestring = timestamp.toLocaleTimeString();
    console.log(`[${timestring.slice(0, -3)}.${ms}] ${msg}`);
};

const ReportsCard = ({orderId, onReportDone}) => {
    const [show, setShow] = useState(false);
    const [unfiltered, setUnfiltered] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [orderSelections, setOrderSelections] = useState();
    const [reportSelections, setReportSelections] = useState([]);

    const reportJob = useSelector(state => state.admin.reportJob);
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);
    const {data} = useQuery('orders', () => apiCall('get', '/customer/order'));

    useEffect(() => {
        if (!data) return;
        note(`ReportsCard useEffect hook - orderId=${orderId}`);
        /* special case: if we're passed in an orderId, only show that one */
        if (orderId) {
            const base = data.find(item => item.order_id === orderId);
            const value = {
                ...base,
                school: base.school_name,
                teacher: `${base.teacher_first} ${base.teacher_last}`,
                exam: `${base.exam_name} (${base.test_id})`,
            };
            setShow(true);
            setOrderSelections(new Set([orderId]));
            setUnfiltered([value]);
        } else {
            setUnfiltered(
                data.map(item => {
                    return {
                        ...item,
                        school: item.school_name,
                        teacher: `${item.teacher_first} ${item.teacher_last}`,
                        exam: `${item.exam_name} (${item.test_id})`,
                    };
                })
            );
        }
    }, [data, orderId]);

    const handleSubmit = event => {
        event.preventDefault();
        if (!orderSelections) return;
        const payload = {
            orders: Array.from(orderSelections).filter(o => o !== -1),
            reports: reportSelections,
        };
        dispatch(postReport(payload));
    };

    return (
        <Card>
            <CardHeader
                onClick={() => {
                    setShow(!show);
                }}
                className='pointer'
            >
                <CardTitle className='text-white'>Report Menu</CardTitle>
            </CardHeader>
            <Collapse isOpen={show}>
                {reportJob ? (
                    <AwaitJob jobId={reportJob} onClose={onReportDone}>
                        Generating reports
                    </AwaitJob>
                ) : (
                    <>
                        <CardBody>
                            <Filters unfiltered={show ? unfiltered : null} handle={setFiltered} />
                            <CheckboxOrders orders={filtered} onSelection={setOrderSelections} />
                            <SelectReports
                                data={reportSelections}
                                handle={setReportSelections}
                                adminLevel={auth ? auth.adminLevel : 0}
                            />
                        </CardBody>
                        <CardFooter className='text-right'>
                            <Button
                                color='primary'
                                size='sm'
                                onClick={handleSubmit}
                                disabled={!orderSelections}
                            >
                                Generate
                            </Button>
                        </CardFooter>
                    </>
                )}
            </Collapse>
        </Card>
    );
};

export default ReportsCard;
