import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Row, Col, InputGroupAddon, InputGroupText, FormGroup} from 'reactstrap';
import * as api from '../store/actions/fetchActions';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';

const PreRegister = props => {
    const dispatch = useDispatch();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const key = urlParams.get('key');
    const template = useSelector(state => state.register.landingPageTemplate);

    useEffect(() => {
        dispatch(api.getRegistrantOptionsByKey(key));
    }, []);

    return (
        <Row>
            <Col md={{size: 8, offset: 2}} sm={12}>
                <h2>Mock Exam Registration</h2>
                <hr />
                <div dangerouslySetInnerHTML={{__html: template}} />
                <hr />
            </Col>
            <Col md={2} sm={12}>
                <Link to={'/register/enter?key=' + key}>
                    <Button color='primary' block>
                        Begin
                    </Button>
                </Link>
            </Col>
        </Row>
    );
};

export default PreRegister;
