import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import '../student/PromptPopup.css';
import {usePdf} from '@mikecousins/react-pdf';
import queryString from 'query-string';
import {Row, Col, Button} from 'reactstrap';
import './pdf-popup-styles.scss';
import * as api from '../store/actions/fetchActions';

const ShowPDF = props => {
    const [page, setPage] = React.useState(1);
    const [scale, setScale] = React.useState(1);
    const canvasRef = React.useRef(null);

    // When using S3 or Digital Ocean Spaces, the CORS settings must be set to allow HEAD requests,
    // or this will fail.

    // eslint-disable-next-line no-unused-vars
    const {pdfDocument, pdfPage} = usePdf({
        canvasRef,
        file: props.url,
        onDocumentLoadFail: err => console.error(err),
        scale,
        page,
    });

    return (
        <div id='root' style={{marginTop: '40px', textAlign: 'center'}}>
            {Boolean(pdfDocument && pdfDocument.numPages) && (
                <Row className='pdf-controls-container'>
                    <Col sm='6'>
                        <div className='page-controls'>
                            <Button
                                color='primary'
                                disabled={page === 1}
                                onClick={() => setPage(page - 1)}
                            >
                                Previous
                            </Button>
                            <span>
                                page {page} of {pdfDocument.numPages}
                            </span>
                            <Button
                                color='primary'
                                disabled={page === pdfDocument.numPages}
                                onClick={() => setPage(page + 1)}
                            >
                                Next
                            </Button>
                        </div>
                    </Col>
                    <Col sm='6'>
                        <div className='zoom-controls'>
                            <Button
                                color='primary'
                                disabled={scale < 1}
                                onClick={() => setScale(scale - 0.25)}
                            >
                                Zoom Out
                            </Button>
                            <span>{scale * 100}%</span>
                            <Button
                                color='primary'
                                disabled={scale > 9.75}
                                onClick={() => setScale(scale + 0.25)}
                            >
                                Zoom In
                            </Button>
                        </div>
                    </Col>
                </Row>
            )}
            {!pdfDocument && (
                <div className='spinner-border' role='status'>
                    <span className='sr-only'>Loading...</span>
                </div>
            )}
            <div className='pdf-container'>
                <canvas ref={canvasRef} onContextMenu={e => e.preventDefault()} />
            </div>
        </div>
    );
};

const PDFPopup = () => {
    const location = useLocation();
    const parsedQueryString = queryString.parse(location.search);
    const promptUrl = useSelector(state => state.student.promptUrl);
    const dispatch = useDispatch();

    useEffect(() => {
        window.document.title = 'nGauge Question Prompt';
        dispatch(api.getExamPrompt(parsedQueryString.prompt));
    }, []);

    if (!promptUrl) {
        return (
            <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
            </div>
        );
    } else return <ShowPDF url={promptUrl} />;
};

export default PDFPopup;
