import React from 'react';
import {Card, CardHeader, CardTitle, CardBody, CardFooter} from 'reactstrap';
import Loading from '../../components/waiting';

const AlertNoResponse = props => {
    if (props.count === 0) return null;
    if (!props.count) return <Loading>Loading order count w/o responses . . .</Loading>;
    return (
        <Card className='border-warning'>
            <CardHeader className='bg-warning'>
                <CardTitle>Scan Responses</CardTitle>
            </CardHeader>
            <CardBody>{props.count} orders have no responses.</CardBody>
            <CardFooter>
                <a className='btn btn-warning btn-sm' href='/answer/import_step_1'>
                    Import Scans Now
                </a>
            </CardFooter>
        </Card>
    );
};

export default AlertNoResponse;
