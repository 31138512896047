import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button, Alert} from 'reactstrap';
import {Link, Redirect} from 'react-router-dom';
import {RowInput} from '../components/input/RowInput';
import useAuth from '../hooks/useAuth';

const ChangePassword = () => {
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);
    const [done, setDone] = useState(false);
    const [formValues, setFormValues] = useState({
        password: '',
        new_password: '',
        new_password_copy: '',
    });
    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    const onChange = (name, value) => {
        setFormValues({...formValues, [name]: value});
    };
    return (
        <div className='loginWrapper'>
            {done ? (
                <Alert color='success'>
                    Your password was successfully updated!{' '}
                    <Link to='/'> Go back to the home screen</Link>
                </Alert>
            ) : (
                <>
                    <h5>Change your password</h5>
                    <RowInput
                        id='oldPassword'
                        name='password'
                        placeholder='Old password...'
                        type='password'
                        value={formValues.password}
                        onChange={onChange}
                        required
                    >
                        Old Password:
                    </RowInput>
                    <RowInput
                        id='newPassword'
                        name='new_password'
                        placeholder='New Password...'
                        type='password'
                        value={formValues.new_password}
                        onChange={onChange}
                        required
                    >
                        Enter a new password:
                    </RowInput>
                    <RowInput
                        id='copyPassword'
                        name='new_password_copy'
                        placeholder='Repeat New Password...'
                        type='password'
                        value={formValues.new_password_copy}
                        onChange={onChange}
                        required
                    >
                        Re-enter your new password:
                    </RowInput>
                    <Button
                        color='primary'
                        block
                        onClick={() =>
                            auth.changePassword(formValues, {onSuccess: () => setDone(true)})
                        }
                    >
                        Set New Password
                    </Button>
                </>
            )}
        </div>
    );
};

export default ChangePassword;
