import * as Excel from 'exceljs';
import {saveAs} from 'file-saver';

export default function unscoredDetail(rows) {
    if (!rows) return;
    const fileDate = new Date();
    let workbook = new Excel.Workbook();
    workbook.creator = 'Accounting Data Solutions';
    workbook.lastModifiedBy = 'nGauge Mock Exam Scoring Service';
    workbook.created = new Date(2021, 2, 6);
    workbook.modified = fileDate;
    workbook.properties.date1904 = true;
    workbook.views = [{x: 0, y: 0, firstSheet: 0, activeTab: 1, visibility: 'visible'}];

    let sheet = workbook.addWorksheet('Unscored Essays', {
        views: [{xSplit: 0, ySplit: 1}],
    });
    sheet.autoFilter = 'A1:D1';
    sheet.columns = [
        {header: 'Exam Name', key: 'exam', width: 50},
        {header: 'Question Num', key: 'question', width: 13},
        {header: 'Teacher', key: 'teacher', width: 20},
        {header: 'Student', key: 'student', width: 20},
    ];

    sheet.addRows(rows);
    const fileDateString = fileDate.toLocaleDateString();

    workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
            new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
            'Unscored essays as of ' + fileDateString + '.xlsx'
        );
    });
}
