import React from 'react';
import {Col, Row, Button} from 'reactstrap';
import ResponseInput from './ResponseInput';
import ResponseUpload from './ResponseUpload';
import Splash from './SplashStart';

const ChooseMethod = ({
    page,
    examFRURL,
    setPage,
    questions,
    persist,
    examFRTime,
    setStudentPersist,
    putStudents,
    submitFRResponses,
    err,
}) => {
    if (page === 'text')
        return (
            <Splash prompt={examFRURL} section='frq'>
                <ResponseInput
                    questions={questions}
                    examFRTime={examFRTime}
                    persist={persist}
                    setStudentPersist={setStudentPersist}
                    putStudents={putStudents}
                    setPage={setPage}
                    err={err}
                    submitFRResponses={submitFRResponses}
                />
            </Splash>
        );
    else if (page === 'image')
        return (
            <Splash prompt={examFRURL} section='frq'>
                <ResponseUpload
                    questions={questions}
                    persist={persist}
                    examFRTime={examFRTime}
                    setPage={setPage}
                    setStudentPersist={setStudentPersist}
                    putStudents={putStudents}
                    submitFRResponses={submitFRResponses}
                />
            </Splash>
        );

    return (
        <div>
            <Row>
                <Col>
                    <p>
                        Provide your answers to each question either by typing them in the text box
                        or uploading image(s) of your completed answer sheets. When finished, click
                        the "Submit Responses" button at the bottom of the page.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col align='center'>
                    <Button className='mx-5 p-4' color='primary' onClick={() => setPage('text')}>
                        ENTER TEXT DIRECTLY
                    </Button>{' '}
                    <Button className='mx-5 p-4' color='primary' onClick={() => setPage('image')}>
                        UPLOAD IMAGE FILES
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default ChooseMethod;
