import React from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import useAuth from '../../hooks/useAuth';
import './Footer.css';

const Footer = () => {
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);
    const isUser = auth ? auth.isAdmin || auth.isReader || auth.isTeacher : false;

    return (
        <div className='footer'>
            <div className='footer-content'>
                <span>Copyright © 2024 · Applied Practice</span>
            </div>
            <div className='waves-container'>
                <svg className='wave wave-yellow' viewBox='0 0 1440 320'>
                    <path
                        fill='#ffc609'
                        d='M0,288L80,272C160,256,320,224,480,197.3C640,171,800,149,960,138.7C1120,128,1280,128,1360,128L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z'
                    ></path>
                </svg>
                <svg className='wave wave-blue' viewBox='0 0 1440 320'>
                    <path
                        fill='#1f6bb6'
                        d='M0,200L60,184C120,168,240,136,360,130.7C480,125,600,147,720,146.7C840,147,960,125,1080,120C1200,115,1320,125,1380,130.7L1440,136L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z'
                    ></path>
                </svg>
            </div>
            <br />
            <br />
            {isUser ? (
                <>
                    <Link to='/change-password'>
                        <small>Change Password</small>
                        <br />
                    </Link>
                    <br />
                </>
            ) : null}
        </div>
    );
};

export default Footer;
