import React from 'react';
import {
    Collapse,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardFooter,
    Table,
    Button,
} from 'reactstrap';
import {LoadingRow} from '../../components/waiting';
import {submitJob} from '../../hooks/useApiJobs';
import AwaitJob from '../../components/AwaitJob';

const ReaderScoreCount = props => {
    const [show, setShow] = React.useState(false);
    const [scores, setScores] = React.useState(<LoadingRow>Loading . . .</LoadingRow>);

    const {reader_scores, getMetrics} = props;
    React.useEffect(() => {
        if (!reader_scores) {
            if (show) getMetrics('reader_scores');
            return;
        }
        setScores(
            reader_scores.map((item, idx) => (
                <tr key={idx}>
                    <td>{`${item.reader_last}, ${item.reader_first}`}</td>
                    <td className='text-right'>{item.count}</td>
                </tr>
            ))
        );
    }, [show, reader_scores, getMetrics]);

    return (
        <Card>
            <CardHeader
                onClick={() => {
                    setShow(!show);
                }}
                className='pointer'
            >
                <CardTitle className='text-white'>Scored Essays by Reader</CardTitle>
            </CardHeader>
            <Collapse isOpen={show}>
                <CardBody>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>Reader</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>{scores}</tbody>
                    </Table>
                </CardBody>
                <ExportFooter summary={reader_scores} />
            </Collapse>
        </Card>
    );
};

const ExportFooter = props => {
    const {jobId, reset, generateExport} = submitJob();
    const {summary} = props;
    if (jobId)
        return (
            <AwaitJob jobId={jobId} onClose={reset}>
                Generating export file
            </AwaitJob>
        );

    return (
        <CardFooter className='text-right'>
            <Button
                className='btn-primary'
                size='sm'
                disabled={!summary}
                onClick={() => generateExport(1)}
            >
                Export Detail
            </Button>
        </CardFooter>
    );
};

export default ReaderScoreCount;
