import React, {useEffect, useState} from 'react';
import {convertToRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import Timer from '../components/Timer';
import {useInterval} from './utils';
import 'draft-js/dist/Draft.css';
import './AnswerSheet.css';
import {Button, Col, Container, Row} from 'reactstrap';
import TextEditor from '../components/input/TextInput';

const PartInput = ({parts, persist, localPersist, rKey}) => {
    return (
        <div>
            {!!parts ? (
                parts.map((part, idx) => (
                    <div key={idx}>
                        <h5>{part.heading}</h5>
                        <TextEditor
                            value={persist ? persist[part.rKey] : null}
                            onChange={value => (localPersist.current[part.rKey] = value)}
                        />
                    </div>
                ))
            ) : (
                <TextEditor
                    value={persist ? persist[rKey] : null}
                    onChange={value => (localPersist.current[rKey] = value)}
                />
            )}
        </div>
    );
};

const ResponseInput = ({
    questions,
    examFRTime,
    persist,
    setStudentPersist,
    putStudents,
    setPage,
    err,
    submitFRResponses,
}) => {
    const [startTime, setStartTime] = useState(new Date(persist?.FR || 0));
    useEffect(() => {
        if (startTime?.toJSON() === persist?.FR) return;
        if (startTime < 1000000000000) {
            const newStartTime = new Date();
            setStartTime(newStartTime);
            setStudentPersist({FR: newStartTime.toJSON()});
        }
    }, [persist?.FR, startTime]);

    const localPersist = React.useRef({});

    // this timer updates persist data in redux state, then uploads it with the following useEffect
    useInterval(
        () =>
            setStudentPersist(
                Object.entries(localPersist.current).reduce((acc, [key, value]) => {
                    acc[key] = convertToRaw(value.getCurrentContent());
                    return acc;
                }, {})
            ),
        20000
    );
    useEffect(putStudents, [persist]);

    // this gets the html version of all inputs to submit
    const toHtml = () =>
        Object.entries(localPersist.current).reduce((acc, [key, value]) => {
            acc[key] = stateToHTML(value.getCurrentContent());
            return acc;
        }, {});
    const handleSubmit = () => {
        submitFRResponses(toHtml());
        setPage('open');
    };

    const onComplete = () => {
        setStudentPersist({FR: 'expired'});
        err('Timer Expired. Submitting.', 'Time is up!', 'warning');
        handleSubmit();
    };

    if (persist && persist.FR === 'expired') setPage('open');

    return (
        <div>
            <Timer startTime={startTime} timeLimit={examFRTime} onComplete={onComplete} />
            <Row>
                <Col align='center'>
                    <Button className='my-3 mx-1 p-2' color='primary' onClick={handleSubmit}>
                        Submit Responses
                    </Button>
                </Col>
            </Row>
            {questions.map((q, idx) => (
                <Container className='border border-primary' key={idx}>
                    <h3>{q.heading}</h3>
                    <PartInput
                        parts={q.parts}
                        rKey={q.rKey}
                        persist={persist}
                        localPersist={localPersist}
                    />
                </Container>
            ))}
            <Row>
                <Col align='center'>
                    <Button className='my-3 mx-1 p-2' color='primary' onClick={handleSubmit}>
                        Submit Responses
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default ResponseInput;
