import React, {useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {RowInput} from '../../components/input/RowInput';
import Spinner from '../../components/waiting';
import {useExamAnswerKeyData} from '../../hooks/useApiExams';

const firstDefined = (obj1, obj2, key) => {
    const value1 = obj1?.[key];
    if (value1 === undefined || value1 === null) return obj2?.[key] || '';
    return obj1[key];
};
const AnswerModal = ({
    isOpen,
    toggle,
    exam_id,
    multiple_choice_answer_key_id,
    question_number,
    ...init
}) => {
    const [editAnswer, setEditAnswer] = useState();
    const {saveAnswer, isSaving} = useExamAnswerKeyData(exam_id);
    const newAnswer = {
        correct_answer: firstDefined(editAnswer, init, 'correct_answer'),
        objective1: firstDefined(editAnswer, init, 'objective1'),
        objective2: firstDefined(editAnswer, init, 'objective2'),
        skill: firstDefined(editAnswer, init, 'skill'),
    };
    const handleChange = (name, value) => setEditAnswer({...editAnswer, [name]: value});
    const handleSave = () => {
        saveAnswer(
            {multiple_choice_answer_key_id, question_number, ...newAnswer},
            {onSuccess: toggle}
        );
    };
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Edit Answer Key for Q{question_number}</ModalHeader>
            <ModalBody>
                <RowInput
                    name='correct_answer'
                    id='answerCorrect'
                    onChange={handleChange}
                    value={newAnswer.correct_answer}
                >
                    Correct Answer
                </RowInput>
                <RowInput
                    name='objective1'
                    id='answerObjective1'
                    onChange={handleChange}
                    value={newAnswer.objective1}
                >
                    Objective 1
                </RowInput>
                <RowInput
                    name='objective2'
                    id='answerObjective2'
                    onChange={handleChange}
                    value={newAnswer.objective2}
                >
                    Objective 2
                </RowInput>
                <RowInput
                    name='skill'
                    id='answerSkill'
                    onChange={handleChange}
                    value={newAnswer.skill}
                >
                    Skill
                </RowInput>
            </ModalBody>
            <ModalFooter>
                {isSaving ? (
                    <Spinner />
                ) : (
                    <Button color='primary' onClick={handleSave}>
                        Save
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
};

export default AnswerModal;
