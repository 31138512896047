import * as act from '../actions/actionTypes';

const readerReducer = (
    state = {
        loading: false,
        exhausted: false,
    },
    action
) => {
    switch (action.type) {
        case act.FETCH_READER_EXAM_REQUEST:
            state = {
                ...state,
                loading: true,
                exhausted: false,
            };
            break;
        case act.FETCH_READER_EXAM_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loading: false,
            };
            break;
        case act.FETCH_READER_EXAM_FAILURE:
            state = {
                ...state,
                loading: false,
                error: !state.error ? [action.payload] : [...state.error, action.payload],
            };
            break;
        case act.DELETE_CHECKOUT_SUCCESS:
            state = {
                ...state,
                response: null,
                questionId: null,
            };
            break;
        case act.DELETE_CHECKOUT_FAILURE:
            state = {
                ...state,
                response: null,
                questionId: null,
                error: !state.error ? [action.payload] : [...state.error, action.payload],
            };
            break;
        case act.REFRESH_CHECKOUT_REQUEST:
            state = {
                ...state,
                refreshingCheckout: true,
            };
            break;
        case act.REFRESH_CHECKOUT_SUCCESS:
            state = {
                ...state,
                refreshingCheckout: false,
            };
            break;
        case act.REFRESH_CHECKOUT_FAILURE:
            state = {
                ...state,
                refreshingCheckout: false,
                response: null,
                questionId: null,
                error: !state.error ? [action.payload] : [...state.error, action.payload],
            };
            break;
        case act.CLEAR_READER_ERROR:
            state = {
                ...state,
                error: !state.error
                    ? null
                    : state.error.filter((val, idx) => idx !== action.payload),
            };
            break;
        case act.SET_READER_TEST:
            state = {
                ...state,
                response: {
                    responseId: 130215,
                    questionNumber: 1,
                    questionDdl: [
                        {
                            id: 663,
                            name: '1',
                        },
                    ],
                    rubric: [
                        {
                            part: 'Thesis',
                            range: 1,
                            start: 1,
                            end: 4,
                        },
                        {
                            part: 'Evidence and Commentary',
                            range: 4,
                            start: 1,
                            end: 4,
                        },
                        {
                            part: 'Sophistication',
                            range: 1,
                            start: 1,
                            end: 4,
                        },
                    ],
                    comments: [
                        {
                            part: 'Thesis',
                            comments: [],
                        },
                        {
                            part: 'Evidence and Commentary',
                            comments: [],
                        },
                        {
                            part: 'Sophistication',
                            comments: [],
                        },
                    ],
                    html: '<h2>Response</h2><p>This is a sample response.</p>',
                    images: [],
                },
                questionId: 0,
            };
            break;
        default:
    }
    return state;
};

export default readerReducer;
