import React, {useEffect, useState} from 'react';
import Loading from '../../components/waiting';
import FilterItem from '../../components/input/FilterItem';

const Filters = ({unfiltered, handle}) => {
    /* Report filters are interlinked; for example if you select a school, only teachers
       from that school will be available in the teacher dropdown. Then if you also select
       an exam, then select 'All' for school, the teacher dropdown will include teachers
       from all schools that have been signed up for that exam. */
    /* These four are the current state of each filter item */
    const [registrant, setRegistrant] = useState();
    const [school, setSchool] = useState();
    const [teacher, setTeacher] = useState();
    const [exam, setExam] = useState();
    /* These four are the currently available options for each filter item */
    const [entities, setEntities] = useState();
    const [schools, setSchools] = useState();
    const [teachers, setTeachers] = useState();
    const [exams, setExams] = useState();
    /* `unfiltered` is the population of items, `handle` receives the filtered items */

    /* handle a filter change - same operation, only difference is which filter gets the
       new value (maybe there's a better way to do that? switch/case works)
       -> apply filter to population (getFilter)
        -> update filter options (setFilters)
         -> pass filtered list to parent (handle) */
    const filterSelect = (name, value) => {
        const filterValue = value === 'All' ? '' : value;
        switch (name) {
            case 'registrant':
                getFilter(filterValue, school, teacher, exam)
                    .then(filtered => setFilters(filtered, filterValue, school, teacher, exam))
                    .then(handle);
                break;
            case 'school':
                getFilter(registrant, filterValue, teacher, exam)
                    .then(filtered => setFilters(filtered, registrant, filterValue, teacher, exam))
                    .then(handle);
                break;
            case 'teacher':
                getFilter(registrant, school, filterValue, exam)
                    .then(filtered => setFilters(filtered, registrant, school, filterValue, exam))
                    .then(handle);
                break;
            case 'exam':
                getFilter(registrant, school, teacher, filterValue)
                    .then(filtered =>
                        setFilters(filtered, registrant, school, teacher, filterValue)
                    )
                    .then(handle);
                break;
            default:
                return;
        }
    };
    /* get a fresh filtered list - we filter the population each time because a filter change
       may add back items that were previously excluded */
    const getFilter = (filterRegistrant, filterSchool, filterTeacher, filterExam) => {
        return new Promise(resolve => {
            const newValue = unfiltered.filter(
                item =>
                    (!filterRegistrant || item.registrant === filterRegistrant) &&
                    (!filterSchool || item.school === filterSchool) &&
                    (!filterTeacher || item.teacher === filterTeacher) &&
                    (!filterExam || item.exam === filterExam)
            );
            resolve(newValue);
        });
    };
    /* set all four filter options to values currently in the filtered list */
    const setFilters = (filtered, filterRegistrant, filterSchool, filterTeacher, filterExam) => {
        return new Promise(resolve => {
            setFilter('registrant', filterRegistrant, filtered, setRegistrant, setEntities);
            setFilter('school', filterSchool, filtered, setSchool, setSchools);
            setFilter('teacher', filterTeacher, filtered, setTeacher, setTeachers);
            setFilter('exam', filterExam, filtered, setExam, setExams);
            resolve(filtered);
        });
    };
    /* for a given filter, set the dropdown options to what is currently in the filtered
       list plus 'All' (represented by an empty string), and set the current item state */
    const setFilter = (name, value, filtered, itemCallback, listCallback) => {
        const items = new Set(filtered.map(item => item[name]));
        const newList = Array.from(items).concat('');
        itemCallback(items.has(value) ? value : '');
        listCallback(newList);
    };

    /* when the population changes (likely only when first loaded), reset the filters */
    useEffect(() => {
        if (unfiltered) {
            getFilter('', '', '', '')
                .then(filtered => setFilters(filtered, '', '', '', ''))
                .then(() => handle([]));
        } else handle([]);
    }, [unfiltered]);

    /* if any lists are missing, show 'Loading' instead of broken components */
    if (!unfiltered || !entities || !schools || !teachers || !exams) {
        return <Loading>Loading . . .</Loading>;
    }

    return (
        <>
            <FilterItem
                field='registrant'
                name='Ordering Entity'
                value={registrant}
                options={entities}
                handler={filterSelect}
            />
            <FilterItem
                field='school'
                name='School'
                value={school}
                options={schools}
                handler={filterSelect}
            />
            <FilterItem
                field='teacher'
                name='Teacher'
                value={teacher}
                options={teachers}
                handler={filterSelect}
            />
            <FilterItem
                field='exam'
                name='Exam'
                value={exam}
                options={exams}
                handler={filterSelect}
            />
        </>
    );
};

export default Filters;
