import React, {useState} from 'react';
import {Col, Row, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {useTeacherStudentData} from '../hooks/useApi';
import Loading from '../components/waiting';
import ResponseImage from '../response/ResponseImage';

const StudentRow = ({student}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const {isLoading, studentData, responseImageCount} = useTeacherStudentData(
        student.student_id,
        modalIsOpen
    );
    const [activeImage, setActiveImage] = useState(null);

    const isStudentActive = !!student.name;
    const onToggle = () => {
        if (isStudentActive) {
            setModalIsOpen(!modalIsOpen);
        }
    };

    const onSelectFile = file => {
        setActiveImage(file);
    };

    const imageContainerStyles = {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
    };

    return (
        <>
            <Modal isOpen={modalIsOpen} className='student-uploads-modal'>
                <ModalHeader toggle={onToggle}>{student.name}</ModalHeader>
                <ModalBody>
                    <Row className=' h-100 no-gutters' style={{margin: '0px'}}>
                        <Col xs='4' className='file-selection-column'>
                            {/*<div className='upload-item-type-header'>Name Page</div>*/}
                            {/*{studentData?.namePage?.image ? (*/}
                            {/*    <div*/}
                            {/*        className='upload-item'*/}
                            {/*        onClick={() => onSelectFile(studentData.namePage.image)}*/}
                            {/*    >*/}
                            {/*        {studentData?.namePage?.image?.qr_code}*/}
                            {/*    </div>*/}
                            {/*) : isLoading ? (*/}
                            {/*    <div>...</div>*/}
                            {/*) : (*/}
                            {/*    <div className='no-upload-item'>No Name Page has been uploaded</div>*/}
                            {/*)}*/}
                            {/*<div className='upload-item-type-header'>Multiple Choice Page</div>*/}
                            {/*{studentData?.mcPage?.image ? (*/}
                            {/*    <div*/}
                            {/*        className='upload-item'*/}
                            {/*        onClick={() => onSelectFile(studentData.mcPage.image)}*/}
                            {/*    >*/}
                            {/*        {studentData?.mcPage?.image?.qr_code}*/}
                            {/*    </div>*/}
                            {/*) : isLoading ? (*/}
                            {/*    <div>...</div>*/}
                            {/*) : (*/}
                            {/*    <div className='no-upload-item'>*/}
                            {/*        No Multiple Choice Page has been uploaded*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            <div className='upload-item-type-header'>Responses</div>
                            {responseImageCount > 0 ? (
                                studentData.responses.map((response, idx) => (
                                    <React.Fragment key={idx}>
                                        {response.images.map((image, idx) => (
                                            <div
                                                key={idx}
                                                className='upload-item'
                                                onClick={() => onSelectFile(image)}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                }}
                                            >
                                                <div style={{display: 'block'}}>
                                                    {image?.qr_code}
                                                </div>
                                                {activeImage?.id === image.id ? (
                                                    <div style={{display: 'block', color: 'gray'}}>
                                                        Uploaded as: {image.filename}
                                                    </div>
                                                ) : null}
                                            </div>
                                        ))}
                                    </React.Fragment>
                                ))
                            ) : isLoading ? (
                                <Loading>Loading images...</Loading>
                            ) : (
                                <div className='no-upload-item'>No response pages available</div>
                            )}
                        </Col>

                        <Col
                            xs={activeImage ? 8 : 5}
                            className='file-display-column'
                            style={{padding: '10px'}}
                        >
                            {activeImage ? (
                                <ResponseImage
                                    imageContainerStyles={imageContainerStyles}
                                    key={activeImage?.id}
                                    id={activeImage?.id}
                                    url={activeImage?.url}
                                    expand={false}
                                />
                            ) : (
                                <div className='no-image'>Please select an image</div>
                            )}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <tr
                key={student.student}
                onClick={onToggle}
                style={isStudentActive ? {cursor: 'pointer'} : {}}
            >
                <td>{student.student}</td>
                <td className='text-center'>{student.login_code}</td>
                <td>{student.name}</td>
                <td className='text-center'>{student.mc}</td>
                <td className='text-center'>{student.fr}</td>
                <td className='text-center'>{student.scored}</td>
            </tr>
        </>
    );
};

export default StudentRow;
