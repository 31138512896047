import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams, useLocation, Redirect} from 'react-router-dom';
import * as act from '../store/actions/actionCreators';
import * as API from '../store/actions/fetchActions';
import Loading from '../components/waiting';
import ErrorMessage from '../components/ErrorMessage';
import GradingPage from './GradingPage';
import {fetchReaderExams, fetchReaderResponseById} from '../store/actions/fetchActions';
import queryString from 'query-string';
import useAuth from '../hooks/useAuth';

const ReaderQuestion = () => {
    const [questionNumber, setQuestionNumber] = useState();
    const dispatch = useDispatch();
    const history = useHistory();
    const questionId = useParams().questionId;
    const error = useSelector(state => state.reader.error);
    const isLoading = useSelector(state => state.reader.loading);
    const response = useSelector(state => state.reader.response);
    const exhausted = useSelector(state => state.reader.exhausted);
    const location = useLocation();
    const parsed = queryString.parse(location.search);
    console.log('parsed: ', parsed);
    const examCode = parsed.examCode;
    const auth = useAuth(dispatch);
    const readertest = useSelector(state => state.reader);
    console.log('reader? ');
    console.dir(readertest);

    useEffect(() => {
        if (parsed.test) {
            // different api endpoint when trying to get specific test response
            dispatch(fetchReaderResponseById(parsed.responseId));
        } else {
            // simply fetch the latest response for this question
            // dispatch(fetchReaderExams(questionId));
            dispatch(API.fetchReaderExams(questionId));
        }
        // React-router will not detect a page change on URL parameter changes, so you need this.
    }, [questionId]);
    useEffect(() => {
        if (exhausted) {
            if (!isLoading && !response) {
                dispatch(
                    act.addToast(`Exhausted responses for Q${questionNumber}`, 'Done', 'warning')
                );
                history.push('/reader');
            }
        } else if (response && response.questionNumber) {
            setQuestionNumber(response.questionNumber);
            // console.log()
        }
    }, [response]);

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not a reader */
    if (auth.isReader === false) return <Redirect to='/' />;

    const handleCancel = (q, r) => {
        dispatch(API.cancelReaderLock(q, r));
        history.push('/reader');
    };
    return (
        <React.Fragment>
            {isLoading && (
                <React.Fragment>
                    <ErrorMessage messages={error} onClose={() => dispatch(act.readerErrorClear)} />
                    <Loading>Loading . . .</Loading>
                </React.Fragment>
            )}
            {!isLoading && response && (
                <GradingPage
                    response={response}
                    testResponse={response.testQuestion}
                    responseId={response.responseId}
                    questionId={questionId}
                    questionNumber={response.questionNumber}
                    questionDdl={response.questionDdl}
                    rubric={response.rubric}
                    comments={response.comments}
                    presetCommentsRequired={response.presetCommentsRequired}
                    html={response.html}
                    images={response.images}
                    onCancel={handleCancel}
                    examCode={examCode}
                >
                    {error}
                </GradingPage>
            )}
        </React.Fragment>
    );
};

export default ReaderQuestion;
