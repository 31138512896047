import React, {useEffect} from 'react';
import * as act from '../store/actions/actionCreators';
import * as API from '../store/actions/fetchActions';
import Loading from '../components/waiting';
import ErrorMessage from '../components/ErrorMessage';
import QuestionList from './QuestionList';
import {useDispatch, useSelector} from 'react-redux';
import useAuth from '../hooks/useAuth';
import {Redirect} from 'react-router-dom';

const ReaderHome = () => {
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);

    const error = useSelector(state => state.reader.error);
    const loading = useSelector(state => state.reader.loading);
    useEffect(() => {
        dispatch(API.fetchReaderExams());
    }, []);

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not a reader */
    if (auth.isReader === false) return <Redirect to='/' />;

    const errorComponent = (
        <ErrorMessage messages={error} onClose={() => dispatch(act.readerErrorClear)} />
    );
    return (
        <React.Fragment>
            {loading ? (
                <React.Fragment>
                    {errorComponent}
                    <Loading>Loading . . .</Loading>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {errorComponent}
                    <QuestionList />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default ReaderHome;
