import React from 'react';
import {Button, Col, Input, InputGroup, InputGroupAddon, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import {useYearsData} from '../../hooks/useApi';

const ExamHeading = props => {
    const {years} = useYearsData();
    return (
        <Row>
            <Col sm='4'>
                <InputGroup size='sm'>
                    <InputGroupAddon addonType='prepend'>School Year:</InputGroupAddon>
                    <Input
                        type='select'
                        name='schoolYear'
                        value={props.year ? props.year : years[0] || ''}
                        onChange={props.onChange}
                    >
                        {years.map(y => (
                            <option key={y}>{y}</option>
                        ))}
                    </Input>
                    <InputGroupAddon addonType='append'>
                        <Button
                            color='primary'
                            name='copy'
                            onClick={e => props.modalToggle(e, 'copy')}
                        >
                            Copy Year
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </Col>
            <Col sm='7'>
                <InputGroup size='sm'>
                    <InputGroupAddon addonType='prepend'>Exam:</InputGroupAddon>
                    <Input
                        name='examName'
                        value={props.exam.name ? `${props.exam.name} (${props.exam.test_id})` : ''}
                        disabled
                    />
                    <InputGroupAddon addonType='append'>
                        <Button color='primary' name='addExam' onClick={props.handleExam}>
                            Add Exam
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </Col>
            <Col sm='1'>
                <Link to='/admin' className='btn btn-primary'>
                    Close
                </Link>
            </Col>
        </Row>
    );
};

export default ExamHeading;
