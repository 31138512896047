import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Loading from '../../components/waiting';
import {Modal, ModalBody, UncontrolledTooltip} from 'reactstrap';
import {postExamCopy} from '../../store/actions/fetchActions';
import {AwaitJobModal} from '../../components/AwaitJob';
import {submitJob} from '../../hooks/useApiJobs';
import {sortExamTab} from '../../store/actions/actionCreators';

const ExamsTab = props => {
    const dispatch = useDispatch();
    const {jobId, reset, sampleAnswerSheets} = submitJob();
    const copying = useSelector(state => state.exam.copying);
    const handleCopy = exam_id => {
        dispatch(postExamCopy(exam_id));
    };
    const handleSort = column => {
        const action = sortExamTab('exams', column);
        dispatch(action);
    };
    return (
        <>
            <table className='table table-hover table-condensed'>
                <tbody>
                    <tr>
                        <th>Select</th>
                        <th className='sort' onClick={() => handleSort('test_id')}>
                            Test ID
                        </th>
                        <th>Year</th>
                        <th className='sort' onClick={() => handleSort('exam_name')}>
                            Exam Name
                        </th>
                        <th className='sort' onClick={() => handleSort('discipline')}>
                            Discipline
                        </th>
                        <th className='sort' onClick={() => handleSort('release_year')}>
                            Release Year
                        </th>
                        <th className='sort' onClick={() => handleSort('owned_by')}>
                            Owned By
                        </th>
                        <th className='sort' onClick={() => handleSort('free_response_quantity')}>
                            # FR's
                        </th>
                        <th className='sort' onClick={() => handleSort('multiple_choice_quantity')}>
                            # MC's
                        </th>
                        <th colSpan={1}>Actions</th>
                    </tr>
                    {props.exams.map(e => (
                        <tr key={e.exam_id}>
                            <td>
                                <input
                                    type='radio'
                                    name='exam'
                                    value={e.exam_id}
                                    checked={props.exam.exam_id === e.exam_id ? 'checked' : false}
                                    onChange={props.onSelect}
                                />
                            </td>
                            <td>{e.test_id}</td>
                            <td>{e.school_year}</td>
                            <td>{e.exam_name}</td>
                            <td>{e.discipline}</td>
                            <td>{e.release_year}</td>
                            <td>{e.owned_by}</td>
                            <td>{e.free_response_quantity}</td>
                            <td>{e.multiple_choice_quantity}</td>
                            <td>
                                <i
                                    className='fa fa-edit icon-primary'
                                    onClick={evt => props.modalToggle(evt, 'exam', e.exam_id)}
                                    id={`tooltip-edit-exam-${e.exam_id}`}
                                    style={{paddingRight: '6px'}}
                                />
                                <UncontrolledTooltip
                                    placement='right'
                                    target={`tooltip-edit-exam-${e.exam_id}`}
                                >
                                    Edit Exam
                                </UncontrolledTooltip>
                                {props.canCopy && (
                                    <>
                                        <i
                                            className='fa fa-copy icon-primary'
                                            onClick={() => handleCopy(e.exam_id)}
                                            id={`tooltip-copy-exam-${e.exam_id}`}
                                            style={{paddingRight: '6px'}}
                                        />
                                        <UncontrolledTooltip
                                            placement='right'
                                            target={`tooltip-copy-exam-${e.exam_id}`}
                                        >
                                            Copy Exam to Current School Year
                                        </UncontrolledTooltip>
                                    </>
                                )}
                                <i
                                    className='fa fa-file-pdf icon-primary'
                                    onClick={() => sampleAnswerSheets(e.exam_id)}
                                    id={`tooltip-sample-${e.exam_id}`}
                                />
                                <UncontrolledTooltip
                                    placement='right'
                                    target={`tooltip-sample-${e.exam_id}`}
                                >
                                    Sample
                                </UncontrolledTooltip>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal isOpen={copying}>
                <ModalBody>
                    <Loading>Copying . . .</Loading>
                </ModalBody>
            </Modal>
            <AwaitJobModal jobId={jobId} onClose={reset}>
                Generating sample file
            </AwaitJobModal>
            {!props.exams ? <Loading>Loading exams . . .</Loading> : null}
        </>
    );
};

export default ExamsTab;
