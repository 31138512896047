import {useMutation, useQuery, useQueryClient} from 'react-query';
import {apiCall, note} from './useApi';

const getCustomerOrder = async () => await apiCall('get', '/customer/order');

export const useOrdersData = enabled => {
    note('useOrdersData called');
    const queryClient = useQueryClient();
    const {data} = useQuery('orders', getCustomerOrder, {placeholderData: [], enabled});
    const updateMutation = useMutation(payload => apiCall('put', '/customer/order', payload), {
        onSuccess: updated => queryClient.setQueryData('orders', updated),
    });
    const deleteMutation = useMutation(
        order_id => apiCall('delete', '/customer/order/' + order_id),
        {
            onSuccess: () => queryClient.invalidateQueries('orders'),
        }
    );
    return {
        ordersData: data,
        updateOrder: updateMutation.mutate,
        deleteOrder: deleteMutation.mutate,
    };
};

/* see https://tkdodo.eu/blog/react-query-data-transformations for passing select function into
   hook as an argument, specifically avoiding excess rerenders. we were getting stuck in a loop. */
export const useAnswerSheetsData = () => {
    note('useAnswerSheetsData called');
    return useAnswerSheetsApi(selectAnswerSheetData);
};
const useAnswerSheetsApi = select => {
    const {data} = useQuery('orders', getCustomerOrder, {select});
    return {ordersData: data};
};
const selectAnswerSheetData = response => {
    return {
        entities: Array.from(new Set(response.map(item => item.registrant))).concat(''),
        schools: Array.from(new Set(response.map(item => item.school_name))).concat(''),
        exams: Array.from(
            new Set(response.map(item => `${item.exam_name} (${item.test_id})`))
        ).concat(''),
        data: response.map(x => ({...x, selected: true, add: '0'})),
    };
};

const getOrderSelect = async () => await apiCall('get', '/customer/order/select');

/* see https://tkdodo.eu/blog/react-query-data-transformations for passing select function into
   hook as an argument, specifically avoiding excess rerenders. we were getting stuck in a loop. */
export const useOrderSelectData = select => {
    note('useOrderSelectData called');
    const {data} = useQuery(['orders', 'select'], getOrderSelect, {select});
    return {orderDropDown: data};
};
