import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {getScoresForTestResponse} from '../../store/actions/fetchActions';
import {useParams} from 'react-router-dom';
import {Button} from 'reactstrap';

const TestResponse = () => {
    const dispatch = useDispatch();
    const responseId = useParams().responseId;
    const testAverages = useSelector(state => state.admin.testResponseAverages);

    useEffect(() => {
        dispatch(getScoresForTestResponse(responseId));
    }, []);

    return (
        <div>
            {testAverages.length === 0 && (
                <div style={{textAlign: 'center', paddingTop: '60px'}}>
                    <h4>
                        No readers have scored this test response yet. When they do, their scores
                        and the overall average scores will show up here.
                    </h4>
                </div>
            )}
            {testAverages.map((item, index) => (
                <div key={index}>
                    Reader: {item.reader}
                    <br />
                    &nbsp;&nbsp;&nbsp;Score: {item.score}
                    <br />
                    {item.detail.map((score, idx) => (
                        <span key={idx}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Part: {score.part}, Score: {score.score},
                            Comment(s): {score.comment}
                            <br />
                        </span>
                    ))}
                </div>
            ))}
            <div style={{textAlign: 'center', paddingTop: '40px'}}>
                <Link to={'/admin/test-responses'}>
                    <Button color='primary'>Go back to Test Responses List</Button>
                </Link>
            </div>
        </div>
    );
};

export default TestResponse;
