import {useMutation, useQuery, useQueryClient} from 'react-query';
import {apiCall} from './useApi';

export const useBlankResponseData = () => {
    const {data, isLoading, refetch} = useQuery(['blanks'], () =>
        apiCall('get', '/admin/response/blank')
    );
    const saveMutation = useMutation(payload =>
        apiCall('post', '/response/' + payload.type, payload.data)
    );
    return {
        blankResponseData: data,
        isLoading,
        refetch,
        isUpdating: saveMutation.isLoading,
        updateBlank: saveMutation.mutate,
    };
};
