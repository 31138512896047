import React from 'react';
import {Link, Redirect, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Button, Col, Row} from 'reactstrap';
import AwaitJob from '../../components/AwaitJob';
import useAuth from '../../hooks/useAuth';

const ViewScannedResponseStatus = () => {
    const dispatch = useDispatch();
    const {jobId} = useParams();
    const auth = useAuth(dispatch);

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not an admin */
    if (auth.isAdmin === false) return <Redirect to='/' />;

    return (
        <>
            <Row>
                <Col xs={12}>
                    <div style={{display: 'flex'}}>
                        <div>
                            <div>
                                <b>1. </b>Upload the Canon scanner output file
                            </div>
                            <div
                                style={{
                                    backgroundColor: '#00acf3',
                                    color: 'white',
                                    padding: '5px',
                                    borderRadius: '2px',
                                }}
                            >
                                <b>2. </b>Wait for nGauge to process the images
                            </div>
                        </div>
                        <Link
                            to='/admin#maint'
                            className='float-right'
                            style={{marginLeft: 'auto'}}
                        >
                            <Button color='primary'>Back to Admin</Button>
                        </Link>
                    </div>
                </Col>
            </Row>
            <AwaitJob jobId={jobId} onClose={() => (window.location = '/admin#maint')}>
                Processing import
            </AwaitJob>
        </>
    );
};

export default ViewScannedResponseStatus;
