import React, {useState, useEffect} from 'react';
import EditTestResponsesModal from './questions/EditTestResponsesModal';
import Loading from '../../components/waiting';
import QuestionModal from './questions/QuestionModal';
import {UncontrolledTooltip} from 'reactstrap';
import {useExamQuestionsData} from '../../hooks/useApiExams';

const mcPageOptions = ['answer_entry_100.png', 'answer_entry_131+.png'];

const QuestionsTab = ({exam_id}) => {
    const [questions, setQuestions] = useState([]);
    const [questionId, setQuestionId] = useState(null);
    const [sort, setSort] = useState({column: null, ascending: true});
    const [testResponseModalQuestionId, setTestResponseModalQuestionId] = useState(null);
    const {questionsData, isLoading} = useExamQuestionsData(exam_id);

    useEffect(() => {
        if (exam_id) setSort({column: 'question_number', ascending: true});
    }, [exam_id]);
    useEffect(() => {
        if (isLoading || !questionsData) return;
        setQuestions(questionsData);
    }, [questionsData]);
    useEffect(() => {
        const asc = sort.ascending ? 1 : -1;
        const sorting = [...questions];
        sorting.sort((a, b) => (a[sort.column] > b[sort.column] ? asc : -asc));
        setQuestions(sorting);
    }, [sort]);
    const onSort = col => {
        setSort({column: col, ascending: sort.column === col ? !sort.ascending : true});
    };

    const openQuestionModal = (e, id) => {
        e.preventDefault();
        setQuestionId(id);
    };
    const openEditTestResponsesModal = (e, id) => {
        e.preventDefault();
        setTestResponseModalQuestionId(id);
    };
    if (!questions) return <Loading>Loading questions . . .</Loading>;

    return (
        <>
            <table className='table table-hover table-condensed'>
                <tbody>
                    <tr>
                        <th>Year</th>
                        <th className='sort' onClick={() => onSort('question_number')}>
                            Q No.
                        </th>
                        <th className='sort' onClick={() => onSort('format')}>
                            Format
                        </th>
                        <th>Parts</th>
                        <th className='sort' onClick={() => onSort('range')}>
                            Range
                        </th>
                        <th className='sort' onClick={() => onSort('weight')}>
                            Weight
                        </th>
                        <th>Actions</th>
                    </tr>
                    {questions?.map(q => (
                        <tr key={q.id}>
                            <td>{q.school_year}</td>
                            <td>{q.question_number}</td>
                            <td>{q.format}</td>
                            <td>{q.parts ? q.parts.length : 0}</td>
                            <td>{q.range}</td>
                            <td>{q.weight}</td>
                            <td>
                                {q.format !== 'name' && (
                                    <>
                                        <i
                                            className='fa fa-edit icon-primary'
                                            onClick={evt => openQuestionModal(evt, q.id)}
                                            style={{paddingRight: '6px'}}
                                            id={`tooltip-edit-question-${q.id}`}
                                        />
                                        <UncontrolledTooltip
                                            placement='right'
                                            target={`tooltip-edit-question-${q.id}`}
                                        >
                                            Edit Question
                                        </UncontrolledTooltip>
                                        {q.format !== 'mc' && (
                                            <>
                                                <i
                                                    className='fa fa-paragraph icon-primary'
                                                    onClick={e =>
                                                        openEditTestResponsesModal(e, q.id)
                                                    }
                                                    id={`tooltip-test-responses-${q.id}`}
                                                />
                                                <UncontrolledTooltip
                                                    placement='right'
                                                    target={`tooltip-test-responses-${q.id}`}
                                                >
                                                    Edit Test Responses
                                                </UncontrolledTooltip>
                                            </>
                                        )}
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <QuestionModal
                isOpen={questionId !== null}
                toggle={() => setQuestionId(null)}
                exam_id={exam_id}
                question_id={questionId}
                mcImages={mcPageOptions}
            />
            <EditTestResponsesModal
                questionNumber={testResponseModalQuestionId}
                exam={exam_id}
                isOpen={testResponseModalQuestionId !== null}
                toggle={() => setTestResponseModalQuestionId(null)}
            />
        </>
    );
};

export default QuestionsTab;
