import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Row, Col} from 'reactstrap';
import Loading from '../components/waiting';
import * as api from '../store/actions/fetchActions';
import CardExamStudents from './CardExamStudents';
import CardTeacherReports from './CardTeacherReports';
import CardExamResources from './CardExamResources';
import CardTeacherUpload from './CardTeacherUpload';

const Dashboard = () => {
    const dispatch = useDispatch();
    const [exams, setExams] = useState(null);
    const teacherData = useSelector(state => state.teacher);

    useEffect(() => {
        dispatch(api.getTeachersStudents());
    }, []);
    useEffect(() => {
        const localExams = teacherData.dashboard;
        if (!localExams) return;
        setExams(localExams);
    }, [teacherData]);
    if (!exams) return <Loading />;

    return (
        <>
            {exams.map((exam, index) => (
                <Row key={index}>
                    <Col>
                        <CardExamStudents exam_name={exam.exam_name} students={exam.students} />
                    </Col>
                    <Col>
                        <Row>
                            <Col sm={12}>
                                <CardTeacherReports
                                    exam_name={exam.exam_name}
                                    test_id={exam.test_id}
                                    reports={teacherData.reports}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <CardExamResources
                                    exam_name={exam.exam_name}
                                    resources={exam.resources}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <CardTeacherUpload
                                    exam_name={exam.exam_name}
                                    test_id={exam.test_id}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ))}
        </>
    );
};

export default Dashboard;
