import React from 'react';
import {useDispatch} from 'react-redux';
import useAuth from '../hooks/useAuth';
import {Link} from 'react-router-dom';
import {Col, Container, Jumbotron, Row} from 'reactstrap';

const NotFound = () => {
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);

    const links = [{url: '/', name: 'nGauge Home'}];
    if (auth.isStudent) links.push({url: '/student', name: 'Student Page'});
    if (auth.error) links.push({url: '/login', name: 'Teacher/Reader/Admin Login'});
    if (auth.isAdmin) links.push({url: '/admin#dash', name: 'Admin Dashboard'});
    if (auth.isTeacher) links.push({url: '/teacher', name: 'Teacher Dashboard'});
    if (auth.isReader) links.push({url: '/reader', name: 'Grading Page'});

    return (
        <Row className='mb-4'>
            <Col>
                <Jumbotron>
                    <Container>
                        <h2>Sorry, we couldn't find that.</h2>
                        <p className='lead'>Did you mean to go to one of these pages?</p>
                        <ul style={{listStyleType: '"✏️ "'}}>
                            {links.map((link, key) => (
                                <li className='mb-2'>
                                    <Link to={link.url} className='text-black-50'>
                                        {link.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Container>
                </Jumbotron>
            </Col>
        </Row>
    );
};

export default NotFound;
