import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Button, Row, Col, Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';
import Loading from '../components/waiting';
import ResponseImage from './ResponseImage';
import {CodeAndRotate} from './AssignFields';
import ReassignOptions from './AssignImage';
import {useDispatch} from 'react-redux';
import useAuth from '../hooks/useAuth';
import {useUnassigned} from '../hooks/useApi';

const UnassignedRow = props => {
    const [saving, setSaving] = useState();
    const [rotate, setRotate] = useState(0);
    const [attributes, setAttributes] = useState({
        order: null,
        checkDelete: false,
        checkClear: false,
        student: props.student,
        question: props.question,
        page: props.page,
    });

    const handleRotate = () => {
        if (rotate >= 270) setRotate(0);
        else setRotate(rotate + 90);
    };
    const handleChange = (name, value) => {
        setAttributes({
            ...attributes,
            [name]: value,
        });
    };
    const handleSubmit = event => {
        event.preventDefault();
        setSaving(props.id);
        props.onSubmit({
            index: props.id,
            image_id: props.image_id,
            rotate: rotate,
            delete: attributes.checkDelete,
            clear: attributes.checkClear,
            page: attributes.page,
            question: attributes.question,
            student: attributes.student,
            order: attributes.order,
        });
    };

    return (
        <Row>
            <Col sm='7'>
                <ResponseImage url={props.url} rotate={rotate} onRotate={handleRotate} />
            </Col>
            <Col sm='5'>
                <CodeAndRotate code={props.qr_code} handle={handleRotate} />
                {props.filename ? (
                    <InputGroup>
                        <InputGroupAddon addonType='prepend'>
                            <InputGroupText>File:</InputGroupText>
                        </InputGroupAddon>
                        <Input value={props.filename} readOnly />
                    </InputGroup>
                ) : null}
                <ReassignOptions {...attributes} onChange={handleChange} />
                <div className='text-right'>
                    <br />
                    {saving === props.id ? (
                        <Loading />
                    ) : (
                        <Button color='primary' onClick={handleSubmit}>
                            Save
                        </Button>
                    )}
                </div>
            </Col>
            <Col sm='12'>
                <hr />
            </Col>
        </Row>
    );
};

const AssignUnassigned = () => {
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);
    const {count, unassigned, updateImage} = useUnassigned(dispatch);

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not an admin */
    if (auth.isAdmin === false) return <Redirect to='/' />;

    return (
        <>
            <Row>
                <Col sm='3' />
                <Col sm='6'>
                    <h4>Assign unreadable pages:</h4>
                    {count === 0 && <div>There are no more unreadable pages to assign!</div>}
                </Col>
                <Col sm='3' className='text-right'>
                    <Link className='btn btn-primary' to='/admin'>
                        Close
                    </Link>
                </Col>
            </Row>
            {unassigned &&
                unassigned.map((page, idx) => (
                    <UnassignedRow {...page} key={page.image_id} id={idx} onSubmit={updateImage} />
                ))}
        </>
    );
};

export default AssignUnassigned;
