import React from 'react';
import {Redirect} from 'react-router-dom';
import Loading from '../components/waiting';
import EnterName from './EnterName';
import ChooseSection from './ChooseSection';
import {useDispatch, useSelector} from 'react-redux';
import useAuth from '../hooks/useAuth';
import * as act from '../store/actions/actionCreators';
import {useHistory} from 'react-router';
import {setStudentPersist} from '../store/actions/actionCreators';
import {submitFRResponses, submitMCResponses, putStudents} from '../store/actions/fetchActions';

const Student = () => {
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);
    const history = useHistory();
    const studentData = useSelector(state => state?.student);

    const loginCookieSet = document.cookie.includes('ngauge=');
    if (!loginCookieSet) {
        /* we're getting a re-rendering loop React error in Rollbar on this dispatch line sometimes
           I'm not sure why that's happening but maybe these console messages showing up in
           the telemetry will help figure it out ... */
        console.warn(`cookie not found. document.cookie='${document.cookie}'\n(auth: ${auth})\n`);
        dispatch(
            act.addToast(
                'Please enable cookies to use this site. We use a cookie to correctly attribute your work to you, and nothing else.',
                'Enable Cookies',
                'danger'
            )
        );
        return <Redirect to='/' />;
    }
    const setPage = newPage => {
        history.push(history.pathname, newPage);
        history.go(1);
    };
    const handleSetStudentPersist = payload => dispatch(setStudentPersist(payload));
    const handleSubmitFRResponses = payload => dispatch(submitFRResponses(payload));
    const handleSubmitMCResponses = payload => dispatch(submitMCResponses(payload));
    const handlePutStudents = () => dispatch(putStudents());

    return (
        <div className='mb-5'>
            <h2>{studentData?.examName}</h2>
            <span>
                Teacher: {studentData?.teacherName}
                <pre>
                    <strong>[{studentData?.qrCode}]</strong>
                </pre>
            </span>
            {studentData?.status === 'start' ? (
                <EnterName
                    studentCode={auth.userId}
                    firstName={auth.firstName}
                    middleInitial={auth.middleInitial}
                    lastName={auth.lastName}
                    onSubmit={auth.setStudentName}
                />
            ) : studentData?.status === 'open' ? (
                <ChooseSection
                    examMCURL={studentData?.examMCURL}
                    examMCEnable={studentData?.examMCEnable}
                    examMCStatus={studentData?.examMCStatus}
                    examMCTime={studentData?.examMCTime}
                    multipleChoice={studentData?.multipleChoice}
                    examFRURL={studentData?.examFRURL}
                    examFREnable={studentData?.examFREnable}
                    examFRStatus={studentData?.examFRStatus}
                    examFRTime={studentData?.examFRTime}
                    questions={studentData?.questions}
                    persist={studentData?.persist}
                    setStudentPersist={handleSetStudentPersist}
                    putStudents={handlePutStudents}
                    submitMCResponses={handleSubmitMCResponses}
                    submitFRResponses={handleSubmitFRResponses}
                    page={history.location.state}
                    setPage={setPage}
                    err={(message, type, icon) => dispatch(act.addToast(message, type, icon))}
                />
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default Student;
