import React from 'react';
import {Link, Redirect, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Col, Row} from 'reactstrap';
import {postScannedResponse} from '../../store/actions/fetchActions';
import useAuth from '../../hooks/useAuth';
import FileUpload from '../../components/FileUpload';

const ImportScannedResponses = () => {
    const scannedResponseUploadProgress = useSelector(
        state => state.admin.scannedResponseUploadProgress
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useAuth(dispatch);

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not an admin */
    if (auth.isAdmin === false) return <Redirect to='/' />;

    return (
        <Row>
            <Col>
                <FileUpload
                    onUpload={files => dispatch(postScannedResponse(files[0], history))}
                    bypassSizeLimit
                    progress={scannedResponseUploadProgress}
                >
                    Choose file to import
                </FileUpload>
            </Col>
            <Col>
                <Link to='/admin#maint' className='float-right' style={{marginLeft: 'auto'}}>
                    <Button color='primary'>Back to Admin</Button>
                </Link>
            </Col>
        </Row>
    );
};

export default ImportScannedResponses;
