import React from 'react';
import {Col, FormGroup, Input, Label} from 'reactstrap';
import DurationInputMask from 'react-duration-input-mask';

class DurationInputComponent extends DurationInputMask {
    // I'm subclassing the constructor class because the `mask` method is not exported, and
    // if I pass an integer as defaultValue it just shows that in the field until it's edited.
    constructor(props) {
        super(props);
        this.state = {
            value: this.mask(props.defaultValue, props),
        };
    }
}

export const RowInput = ({id, name, value, onChange, children, ...rest}) => {
    const handleChange = event => {
        onChange(event.target.name, event.target.value);
    };
    return (
        <FormGroup row>
            <Label sm={3} for={id}>
                {children}
            </Label>
            <Col sm={9}>
                <Input id={id} name={name} value={value} onChange={handleChange} {...rest} />
            </Col>
        </FormGroup>
    );
};

export const CheckInput = props => {
    const {id, name, value, onChange, children} = props;
    const checkValue = value ? value : props.defaultValue || false;
    const handleChange = event => {
        onChange(event.target.name, event.target.checked);
    };
    return (
        <FormGroup row>
            <Col sm={{size: 9, offset: 3}}>
                <FormGroup check>
                    <Input
                        name={name}
                        id={id}
                        type='checkbox'
                        onChange={handleChange}
                        checked={checkValue}
                    />
                    <Label for={id} check>
                        {children}
                    </Label>
                </FormGroup>
            </Col>
        </FormGroup>
    );
};

export const DurationInput = props => {
    const {id, name, value, onChange, children} = props;
    const handleChange = parsedValue => {
        onChange(name, parsedValue);
    };
    return (
        <FormGroup row>
            <Label sm={3} for={id}>
                {children}
            </Label>
            <Col sm={9}>
                <DurationInputComponent
                    className='form-control'
                    id={id}
                    handleChange={handleChange}
                    defaultValue={value ? value : ''}
                />
            </Col>
        </FormGroup>
    );
};
