import {combineReducers} from 'redux';
import adminReducer from './adminReducer';
import examReducer from './examReducer';
import selectionsReducer from './selectionsReducer';
import registerReducer from './registerReducer';
import registrantReducer from './registrantReducer';
import responseReducer from './responseReducer';
import readerReducer from './readerReducer';
import studentReducer from './studentReducer';
import teacherReducer from './teacherReducer';
import toastReducer from './toastReducer';

export default function rootReducer() {
    return combineReducers({
        admin: adminReducer,
        exam: examReducer,
        reader: readerReducer,
        register: registerReducer,
        registrants: registrantReducer,
        responses: responseReducer,
        selections: selectionsReducer,
        student: studentReducer,
        teacher: teacherReducer,
        toast: toastReducer,
    });
}
