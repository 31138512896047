import React from 'react';
import {Fade} from 'reactstrap';

const CloseButton = props => (
    <button type='button' onClick={props.toggle} className='close' aria-label='Close'>
        <span aria-hidden='true'>
            <svg
                viewBox='0 0 14 14'
                style={{
                    height: '14px',
                    width: '14px',
                    stroke: 'red',
                    fill: 'none',
                    strokeLinejoin: 'round',
                    strokeLinecap: 'round',
                    strokeWidth: '1px',
                }}
            >
                <g>
                    <rect height='12' width='12' x='1' y='1' />
                    <line x1='4' x2='10' y1='4' y2='10' />
                    <line x1='10' x2='4' y1='4' y2='10' />
                </g>
            </svg>
        </span>
    </button>
);

const Alert = props => (
    <Fade className='alert alert-danger alert-dismissible' in={props.isOpen} role='alert'>
        <CloseButton toggle={props.toggle} />
        {props.children}
    </Fade>
);

const ErrorMessage = props => {
    if (!props.messages) {
        return null;
    }

    return (
        <div style={{zIndex: 9999, position: 'sticky', top: '60px', right: '40px'}}>
            {props.messages.map((err, idx) => (
                <Alert
                    key={idx}
                    isOpen={true}
                    toggle={() => {
                        props.onClose(idx);
                    }}
                >
                    {err}
                </Alert>
            ))}
        </div>
    );
};

export default ErrorMessage;
