import React from 'react';
import {Col, Row, Button} from 'reactstrap';
import ChooseMethod from './ChooseMethod';
import MC from './MultipleChoiceInput';
import Splash from './SplashStart';

const ChooseSection = ({
    page,
    examMCURL,
    examMCEnable,
    examMCStatus,
    examMCTime,
    multipleChoice,
    setPage,
    examFRURL,
    examFREnable,
    examFRStatus,
    examFRTime,
    questions,
    persist,
    setStudentPersist,
    putStudents,
    submitMCResponses,
    submitFRResponses,
    err,
}) => {
    if (page === 'multipleChoice' || !examFREnable)
        return (
            <Splash prompt={examMCURL} section='mcq'>
                <MC
                    persist={persist}
                    setStudentPersist={setStudentPersist}
                    putStudents={putStudents}
                    submitMCResponses={submitMCResponses}
                    setPage={setPage}
                    err={err}
                    examMCTime={examMCTime}
                    multipleChoice={multipleChoice}
                />
            </Splash>
        );
    else if (['freeResponse', 'text', 'image'].includes(page) || !examMCEnable)
        return (
            <ChooseMethod
                page={page}
                examFRURL={examFRURL}
                questions={questions}
                examFRTime={examFRTime}
                persist={persist}
                setStudentPersist={setStudentPersist}
                putStudents={putStudents}
                setPage={setPage}
                err={err}
                submitFRResponses={submitFRResponses}
            />
        );

    return (
        <div>
            <Row>
                <Col>
                    <p>
                        Select the part of the exam you are submitting. Be sure to logout when you
                        have completed your assessment.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col align='center'>
                    <Button
                        className='mx-5 p-4'
                        color={!examMCStatus ? 'secondary' : 'primary'}
                        disabled={!examMCStatus}
                        onClick={() => setPage('multipleChoice')}
                    >
                        MULTIPLE CHOICE
                    </Button>{' '}
                    <Button
                        className='mx-5 p-4'
                        color={!examFRStatus ? 'secondary' : 'primary'}
                        disabled={!examFRStatus}
                        onClick={() => setPage('freeResponse')}
                    >
                        FREE RESPONSE
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default ChooseSection;
