import React, {useEffect} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Form, Input, Button} from 'reactstrap';
import {useDispatch} from 'react-redux';
import useAuth from '../hooks/useAuth';

const Login = () => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const dispatch = useDispatch();
    const auth = useAuth(dispatch);

    const onChange = event => {
        const {type, value} = event.target;
        if (type === 'email') setEmail(value);
        if (type === 'password') setPassword(value);
    };

    const onSubmit = event => {
        event.preventDefault();
        auth.login({email, password});
    };

    if (auth) {
        if (auth.isAdmin) return <Redirect to='/admin' />;
        else if (auth.isReader) return <Redirect to='/reader' />;
        else if (auth.isTeacher) return <Redirect to='/teacher' />;
    }

    return (
        <div className='loginWrapper'>
            <Form onSubmit={onSubmit}>
                Please log in: <br />
                <Input
                    className='mb-2'
                    placeholder='Email'
                    type='email'
                    value={email}
                    onChange={onChange}
                    required
                />
                <Input
                    className='mb-4'
                    placeholder='Password'
                    type='password'
                    value={password}
                    onChange={onChange}
                    required
                />
                <Button color='primary' block type='submit'>
                    Login
                </Button>
                <p className='text-center'>
                    <Link to='/password-reset'>
                        <small>Forgot password?</small>
                    </Link>
                </p>
            </Form>
        </div>
    );
};

export default Login;
