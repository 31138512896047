import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Container, Row, Col} from 'reactstrap';
import Select from 'react-select';
import Loading from '../../components/waiting';
import NamePage from '../../response/PageName';
import MCPage from '../../response/PageMCQ';
import FRPage from '../../response/PageFRQ';
import ResponseOptions from '../../response/AssignResponse';
import SelectOrder from '../../components/input/SelectOrder';
import {useStudentData} from '../../hooks/useApi';
import {useReviewerData, useStudentsList} from '../../hooks/useApiUser';
import useAuth from '../../hooks/useAuth';

const ResponseMaintenance = () => {
    const [order, setOrder] = useState();
    const [student, setStudent] = useState();
    const [attributes, setAttributes] = useState();
    const [reviewer, setReviewer] = useState({
        value: 'all',
        label: 'All Reviewers',
        orders: [],
    });

    const dispatch = useDispatch();
    const {reviewerOptions} = useReviewerData();
    const {studentList} = useStudentsList(order, reviewer.value);
    const {studentData, updateImage, updateText, updateName, updateMC, updateScore, deleteScore} =
        useStudentData(student?.value, reviewer.value);
    const auth = useAuth(dispatch);

    useEffect(() => {
        if (!studentData) return;
        setAttributes({...studentData});
    }, [studentData]);

    /* React wants all hooks to be above this since it might return */
    /* user is not logged in */
    if (auth.redirect) return <Redirect to={auth.redirect} />;
    /* user is not an admin */
    if (auth.isAdmin === false) return <Redirect to='/' />;

    const handleSelectOrder = value => {
        setOrder(value);
        setStudent(null);
        setAttributes(null);
    };
    const handleSelectStudent = value => {
        setStudent(value);
        setAttributes(null);
    };
    const handleChange = payload => {
        const {section, name, value} = payload;
        const data = {...attributes};
        switch (section) {
            case 'namePage':
                data[section][name] = value;
                break;
            case 'mcPage':
                const {index, elementName} = payload;
                data[section][name][index][elementName] = value;
                break;
            case 'responses':
                data[section][payload.index][name] = value;
                break;
            case 'images':
                data.responses[payload.index].images = data.responses[payload.index].images.map(
                    im => (im.id === payload.imageId ? {...im, [name]: value} : im)
                );
                break;
            case 'texts':
                data.responses[payload.index].texts = data.responses[payload.index].texts.map(tx =>
                    tx.id === payload.textId ? {...tx, [name]: value} : tx
                );
                break;
            case 'scores':
                data.responses[payload.index].scores[payload.partIndex][name] = value;
                break;
            default:
        }
        setAttributes(data);
    };

    const handleAssignment = (section, index, id) => {
        const item = attributes.responses[index][section].find(it => it.id === id);
        const payload = {
            student: item.student,
            question: item.question,
            order: item.order ? (item.order.value ? item.order.value : item.order) : 0,
            delete: !!item.checkDelete,
            clear: !!item.checkClear,
        };
        if (section === 'images') updateImage({...payload, image_id: item.id, page: item.page});
        else if (section === 'texts') updateText({...payload, text_id: id});
    };
    const handleSubmit = (section, index) => {
        const responses = [...attributes.responses];
        const response = index !== null && index !== undefined && {...responses[index]};
        switch (section) {
            case 'namePage':
                updateName({
                    studentId: student.value,
                    firstName: attributes.namePage.firstName,
                    middleInitial: attributes.namePage.middleInitial,
                    lastName: attributes.namePage.lastName,
                });
                break;
            case 'mcPage':
                updateMC({
                    studentId: student.value,
                    mcAnswers: attributes.mcPage.mcAnswers,
                });
                break;
            case 'responses':
                let pass = true;
                for (let i = 0; i < response.scores.length; i++) {
                    const score = response.scores[i].score;
                    if (isNaN(parseInt(score)) || !isFinite(score) || score < -1) {
                        handleChange({
                            section,
                            index,
                            name: 'error',
                            value: `Cannot set score to "${score}". Must be a positive integer, -1, or 0.`,
                        });
                        pass = false;
                        break;
                    }
                }
                if (pass) {
                    updateScore({
                        free_response_id: response.id,
                        scores: response.scores,
                        other_comment: response.comment,
                    });
                    handleChange({section, index, name: 'error', value: null});
                }
                break;
            case 'deleteScore':
                deleteScore(response.id);
                break;
            default:
        }
    };

    let students, content;
    let reviewers = <Loading>Loading . . .</Loading>;
    if (reviewerOptions) {
        reviewers = (
            <Select
                options={reviewerOptions}
                value={reviewer}
                onChange={setReviewer}
                placeholder='Select a reviewer...'
            />
        );
    }
    if (studentList) {
        students = (
            <Select
                options={studentList}
                value={student}
                onChange={handleSelectStudent}
                placeholder='Enter or select student...'
            />
        );
    }

    if (attributes) {
        content = (
            <Container>
                {attributes.namePage ? (
                    <NamePage
                        {...attributes.namePage}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                    />
                ) : null}
                {attributes.mcPage ? (
                    <MCPage
                        {...attributes.mcPage}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                    />
                ) : null}
                {attributes.responses.length > 0 && !!attributes.responses[0].id
                    ? attributes.responses.map((item, idx) => (
                          <FRPage
                              {...item}
                              key={idx}
                              index={idx}
                              onChange={handleChange}
                              onSubmit={handleAssignment}
                          >
                              <ResponseOptions
                                  {...item}
                                  index={idx}
                                  handleSubmit={handleSubmit}
                                  handleChange={handleChange}
                              />
                          </FRPage>
                      ))
                    : null}
            </Container>
        );
    } else if (!!student) {
        content = <Loading>Loading . . .</Loading>;
    }

    return (
        <React.Fragment>
            <Row>
                <Col sm='2' />
                <Col sm='8'>
                    {reviewers}
                    <SelectOrder
                        value={order}
                        handleSelect={handleSelectOrder}
                        reviewer={reviewer}
                    />
                    {students}
                </Col>
                <Col sm='2'>
                    <Link to='/admin' className='btn btn-primary btn-block'>
                        Home
                    </Link>
                </Col>
            </Row>
            <Row>{content}</Row>
        </React.Fragment>
    );
};

export default ResponseMaintenance;
