import * as act from '../actions/actionTypes';

const registerReducer = (
    state = {
        hasRegistrants: false,
        schools: [],
        exams: [],
        teachers: [],
        landingPageTemplate: '',
        successfullySubmitted: false,
    },
    action
) => {
    switch (action.type) {
        case act.GET_REGISTRANT_OPTIONS_BY_KEY_REQUEST:
            state = {
                ...state,
                schools: [],
                exams: [],
            };
            break;
        case act.GET_REGISTRANT_OPTIONS_BY_KEY_SUCCESS:
            state = {
                ...state,
                schools: action.payload.schools,
                exams: action.payload.exams,
                landingPageTemplate: action.payload.landing_page_template,
            };
            break;
        case act.GET_TEACHERS_BY_SCHOOL_REQUEST:
            state = {
                ...state,
                teachers: [],
            };
            break;
        case act.GET_TEACHERS_BY_SCHOOL_SUCCESS:
            state = {
                ...state,
                teachers: [
                    ...action.payload,
                    {
                        first_name: null,
                        last_name: null,
                        school_id: null,
                        email: null,
                        teacher_id: 0,
                    },
                ],
            };
            break;
        case act.POST_REGISTRATION_SUCCESS:
            state = {
                ...state,
                successfullySubmitted: true,
            };
            break;
        default:
    }
    return state;
};

export default registerReducer;
