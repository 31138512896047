import React from 'react';
import {Button, Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';
import Select from 'react-select';

export const CodeAndRotate = ({code, handle}) => (
    <InputGroup>
        <InputGroupAddon addonType='prepend'>
            <InputGroupText>Code:</InputGroupText>
        </InputGroupAddon>
        <Input value={code} readOnly />
        <InputGroupAddon addonType='append'>
            <Button color='primary' onClick={handle}>
                Rotate
            </Button>
        </InputGroupAddon>
    </InputGroup>
);

export const SelectInput = ({label, name, list, opt, handle}) => (
    <InputGroup>
        <InputGroupAddon addonType='prepend'>
            <InputGroupText className='primary' tag='label'>
                {label}
            </InputGroupText>
        </InputGroupAddon>
        <Select
            options={list}
            styles={{container: provided => ({...provided, flex: 1})}}
            value={opt}
            onChange={selected => handle(name, selected)}
        />
    </InputGroup>
);

export const EnterInput = ({label, name, value, handle}) => (
    <InputGroup>
        <InputGroupAddon addonType='prepend'>
            <InputGroupText className='primary' tag='label'>
                {label}
            </InputGroupText>
        </InputGroupAddon>
        <Input name={name} value={value} aria-label={name} onChange={handle} />
    </InputGroup>
);

export const DeleteInput = ({deleteCheck, clearCheck, handle}) => (
    <InputGroup>
        <InputGroupAddon addonType='prepend'>
            <InputGroupText className='primary'>
                <Input
                    addon
                    type='checkbox'
                    name='checkDelete'
                    checked={deleteCheck}
                    aria-label='mark image for deletion'
                    onChange={handle}
                />
            </InputGroupText>
        </InputGroupAddon>
        <Input placeholder='Delete Page' readOnly />
        <InputGroupAddon addonType='prepend'>
            <InputGroupText className='primary'>
                <Input
                    addon
                    type='checkbox'
                    name='checkClear'
                    checked={clearCheck}
                    aria-label='clear target response score'
                    onChange={handle}
                />
            </InputGroupText>
        </InputGroupAddon>
        <Input placeholder='Clear Target Response Score' readOnly />
    </InputGroup>
);
