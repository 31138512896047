import React, {useState, useEffect, useRef} from 'react';
import './ResponseImage.scss';
import Loading from '../components/waiting';
import devImage from '../components/placeholder.png';
import {API_PREFIX} from '../store/actions/fetchHelpers';
import {UncontrolledTooltip} from 'reactstrap';

const ResponseImage = ({url, rotate, onRotate, imageContainerStyles = {}}) => {
    const [loaded, setLoaded] = useState(false);
    const [enlarged, setEnlarged] = useState(false);
    const [imageRotate, setImageRotate] = useState(0);
    const [imageInvert, setImageInvert] = useState(false);
    const [alt, setAlt] = useState('response page');
    const [imageSource, setImageSource] = useState(
        process.env.REACT_APP_NO_IMAGES ? devImage : url
    );
    const [retries, setRetries] = useState(0);
    const ref = useRef(null);
    const imageId = url.split('.')[4];

    useEffect(() => {
        if (!!rotate) {
            setImageRotate(rotate);
        }
    }, [rotate]);

    const handleRotate = () => {
        if (onRotate) onRotate();
        setImageRotate((imageRotate + 90) % 360);
    };
    const handleError = () => {
        if (retries < 1) {
            setRetries(retries + 1);
            setImageSource(
                process.env.PUBLIC_URL + API_PREFIX + '/api/v1/response/image/' + imageId
            );
        } else {
            setLoaded(true);
            setAlt('loading image failed');
        }
    };
    const handleClick = event => {
        if (event.target.tagName === 'IMG') {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        }
        setEnlarged(!enlarged);
    };
    return (
        <div className='image-container' ref={ref} style={imageContainerStyles}>
            <ZoomImageContainer
                id={imageId}
                source={imageSource}
                containerWidth={ref.current?.clientWidth}
                alt={alt}
                enlarged={enlarged}
                rotate={imageRotate}
                invert={imageInvert}
                loaded={loaded}
                onClick={handleClick}
                onLoad={setLoaded}
                onError={handleError}
            />
            <div className='float-right'>
                <i
                    className='fa fa-adjust icon-primary'
                    tabIndex={2}
                    id={`tooltip-invert-${imageId}`}
                    onClick={() => setImageInvert(!imageInvert)}
                />
                <UncontrolledTooltip placement='right' target={`tooltip-invert-${imageId}`}>
                    Invert Image Colors
                </UncontrolledTooltip>
                <i
                    className='fa fa-redo icon-primary'
                    tabIndex={3}
                    id={`tooltip-right-${imageId}`}
                    onClick={handleRotate}
                />
                <UncontrolledTooltip placement='right' target={`tooltip-right-${imageId}`}>
                    Rotate Image Right
                </UncontrolledTooltip>
            </div>
        </div>
    );
};

const ZoomImageContainer = ({
    id,
    source,
    containerWidth,
    alt,
    enlarged,
    rotate,
    invert,
    loaded,
    onClick,
    onLoad,
    onError,
}) => {
    const [minHeight, setMinHeight] = useState();
    const classes = enlarged ? 'image-modal' : 'image-flex';
    return (
        <div className={classes} style={{minHeight}} onClick={onClick}>
            {!loaded && <Loading image>Loading Image . . .</Loading>}
            <RotatingImage
                id={`image-${id}`}
                source={source}
                containerWidth={containerWidth}
                setMinHeight={setMinHeight}
                alt={alt}
                enlarged={enlarged}
                rotate={rotate}
                invert={invert}
                loaded={loaded}
                onLoad={onLoad}
                onError={onError}
                onClick={onClick}
            />
        </div>
    );
};

const RotatingImage = ({
    id,
    source,
    containerWidth,
    setMinHeight,
    alt,
    enlarged,
    rotate,
    invert,
    loaded,
    onLoad,
    onError,
    onClick,
}) => {
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(1);
    const ratio = imageWidth / imageHeight;
    useEffect(() => {
        /* If we rotate a landscape page to portrait, make sure it doesn't cover up the rotate button. */
        if (ratio > 1 && rotate % 180 === 90) setMinHeight(Math.round(containerWidth * ratio + 12));
    }, [containerWidth, ratio, rotate]);
    const handleLoad = ({target}) => {
        setImageWidth(target.naturalWidth);
        setImageHeight(target.naturalHeight);
        onLoad(true);
    };
    const style = {};
    if (enlarged) {
        if (rotate > 0) {
            style['transformOrigin'] = 'top left';
        }
        if (rotate === 90) {
            style['height'] = '96vw';
            style['transform'] = 'rotate(90deg) translate(0%, -100%)';
        } else if (rotate === 180) {
            style['width'] = '96vw';
            style['transform'] = 'rotate(180deg) translate(-100%, -100%)';
        } else if (rotate === 270) {
            style['height'] = '96vw';
            style['transform'] = 'rotate(270deg) translate(-100%, 0%)';
        } else {
            style['width'] = '96vw';
        }
    } else {
        if (rotate % 180 === 90) {
            // image is sideways (90, 270 deg)
            const basis = Math.min(imageWidth, containerWidth);
            const offset = Math.round(basis * (ratio - 1));
            style['transform'] = `translate(0px, ${offset}px) rotate(${rotate}deg) scale(${ratio})`;
        } else {
            // image is not sideways (0, 180 deg)
            style['transform'] = `rotate(${rotate}deg)`;
        }
    }
    if (invert) style['filter'] = 'invert(1)';
    if (!loaded) style['visibility'] = 'hidden';
    return (
        <img
            id={id}
            className={'response-image'}
            style={style}
            src={source}
            onLoad={handleLoad}
            onError={onError}
            alt={alt}
            onClick={onClick}
            tabIndex={1}
        />
    );
};

export default ResponseImage;
