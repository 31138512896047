import React, {useState} from 'react';
import {Button, Col, Form, FormGroup, Input, Label, Progress, Row} from 'reactstrap';
import {useDispatch} from 'react-redux';
import {addToast} from '../store/actions/actionCreators';
import Loading from '../components/waiting';
import {FileDrop} from 'react-file-drop';
import CryptoJS from 'crypto-js';

const FileUpload = ({onUpload, onDone, index, multiple, bypassSizeLimit, children}) => {
    const [isReading, setIsReading] = useState(false);
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState(null);
    const [fileKey, setFileKey] = useState(Date.now());
    const [fileNames, setFileNames] = useState([]);
    const [fileHashes, setFileHashes] = useState([]);
    const dispatch = useDispatch();
    const isMulti = multiple !== undefined;

    const handleFiles = (event, fileList) => {
        event?.persist();
        setIsReading(true);
        setProgress(null);
        const addFiles = event ? event.target.files : fileList;
        const newFiles = isMulti ? Array.from(addFiles) : [addFiles[0]];
        const acceptedFiles = [];
        const pendingPromises = [];

        if (isMulti && files.length + newFiles.length > 7) {
            dispatch(
                addToast('Only up to 7 images may be uploaded at a time', 'Upload Error', 'warning')
            );
            if (event) event.target.value = null;
            setIsReading(false);
            return;
        }

        newFiles.forEach(file => {
            if (!acceptFileType(file)) {
                dispatch(
                    addToast(`${file.name} is not a supported format`, 'Upload Error', 'warning')
                );
            } else if (bypassSizeLimit === undefined && file.size > 5242880) {
                dispatch(
                    addToast(`${file.name} is too large; maximum is 5MB`, 'Upload Error', 'warning')
                );
            } else if (file.size > 1073741824) {
                dispatch(
                    addToast(`${file.name} is too large; maximum is 1GB`, 'Upload Error', 'warning')
                );
            } else {
                pendingPromises.push(
                    getFileHash(file)
                        .then(fileHash => {
                            file.fileHash = fileHash;
                            acceptedFiles.push(file);
                            console.log(`Received ${file.name} [${file.fileHash}]`);
                        })
                        .catch(() =>
                            dispatch(
                                addToast(
                                    `Error reading ${file.name}. Please check your file and try again.`,
                                    'Upload Error',
                                    'warning'
                                )
                            )
                        )
                );
            }
        });
        Promise.all(pendingPromises).then(() => {
            const newFileNames = [];
            const newFileHashes = [];
            const addFiles = acceptedFiles.filter(file => {
                if (fileHashes.includes(file.fileHash) && fileNames.includes(file.name)) {
                    dispatch(
                        addToast(
                            `File '${file.name}' - '${file.fileHash}' - already selected. If you are ready to upload, please click the 'Upload' button.`,
                            'Duplicate File',
                            'warning'
                        )
                    );
                    return false;
                } else {
                    let count = 1;
                    if (fileNames.includes(file.name)) {
                        let newfilename = file.name + '-' + count;
                        while (fileNames.includes(newfilename)) {
                            count++;
                            newfilename = file.name + '-' + count;
                        }
                        const newfile = new File(file, newfilename);
                        console.log('newfile -', newfile.name);
                        console.log('filehas -', newfile.fileHash);
                        file = newfile;
                    }
                    newFileNames.push(file.name);
                    newFileHashes.push(file.fileHash);
                    return true;
                }
            });
            setFileNames(fileNames.concat(newFileNames));
            setFileHashes(fileHashes.concat(newFileHashes));
            setFiles(isMulti ? files.concat(addFiles) : addFiles);
            if (event?.target) event.target.value = null;
            setIsReading(false);
        });
    };
    const handleRemove = file => {
        const newFiles = files.filter(f => f !== file);
        setFiles(newFiles);
    };
    const handleUpload = event => {
        event.preventDefault();
        if (files.length === 0) {
            dispatch(addToast('No files selected'));
            return;
        }
        onUpload(files, index, setProgress);
        setFiles([]);
        setFileKey(Date.now());
    };
    const handleDone = () => {
        setProgress(null);
        onDone();
    };

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        <FormGroup className='files'>
                            <FileTarget
                                isReading={isReading}
                                isMulti={isMulti}
                                fileKey={fileKey}
                                handleFiles={handleFiles}
                            >
                                {children}
                            </FileTarget>
                        </FormGroup>
                        {files && (
                            <ul>
                                {files.map((file, idx) => (
                                    <li key={idx}>
                                        {file.name} &nbsp;(
                                        <span
                                            className='link text-danger'
                                            onClick={() => handleRemove(file)}
                                        >
                                            cancel
                                        </span>
                                        )
                                    </li>
                                ))}
                            </ul>
                        )}
                    </Form>
                </Col>
            </Row>
            {progress && (
                <Row>
                    <Col>
                        <Progress max={100} color='primary' value={progress}>
                            {Math.round(progress)}%
                        </Progress>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    {!progress || !onDone ? (
                        <Button color='success' block onClick={handleUpload}>
                            Upload
                        </Button>
                    ) : (
                        progress > 99 && (
                            <Button color='primary' block onClick={handleDone}>
                                Close
                            </Button>
                        )
                    )}
                </Col>
            </Row>
        </>
    );
};

const FileTarget = ({isReading, isMulti, fileKey, handleFiles, children}) => {
    return (
        <>
            <Label style={{display: isReading ? 'none' : 'flex'}}>
                <span style={{flex: 'fit-content'}}>{children}</span>
                <Input
                    style={{flex: 'fit-content'}}
                    type='file'
                    multiple
                    onChange={event => handleFiles(event, null)}
                    key={fileKey}
                />
            </Label>
            {isReading ? (
                <Loading>Processing...</Loading>
            ) : (
                <FileDrop onDrop={fileList => handleFiles(null, fileList)}>
                    or drag and drop your file{isMulti && 's'} here
                </FileDrop>
            )}
        </>
    );
};

const acceptFileType = file => {
    const types = [
        'image/png',
        'image/jpeg',
        'image/webp',
        'image/tiff',
        'image/x-portable-bitmap',
        'image/x-portable-graymap',
        'image/x-portable-pixmap',
        'image/x-portable-anymap',
        'image/heif',
        'image/heic',
        'application/pdf',
        'application/zip',
    ];
    if (types.includes(file.type)) return true; // accepted file type

    const extDiv = file.name.lastIndexOf('.');
    if (extDiv === -1) return true; // no extension? we'll let the server figure it out

    const extensions = [
        'png',
        'jpg',
        'jpeg',
        'webp',
        'tif',
        'tiff',
        'bmp',
        'heif',
        'heic',
        'pdf',
        'zip',
    ];
    const ext = file.name.substring(extDiv + 1, file.name.length).toLowerCase();
    return extensions.indexOf(ext) !== -1; // accepted extension
};

const getFileHash = file =>
    new Promise((resolve, reject) => {
        if (file.size === 0) reject('Empty file');
        const chunkSize = 256 * 1024;
        const SHA512 = CryptoJS.algo.SHA512.create();
        let offset = 0,
            lastOffset = 0,
            stack = [];
        const parseResult = (offset, size, result) => {
            lastOffset = offset + size;
            let wordBuffer = CryptoJS.lib.WordArray.create(result);
            SHA512.update(wordBuffer);
            if (offset + size >= file.size) {
                lastOffset = 0;
                resolve(SHA512.finalize().toString());
            }
        };
        const loading = event => {
            if (lastOffset !== event.target.offset) {
                stack.push({
                    offset: event.target.offset,
                    size: event.target.size,
                    result: event.target.result,
                });
                return;
            }
            parseResult(event.target.offset, event.target.size, event.target.result);
            let buffered = [{}];
            while (buffered.length > 0) {
                buffered = stack.filter(item => item.offset === lastOffset);
                buffered.forEach(item => {
                    parseResult(item.offset, item.size, item.result);
                    const itemIndex = stack.indexOf(item);
                    if (itemIndex !== -1) {
                        stack.splice(itemIndex, 1);
                    }
                });
            }
        };
        while (offset < file.size) {
            const reader = new FileReader();
            reader.size = chunkSize;
            reader.offset = offset;
            reader.onload = loading;
            reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
            offset += chunkSize;
        }
    });

export default FileUpload;
