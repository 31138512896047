import * as act from '../actions/actionTypes';

const resetState = {
    unscored_free_response: null,
    teacher_packets: null,
    reader_scores: null,
    recategorize: null,
    blank: null,
    unprinted: null,
    no_responses: null,
    loading: false,
    isFetchingTestResponses: false,
    editing_test_responses: [],
    testResponsesForAdmin: [],
    testResponseAverages: [],
    isSavingNewTestResponse: false,
    user: {users: null, userData: null, questions: null},
    scannedResponseUploadProgress: null,
};

const adminReducer = (state = {...resetState}, action) => {
    switch (action.type) {
        case act.POST_EXPORT_SUCCESS:
            state = {
                ...state,
                exportJob: action.payload,
            };
            break;
        case act.POST_REPORT_SUCCESS:
            state = {
                ...state,
                reportJob: action.payload,
            };
            break;
        case act.GET_METRICS_SUCCESS:
            state = {
                ...state,
                ...action.payload,
            };
            break;
        case act.FETCH_ASSIGNMENTS_REQUEST:
            state = {
                ...state,
                assignments: {},
            };
            break;
        case act.FETCH_ASSIGNMENTS_SUCCESS:
            state = {
                ...state,
                assignments: action.payload,
            };
            break;
        case act.DELETE_TEST_RESPONSE_SUCCESS:
            state = {
                ...state,
                editing_test_responses: state.editing_test_responses.filter(
                    response => response.responseId !== action.payload
                ),
            };
            break;
        case act.GET_SCORES_FOR_TEST_RESPONSE_SUCCESS:
            state = {
                ...state,
                testResponseAverages: action.payload,
            };
            break;
        case act.GET_TEST_RESPONSES_FOR_EDITING_REQUEST:
            state = {
                ...state,
                isFetchingTestResponses: true,
            };
            break;
        case act.GET_TEST_RESPONSES_FOR_EDITING_SUCCESS:
            state = {
                ...state,
                isFetchingTestResponses: false,
                editing_test_responses: action.payload,
            };
            break;
        case act.GET_TEST_RESPONSES_FOR_ADMIN_SUCCESS:
            state = {
                ...state,
                testResponsesForAdmin: action.payload.exams,
            };
            break;
        case act.SAVE_TEST_RESPONSE_REQUEST:
            state = {
                ...state,
                isSavingNewTestResponse: true,
            };
            break;
        case act.SAVE_TEST_RESPONSE_SUCCESS:
            state = {
                ...state,
                isSavingNewTestResponse: false,
                editing_test_responses: state.editing_test_responses.concat({
                    responseId: 1000,
                    text: action.payload.text,
                }),
            };
            break;
        case act.SAVE_TEST_RESPONSE_FAILURE:
            state = {
                ...state,
                isSavingNewTestResponse: false,
            };
            break;
        case act.POST_SCANNED_RESPONSE_LOADING:
            state = {
                ...state,
                scannedResponseUploadProgress: action.payload.progress,
            };
            break;
        case act.POST_SCANNED_RESPONSE_SUCCESS:
            state = {
                ...state,
                // clear the response progress bar after submitting success
                scannedResponseUploadProgress: null,
            };
            break;
        default:
    }
    return state;
};

export default adminReducer;
