import React from 'react';
import {Form, FormGroup, Input, Label, UncontrolledTooltip} from 'reactstrap';
import classnames from 'classnames';

export const InlineInput = ({title, name, id, value, type, tip, onChange}) => {
    const isPassword = type === 'password';
    const handleChange = evt => {
        const {name, value} = evt.target;
        onChange(name, value === '' ? null : value);
    };
    return (
        <FormGroup className='mb-2 mr-sm-2'>
            <Label className={classnames({'mr-sm-2': true, 'text-danger': isPassword})}>
                {title}
            </Label>
            <Input
                className={classnames({'mr-sm-4': true, 'border-danger': isPassword})}
                id={id || name}
                name={name}
                type={type || 'text'}
                autoComplete={isPassword ? 'new-password' : undefined}
                value={value || ''}
                onChange={handleChange}
            />
            {!!tip && (
                <UncontrolledTooltip placement='right' target={id}>
                    {tip}
                </UncontrolledTooltip>
            )}
        </FormGroup>
    );
};

export const InlineCheck = ({name, value, defaultValue, tip, onChange, children}) => {
    const checkValue = value ? value : defaultValue || false;
    const handleChange = event => {
        onChange(event.target.name, event.target.checked);
    };
    return (
        <FormGroup check>
            <Input
                name={name}
                id={name}
                type='checkbox'
                onChange={handleChange}
                checked={checkValue}
            />
            <Label for={name} check>
                {children}
            </Label>
            {!!tip && (
                <UncontrolledTooltip placement='right' target={name}>
                    {tip}
                </UncontrolledTooltip>
            )}
        </FormGroup>
    );
};

export const InlineGroup = ({children}) => {
    return (
        <Form
            inline
            className='mb-2'
            onSubmit={e => {
                e.preventDefault();
            }}
        >
            {children}
        </Form>
    );
};
