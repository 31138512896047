import React, {useState, useEffect} from 'react';
import {Modal, Field} from '../../components/Modal';
import {Exam} from '../../components/input/SelectFields';
import {useExamsData} from '../../hooks/useApi';

const EditOrderModal = ({isOpen, onClose, onDelete, onUpdate, order_id, ...rest}) => {
    const [editingOrder, setEditingOrder] = useState(rest);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const {examOptions} = useExamsData(isOpen);

    useEffect(() => {
        if (order_id) setEditingOrder({order_id, ...rest});
    }, [order_id]);

    const handleClose = () => {
        setSaving(false);
        setDeleting(false);
        onClose();
    };

    const handleChange = e => {
        const {name, value} = e.target;
        setEditingOrder({...editingOrder, [name]: value});
    };

    const handleExam = exam => {
        setEditingOrder({...editingOrder, exam_id: exam.value, exam_name: exam.label});
    };

    const handleSave = () => {
        setSaving(true);
        const saveData = Object.fromEntries(
            [
                'order_id',
                'ship_to_attn',
                'ship_to_address1',
                'ship_to_address2',
                'ship_to_city',
                'ship_to_state',
                'ship_to_postal_code',
                'number_of_students',
                'exam_id',
            ]
                .filter(key => key in editingOrder)
                .map(key => [key, editingOrder[key]])
        );
        onUpdate(saveData, {
            onSuccess: () => {
                setSaving(false);
                onClose();
            },
        });
    };

    const handleDelete = () => {
        setDeleting(true);
        onDelete(editingOrder.order_id, {
            onSuccess: () => {
                setDeleting(false);
                onClose();
            },
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            title={`Edit order for ${editingOrder.teacher_first} ${editingOrder.teacher_last} at ${editingOrder.school_name}`}
            onSave={handleSave}
            onDelete={handleDelete}
            saving={saving}
            deleting={deleting}
        >
            <Field
                name='order_date'
                value={editingOrder.order_date}
                onChange={handleChange}
                readOnly
            >
                Order Date
            </Field>
            <Field
                id='schoolName'
                name='school_name'
                value={editingOrder.school_name}
                onChange={handleChange}
                readOnly
            >
                School
            </Field>
            <Field
                id='shipToAttn'
                name='ship_to_attn'
                value={editingOrder.ship_to_attn || ''}
                onChange={handleChange}
            >
                Attn
            </Field>
            <Field
                id='shipToAddr1'
                name='ship_to_address1'
                value={editingOrder.ship_to_address1}
                onChange={handleChange}
            >
                Address 1
            </Field>
            <Field
                id='shipToAddr2'
                name='ship_to_address2'
                value={editingOrder.ship_to_address2 || ''}
                onChange={handleChange}
            >
                Address 2
            </Field>
            <Field
                id='shipToCity'
                name='ship_to_city'
                value={editingOrder.ship_to_city}
                onChange={handleChange}
            >
                City
            </Field>
            <Field
                id='shipToState'
                name='ship_to_state'
                value={editingOrder.ship_to_state}
                onChange={handleChange}
            >
                State
            </Field>
            <Field
                id='shipToPostalCode'
                name='ship_to_postal_code'
                value={editingOrder.ship_to_postal_code}
                onChange={handleChange}
            >
                Postal Code
            </Field>
            <Field
                id='numberOfStudents'
                name='number_of_students'
                value={editingOrder.number_of_students}
                onChange={handleChange}
            >
                # Students
            </Field>
            <Exam
                value={{label: editingOrder.exam_name, value: editingOrder.exam_id}}
                population={examOptions || []}
                handle={handleExam}
            >
                Exam
            </Exam>
        </Modal>
    );
};

export default EditOrderModal;
