import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import {applyMiddleware, createStore} from 'redux';
import rootReducer from './reducers/rootReducers';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import {composeWithDevTools} from 'redux-devtools-extension';

const middleware = [thunk];
if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
} else {
    LogRocket.init('applied-practice/ngauge');
    setupLogRocketReact(LogRocket);
    middleware.push(LogRocket.reduxMiddleware());
}

export default function configureStore(cachedStore) {
    return createStore(
        rootReducer(),
        cachedStore,
        composeWithDevTools(applyMiddleware(...middleware))
    );
}
