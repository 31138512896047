import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {getTestResponsesForAdmin} from '../../store/actions/fetchActions';

const TestResponsesList = () => {
    const dispatch = useDispatch();
    const exams = useSelector(state => state.admin.testResponsesForAdmin);

    useEffect(() => {
        dispatch(getTestResponsesForAdmin());
    }, []);

    const examsWithTestResponses = exams.filter(
        exam => exam.questions.reduce((a, b) => a + (b ? b.testResponses.length : 0), 0) > 0
    );
    if (examsWithTestResponses.length === 0) {
        return <div>There are not yet any test response scores to display.</div>;
    }

    return (
        <div>
            {examsWithTestResponses.map((exam, index) => (
                <div key={index}>
                    <h4>{exam.name}</h4>
                    {exam.questions.map(
                        (question, index) =>
                            question.testResponses.length > 0 && (
                                <div key={index}>
                                    <h5>Question {question.questionNumber}</h5>
                                    {question.testResponses.map((response, index) => (
                                        <div>
                                            <Link to={'/admin/test-response/' + response}>
                                                {'Test Response #' + (index + 1)}
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            )
                    )}
                </div>
            ))}
        </div>
    );
};

export default TestResponsesList;
