import {useMutation, useQuery, useQueryClient} from 'react-query';
import {addToast} from '../store/actions/actionCreators';
import {apiCall} from './useApi';
import {useMemo} from 'react';

export const useUsersData = enabled => {
    const {data, isLoading} = useQuery('users', async () => await apiCall('get', '/admin/users'), {
        select: users => [
            {value: 'CREATE-NEW', label: 'Create New User'},
            ...users.map(user => ({
                value: user.user_id,
                label: user.last_name + ', ' + user.first_name,
            })),
        ],
        enabled,
    });
    return {userList: data, isLoading};
};

export const useAdminUserData = (dispatch, user_id) => {
    const queryClient = useQueryClient();
    const {data} = useQuery(
        ['user', user_id],
        async () => await apiCall('get', '/admin/user/' + user_id),
        {
            enabled: !!user_id,
            select: data1 => {
                return {
                    ...data1,
                    questions: data1.questions?.map(val => ({
                        value: val.question_id,
                        label: val.question_name,
                    })),
                    exams: data1.exams?.map(val => ({
                        value: val.exam_id,
                        label: `${val.exam_name} - ${val.test_id}`,
                    })),
                    schools: data1.schools?.map(val => ({
                        value: val.school_id,
                        label: val.school_name,
                    })),
                };
            },
        }
    );
    const deleteUser = useMutation(user_id => apiCall('delete', '/admin/user/' + user_id), {
        onSuccess: () => {
            queryClient.setQueryData(['user', user_id], () => null);
            queryClient.setQueryData('users', users =>
                users.filter(user => user.user_id !== user_id)
            );
            dispatch(addToast('User deleted!', 'Success!', 'success'));
        },
    });
    const addUser = useMutation(payload => apiCall('post', '/admin/user', payload), {
        onSuccess: newData => {
            queryClient.setQueryData(['user', user_id], newData);
            const {teacher, questions, schools, exams, ...added} = newData;
            queryClient.setQueryData('users', users => users.concat(added));
            dispatch(addToast('User created!', 'Success!', 'success'));
        },
    });
    const updateUser = useMutation(
        ({user_id, ...payload}) => apiCall('put', '/admin/user/' + user_id, payload),
        {
            onSuccess: () => {
                queryClient.refetchQueries(['user', user_id], {
                    exact: true,
                    active: true,
                    fetching: false,
                });
                dispatch(addToast('User updated!', 'Success!', 'success'));
            },
        }
    );
    const resetUser = useMutation(user_id => apiCall('post', '/admin/user/' + user_id), {
        onSuccess: () => {
            dispatch(addToast('Password reset sent!', 'Success!', 'success'));
        },
    });
    return {
        userData: data,
        deleteUser: deleteUser.mutate,
        addUser: addUser.mutate,
        updateUser: updateUser.mutate,
        resetPassword: resetUser.mutate,
    };
};

export const useReviewerData = () => {
    const {data, isLoading, refetch} = useQuery('users', () =>
        apiCall('get', '/admin/users?is_reader=True')
    );

    return {
        reviewerList: data,
        refetch,
        isLoading,
        reviewerOptions: useMemo(() => {
            const arr = data
                ? data.map(reviewer => ({
                      value: reviewer.user_id,
                      label: `${reviewer.last_name}, ${reviewer.first_name} (scores in ${
                          reviewer.scored_orders ? reviewer.scored_orders.length : 0
                      } orders)`,
                      orders: reviewer.scored_orders,
                  }))
                : [];
            arr.unshift({value: 'all', label: 'All Reviewers'});
            return arr;
        }, [data]),
    };
};

export const useStudentsList = (order_id, reviewer_id) => {
    const {data} = useQuery(
        ['studentList', reviewer_id, order_id],
        () => apiCall('get', '/user/student/' + order_id + '/' + reviewer_id),
        {enabled: !!order_id}
    );
    return {studentList: data};
};
