import React, {useEffect, useState} from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardFooter,
    Input,
    Button,
    Collapse,
} from 'reactstrap';
import Loading from '../../components/waiting';
import {usePropertiesData} from '../../hooks/useApiProperties';

const Setup = ({isOpen}) => {
    const [show, setShow] = useState();
    const {properties, isLoading, updateProp} = usePropertiesData(isOpen);
    const toggle = section => setShow(section === show ? 'none' : section);
    if (isLoading) return <Loading>Loading . . .</Loading>;
    return (
        <div>
            <Row>
                <Col md={6}>
                    {properties?.map(prop => (
                        <PropertyCard
                            key={prop.property_id}
                            property_id={prop.property_id}
                            defaultValue={prop.value}
                            isOpen={show === prop.property_id}
                            onToggle={toggle}
                            onSubmit={updateProp}
                        />
                    ))}
                </Col>
            </Row>
        </div>
    );
};

const PropertyCard = ({property_id, defaultValue, isOpen, onToggle, onSubmit}) => {
    const [value, setValue] = useState(defaultValue);
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);
    const handleChange = event => setValue(event.target.value);
    const handleSubmit = () => onSubmit({property_id, value});
    const toggle = () => onToggle(property_id);
    return (
        <Card>
            <CardHeader onClick={toggle} className='pointer'>
                <CardTitle className='text-white'>{property_id}</CardTitle>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody>
                    <Input
                        type='textarea'
                        name={property_id}
                        id={property_id}
                        value={value || ''}
                        rows={((value.length / 50) | 0) + 1}
                        onChange={handleChange}
                    />
                </CardBody>
                <CardFooter className='text-right'>
                    <Button color='primary' size='sm' onClick={handleSubmit}>
                        Update
                    </Button>
                </CardFooter>
            </Collapse>
        </Card>
    );
};

export default Setup;
