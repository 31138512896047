import React from 'react';
import {
    Collapse,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardFooter,
    Button,
    Table,
} from 'reactstrap';
import unscoredDetail from '../../export/unscoredDetail';
import {LoadingRow} from '../../components/waiting';

const UnscoredFreeResponse = props => {
    const [show, setShow] = React.useState(false);
    const [unscored, setUnscored] = React.useState(<LoadingRow>Loading . . .</LoadingRow>);

    const {unscored_free_response, getMetrics} = props;
    React.useEffect(() => {
        if (!unscored_free_response) {
            if (show) getMetrics('unscored_free_response');
            return;
        }
        const unscoredCounts = unscored_free_response.reduce((acc, cur) => {
            const q = cur.exam + cur.question;
            if (q in acc) acc[q].count += 1;
            else
                acc[q] = {
                    exam: cur.exam,
                    question: cur.question,
                    count: 1,
                };
            return acc;
        }, {});
        const sortedCounts = Object.values(unscoredCounts).sort((a, b) =>
            a.exam === b.exam ? a.question - b.question : a.exam > b.exam ? 1 : -1
        );
        setUnscored(
            sortedCounts.map((item, idx) => (
                <tr key={idx}>
                    <td>{item.exam}</td>
                    <td className='text-center'>{item.question}</td>
                    <td className='text-right'>{item.count}</td>
                </tr>
            ))
        );
    }, [show, unscored_free_response, getMetrics]);

    return (
        <Card>
            <CardHeader
                onClick={() => {
                    setShow(!show);
                }}
                className='pointer'
            >
                <CardTitle className='text-white'>Essay Free Responses to be Scored</CardTitle>
            </CardHeader>
            <Collapse isOpen={show}>
                <CardBody>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>Exam</th>
                                <th>Question</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>{unscored}</tbody>
                    </Table>
                </CardBody>
                <CardFooter className='text-right'>
                    <Button
                        className='btn-primary'
                        size='sm'
                        disabled={!unscored_free_response}
                        onClick={() => unscoredDetail(unscored_free_response)}
                    >
                        Export Detail
                    </Button>
                </CardFooter>
            </Collapse>
        </Card>
    );
};

export default UnscoredFreeResponse;
