import React, {useState, useEffect} from 'react';
import {Button, Col, Container, Row, List} from 'reactstrap';
import Timer from '../components/Timer';
import FileUpload from '../components/FileUpload';
import {useInterval} from './utils';
import {useDispatch} from 'react-redux';
import {submitUpload} from '../hooks/useApi';
import {addToast} from '../store/actions/actionCreators';

const ResponseUpload = ({
    questions,
    persist,
    examFRTime,
    setPage,
    setStudentPersist,
    putStudents,
    submitFRResponses,
}) => {
    const [allUploads, setAllUploads] = useState([]);
    const [pages, setPages] = useState({});
    const dispatch = useDispatch();
    const {uploaded, reset, uploadStudent} = submitUpload();

    /* we want to show the student all of the files they've uploaded, whether all at
       once or in multiple upload requests */
    useEffect(() => {
        if (!uploaded) return;
        setAllUploads(allUploads.concat(uploaded));
    }, [uploaded]);

    /* persist the student's work on the server every 30 seconds in case something happens */
    const saveWork = () => putStudents();
    useInterval(saveWork, 30000);

    const [startTime, setStartTime] = useState(new Date(persist?.FR || 0));
    useEffect(() => {
        if (startTime?.toJSON() === persist?.FR) return;
        if (startTime < 1000000000000) {
            const newStartTime = new Date();
            setStartTime(newStartTime);
            setStudentPersist({FR: newStartTime.toJSON()});
        }
    }, [persist?.FR, startTime]);

    const handleSubmit = () => {
        if (allUploads.length === 0) {
            dispatch(addToast('You have not uploaded any files yet!', 'Missing Files'));
            return;
        }
        reset();
        submitFRResponses();
        setPage('open');
    };
    const onComplete = () => {
        setStudentPersist({FR: 'expired'});
        dispatch(addToast('Timer Expired. Submitting.', 'Time is up!', 'warning'));
        reset();
        submitFRResponses();
        setPage('open');
    };
    const handleUpload = (files, index, setProgress) => {
        const {question_id, rKey} = questions[index];
        const lastPage = pages[question_id] || 1;
        setPages({...pages, [question_id]: lastPage + files.length});
        uploadStudent(files, {question_id, rKey, lastPage}, setProgress);
    };
    if (persist && persist.FR === 'expired') setPage('open');

    return (
        <>
            <Timer startTime={startTime} timeLimit={examFRTime} onComplete={onComplete} />
            <Row>
                <Col sm={8} className='pr-0'>
                    {questions.map((q, idx) => (
                        <Container className='border border-primary' key={idx}>
                            <h3>{q.heading}</h3>
                            <FileUpload index={idx} multiple onUpload={handleUpload}>
                                select image files of your written response
                            </FileUpload>
                        </Container>
                    ))}
                </Col>
                <Col sm={4} className='pl-0 border border-primary rounded-lg' align='center'>
                    <Button className='my-3 mx-1 p-2' color='primary' onClick={handleSubmit}>
                        Submit Responses
                    </Button>
                    <div className='m-4 text-left'>
                        {allUploads && allUploads.length > 0 ? (
                            <>
                                <h6>Files received:</h6>
                                <List className='ml-2 p-2'>
                                    {allUploads.map((filename, key) => (
                                        <li key={key}>
                                            <pre>{filename}</pre>
                                        </li>
                                    ))}
                                </List>
                            </>
                        ) : null}
                        <p>
                            <small>
                                Remember to click "Submit Responses" when you are finished
                                uploading.
                            </small>
                        </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col align='center'>
                    <Button className='my-3 mx-1 p-2' color='primary' onClick={handleSubmit}>
                        Submit Responses
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default ResponseUpload;
