import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {Row, Col, Input, Label, Button, FormGroup, Badge} from 'reactstrap';

const List = () => {
    const dispatch = useDispatch();
    const exams = useSelector(state => state.reader.exams);
    const history = useHistory();
    const [questionId, setQuestionId] = React.useState(null);
    const [examId, setExamId] = React.useState(null);
    const [disabled, setDisabled] = React.useState(true);
    const select = event => {
        setDisabled(false);
        setQuestionId(event.target.value);

        let strippedExamName = event.target.dataset.examname;
        let stringArray = strippedExamName.split('- ');
        let examCode = stringArray[stringArray.length - 1];
        setExamId(examCode);
        console.log('examCode - ');
        console.log(examCode);
    };
    const availableExams = exams
        ? exams.filter(exam => exam.questions.reduce((a, b) => a + b.available, 0) > 0)
        : [];

    console.log('exams');
    console.dir(exams);

    const examsWithTestResponses = exams
        ? exams.filter(
              exam => exam.questions.reduce((a, b) => a + (b ? b.testResponses.length : 0), 0) > 0
          )
        : [];

    const handleSubmit = questionId => {
        if (questionId.startsWith('test')) {
            this.props.setReaderTest({});
            return;
        }
        console.log('question selected change route - ');
        console.log(examId);
        history.push('/reader/question/' + questionId + '?examCode=' + examId);
    };

    return (
        <div>
            <Row>
                <Col xs='12'>
                    {availableExams.map((exam, idx) => (
                        <div key={idx}>
                            <h4>{exam.name}</h4>
                            {exam.questions.map(q =>
                                q.available > 0 ? (
                                    <div key={q.questionId}>
                                        <Input
                                            type='radio'
                                            name='questionId'
                                            id={`radio${q.questionId}`}
                                            value={q.questionId}
                                            data-examname={exam.name}
                                            onChange={select}
                                        />
                                        <Label for={`radio${q.questionId}`}>
                                            &nbsp;Question {q.questionNumber}
                                        </Label>
                                        <br />
                                    </div>
                                ) : null
                            )}
                        </div>
                    ))}
                    {availableExams.length === 0 ? (
                        <h4>No Responses Available for Scoring</h4>
                    ) : null}
                    <hr />
                </Col>
            </Row>
            {examsWithTestResponses.length > 0 ? (
                <Row>
                    <Col xs={12}>
                        <h2>Test Questions</h2>
                        {examsWithTestResponses.map((e, idx) => (
                            <div key={idx}>
                                <h4>{e.name}</h4>
                                {e.questions.map(q =>
                                    q.testResponses && q.testResponses.length > 0 ? (
                                        <div key={q.questionId}>
                                            Question {q.questionNumber}:
                                            {q.testResponses.map((t, idx) => (
                                                <FormGroup check inline key={idx}>
                                                    <Label sm>
                                                        <Link
                                                            to={
                                                                '/reader/question/' +
                                                                q.questionId +
                                                                '?test=true&responseId=' +
                                                                t
                                                            }
                                                        >
                                                            Test Response #{idx + 1}
                                                        </Link>
                                                    </Label>
                                                </FormGroup>
                                            ))}
                                            <br />
                                        </div>
                                    ) : null
                                )}
                            </div>
                        ))}
                        <hr />
                    </Col>
                </Row>
            ) : null}
            <Row>
                <Col sm='4' />
                <Col sm='4'>
                    <Button
                        id='btnScore'
                        className='btn btn-primary btn-block'
                        disabled={disabled}
                        type='submit'
                        onClick={() => handleSubmit(questionId)}
                    >
                        Begin Scoring
                    </Button>
                </Col>
                <Col sm='4' />
            </Row>
        </div>
    );
};

export default List;
